import React from "react";
import classnames from "classnames";
import { getImageUrl } from "../../../utils/picture";
import { getTranslation } from "../../../utils/translation";
import IntlMessages from "../../../utils/messages";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  product: Product;
  onRemoveOption: (o: Product) => void;
}

const Option = (props: Props) => {
  const picture =
    props.product && props.product.pictures ? props.product.pictures[0] : null;

  const hasStock = props.product.stock ? props.product.stock > 0 : false;

  const className = classnames(
    "mt-2 sm:mt-0 sm:col-span-1 rounded-10 border h-full",
    {
      "opacity-1 bg-white border-ground-gray-200": hasStock,
      "opacity-70 bg-ground-white-200 border-red-500": !hasStock,
    }
  );

  const classNameEnabled = (product, provider) =>
    classnames(
      "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
      {
        "bg-red-400": !product.enabled && provider.enabled,
        "bg-green-400": product.enabled && provider.enabled,
        "bg-black-400": !product.enabled && !provider.enabled,
        "bg-purple-400": product.enabled && !provider.enabled,
      }
    );

  return (
    <div className={className} key={props.product.id}>
      <div className="relative flex px-2 py-2">
        <span className="inline-block relative">
          <img
            src={getImageUrl(null, picture, 50, 50)}
            alt={getTranslation(props.product.name)}
          />
          <span
            className={classNameEnabled(props.product, props.product.provider)}
          ></span>
        </span>
        <div className="flex flex-col ml-2 justify-around">
          <span className="text-14px text-ground-gray-100">
            {getTranslation(props.product.name)}
          </span>
          <span
            className="text-10px text-ground-gray-400 cursor-pointer z-10"
            onClick={() => props.onRemoveOption(props.product)}
          >
            <IntlMessages id="general.delete" />
          </span>
        </div>
        {!hasStock && (
          <span className="text-13px text-red-500 flex absolute inset-0 items-center justify-center uppercase">
            <IntlMessages id="general.out.of.stock" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Option;
