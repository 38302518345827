import React from "react";
import { contextStore, contextStorePersist } from "..";

let token;
let GroundStripeContextStore: typeof contextStore;

interface GroundProviderOpts {
  jwtToken: string;
  children: React.ReactNode;
  storage?: any;
}

const GroundStripeProvider = (props: GroundProviderOpts) => {
  const { jwtToken, storage, children } = props;
  token = jwtToken;

  GroundStripeContextStore = contextStore;
  if (storage) {
    GroundStripeContextStore = contextStorePersist(storage);
  }

  return (
    <GroundStripeContextStore.Provider>
      {children}
    </GroundStripeContextStore.Provider>
  );
};

export { GroundStripeProvider, GroundStripeContextStore, token as Token };
