import React from "react";
import { Image } from "../../../../utils/types";
import { getBucket, getImageHandlerApi } from "../../../../utils/config";
import { getDefaultImage } from "../../../../utils/picture";

interface Props {
  image: Image;
  width: number;
  height: number;
}

const ImageComponent = (props: Props): JSX.Element => {
  const { width, height, image } = props;
  let url;
  let w = Math.round(width);
  let h = Math.round(height);
  if (!image.source && image.picture) {
    const https = image.picture?.startsWith("https");
    if (https) {
      const bucket = getBucket();
      const api = getImageHandlerApi();

      const index = image.picture?.indexOf(bucket);
      const path = image.picture?.slice(index).replace(bucket, "");
      if (api) {
        url = `${api}/${w}x${h}${path}`;
      }
    }
  } else if (image.picture) {
    url = image.picture;
  } else {
    url = getDefaultImage(null);
    w = 25;
    h = 25;
  }

  return (
    <div className="relative text-center">
      <img
        alt="picture"
        className="rounded-3 w-full object-cover"
        src={url}
        style={{ height: `${h}px` }}
      />
    </div>
  );
};

export default ImageComponent;
