import React, { useState } from "react";
import {
  CustomFile,
  Folder,
} from "../../../lib/ground-aws-graphql-core/models/Api/Documents";
import IntlMessages from "../../../utils/messages";
import images from "../../../images";
import Add from "../../../components/Tailwind/Button/Add";
import overrideClasses from "../../../utils/overrideClasses";
import FileInput from "../../../components/Tailwind/FileInput";

type Props = {
  folder: Folder;
  index: number;
  onSelectFile: (f: CustomFile) => void;
  onAddFile: (file: File, folder: Folder) => void;
};

const FolderComponent = (props: Props): JSX.Element => {
  const { index, folder, onSelectFile, onAddFile } = props;
  const { name, files } = folder;
  const [isFolderOpen, setIsFolderOpen] = useState(false);

  const renderFolder = (sub: Folder, index) => {
    return (
      <FolderComponent
        index={index + 1}
        folder={sub}
        onSelectFile={onSelectFile}
        onAddFile={onAddFile}
      />
    );
  };

  const renderDeleteButton = (file: CustomFile) => {
    return (
      <div className="px-1">
        <button
          className="flex items-center justify-center"
          onClick={() => onSelectFile(file)}
          type="button"
        >
          <Add icon={images.trash} iconOver={images.trash} height="18px" />
        </button>
      </div>
    );
  };

  const getIcon = file => {
    let icon;
    switch (file.extension) {
      case "pdf":
        icon = images.pdfFile;
        break;
      case "doc":
      case "docx":
        icon = images.wordFile;
        break;
      case "jpg":
      case "jpeg":
      case "png":
        icon = images.imageFile;
        break;
      default:
        break;
    }

    return icon;
  };

  const renderFile = (file: CustomFile) => {
    return (
      <div className="flex items-center p-2 pl-6 justify-between">
        <div className="flex">
          <img src={getIcon(file)} alt="" className="mr-2" />
          <a
            id={file.name}
            href={file.url}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer mr-4 text-14px"
          >
            {file.name}
          </a>
        </div>
        <div className="flex">{renderDeleteButton(file)}</div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col pb-3">
        <div
          className={`flex pb-1 ml-${folder.level * 2}`}
          onClick={() => {
            setIsFolderOpen(!isFolderOpen);
          }}
        >
          <img src={isFolderOpen ? images.folderMinus : images.folderPlus} />
          <span className="ml-2 mr-1 text-16px leading-6 font-medium text-gray-900">
            <IntlMessages id={`page.list.documents.document.name.${name}`} />
          </span>
          <span className="text-16px leading-6 text-base font-semibold text-blue-700">
            ({folder.files.length})
          </span>
        </div>
        {folder.level > 0 && (
          <div
            className={overrideClasses(
              "border border-ground-blue-100 border-dashed py-2 bg-neutral-300 hover:bg-white",
              { hidden: !isFolderOpen }
            )}
          >
            {files.map(f => renderFile(f))}
            <div className="flex justify-end">
              <FileInput
                onAddFile={onAddFile}
                label="general.add"
                folder={folder}
              />
            </div>
          </div>
        )}
      </div>

      {folder.folders.length > 0 && (
        <>{folder.folders.map((sub, index) => renderFolder(sub, index))}</>
      )}
    </>
  );
};

export default FolderComponent;
