import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import RealTimeReportings from "./real-time";
import GAReportings from "./ga";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { getOperatorAttributeValue } from "../../../lib/ground-aws-graphql-core/services/Api/Attribute";
import { isOperatorAdmin } from "../../../utils/types";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

export interface CardInterface {
  category: string;
  key: string;
  label: string;
  icon: any;
  url: string;
  target?: string;
  description: string;
  display: boolean;
  realtime: boolean;
}

const Dashboard = (props: Props): JSX.Element => {
  const { intl } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [gaUrl, setGaUrl] = useState<string>();

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const operatorAdmin = isOperatorAdmin(me);

  useEffect(() => {
    if (me) {
      setLoading(true);
      getOperatorAttributeValue({
        operatorId: me.operator_id,
        attributeKeyName: "GA_URL",
      })
        .then(resp => {
          if (resp.data?.data?.value) {
            setGaUrl(resp.data?.data?.value);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [me]);

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <div className="px-8 pb-8">
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <div className="py-10">
          <p>
            <span className="text-16px text-gray-900 leading-5">
              {intl.formatMessage({ id: "page.dashboards.introduction" })}
            </span>
          </p>
        </div>

        <div className="my-8">
          <RealTimeReportings {...props} operatorAdmin={operatorAdmin} />
          {gaUrl && (
            <GAReportings
              {...props}
              gaUrl={gaUrl}
              operatorAdmin={operatorAdmin}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default injectIntl(Dashboard);
