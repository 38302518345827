import React from "react";

interface Props {
  children?: React.ReactNode;
}

const colors = {
  right_gradient_ground: "#f9f9fc",
  left_gradient_ground: "rgba(201, 201, 229, 0.50)",
};

const AuthenticationContainer = (props: Props) => {
  return (
    <div
      style={{
        background: `linear-gradient(321deg, ${colors.left_gradient_ground} 37%, ${colors.right_gradient_ground}) 37%`,
      }}
    >
      <main>{props.children}</main>
    </div>
  );
};
export default AuthenticationContainer;
