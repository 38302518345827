import React, { useState, useEffect } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import Header from "../../../../../components/Tailwind/Block/Header";
import GroundFormik from "../../../../../components/Tailwind/Form";
import { Value } from "../../../../../utils/types";
import Footer from "../../../../../components/Tailwind/Block/Footer";
import {
  CreatePriceUnitVariationInput,
  EnumPermissionEntity,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnitVariation } from "../../../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";

interface Props {
  isOpen: boolean;
  toggle: (e: PriceUnitVariation | null | undefined) => void;
  priceUnitVariation: PriceUnitVariation | null | undefined;
  match: Match;
  intl: IntlShape;
}

const PriceUnitVariationForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);

  const createPriceUnitVariationAction =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.priceUnitVariation.createPriceUnitVariation
    );

  const updatePriceUnitVariationAction =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.priceUnitVariation.updatePriceUnitVariation
    );

  const { toggle } = props;

  const createUpdatePriceUnitVariation = (values, markForDelete = false) => {
    // no errors
    if (props.priceUnitVariation) {
      setLoading(true);

      const payload = {
        id: props.priceUnitVariation.id,
        key: values.key,
        name: values.name,
        enabled,
        markForDelete,
      };

      updatePriceUnitVariationAction(payload)
        .then(() => {
          toggle(props.priceUnitVariation);
          toast(
            props.intl.formatMessage({
              id: "page.product.update.price.variation.success",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(props.priceUnitVariation);
          toast(
            props.intl.formatMessage({
              id: "page.product.update.price.variation.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    } else {
      const payload: CreatePriceUnitVariationInput = {
        key: values.key,
        name: values.name,
        enabled,
        markForDelete,
      };

      setLoading(true);
      createPriceUnitVariationAction(payload)
        .then(() => {
          toggle(null);
          toast(
            props.intl.formatMessage({
              id: "page.product.create.price.variation.success",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(null);
          toast(
            props.intl.formatMessage({
              id: "page.product.create.price.variation.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    }
  };

  const handleSubmit = values => {
    createUpdatePriceUnitVariation(values, false);
  };

  useEffect(() => {
    if (props.priceUnitVariation) {
      setEnabled(props.priceUnitVariation.enabled as boolean);
    }
  }, [props.priceUnitVariation]);

  const values: Value[] = [
    {
      id: "key",
      name: "key",
      type: "text",
      label: "page.product.price.unit.id",
      required: true,
      placeholder: "page.product.price.unit.id",
      value: props.priceUnitVariation?.key,
      useIntl: true,
    },
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.product.price.unit.name",
      required: true,
      placeholder: "page.product.price.unit.name",
      value: props.priceUnitVariation?.name,
      translatable: true,
      useIntl: true,
    },
  ];

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <Header
        item={props.priceUnitVariation}
        title={
          props.priceUnitVariation
            ? "page.product.price.unit.update.variation"
            : "page.product.price.unit.create.variation"
        }
        entity={EnumPermissionEntity.PRICE_UNIT}
        checked={enabled}
        className="border-b border-gray-200"
      />
      <GroundFormik
        item={props.priceUnitVariation}
        values={values}
        onSubmit={handleSubmit}
      >
        <Footer
          item={props.priceUnitVariation}
          index={values.length + 1}
          labels={["general.cancel", "general.delete"]}
          onCancel={() => props.toggle(props.priceUnitVariation)}
        />
      </GroundFormik>
    </>
  );
};

export default PriceUnitVariationForm;
