import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";

interface PaymentIntent {
  id: string;
}

interface ConnectedAccount {
  id: string;
  charges_enabled: boolean;
  payouts_enabled: boolean;
}

interface AccountLinks {
  url: string;
}

interface Refund {
  id: string;
  amount: number;
  reason: string;
  status: string;
  charge: string;
}

export interface CreateRefundOpts {
  operatorName: string;
  order: {
    id: string;
  };
  orderItem?: {
    id: string;
  };
  intent: PaymentIntent;
  amount: number;
  reason?: string;
  metadata?: string;
}

export interface CreateRefundExternalizedOpts {
  globalOrderId: string;
  orderId: string;
  orderItemId?: string;
  reason?: string;
}

export interface RetryRefundExternalizedOpts {
  id: number;
}

export interface CreateConnectedAccountOpts {
  operatorName: string;
  id: string;
}

export interface CreateAccountLinksOpts {
  operatorName: string;
  id: string;
  refreshUrl: string;
  returnUrl: string;
}

// Interface declaration
export interface PaymentModel {
  refund: Refund | null;
  setRefund: Action<PaymentModel, Refund | null>;
  createRefund: Thunk<PaymentModel, CreateRefundOpts, Injections, StoreModel>;
  createRefundExternalized: Thunk<
    PaymentModel,
    CreateRefundExternalizedOpts,
    Injections,
    StoreModel
  >;
  retryRefundExternalized: Thunk<
    PaymentModel,
    RetryRefundExternalizedOpts,
    Injections,
    StoreModel
  >;
  account: ConnectedAccount | null;
  setAccount: Action<PaymentModel, ConnectedAccount | null>;
  createConnectedAccount: Thunk<
    PaymentModel,
    CreateConnectedAccountOpts,
    Injections,
    StoreModel
  >;

  accountLinks: AccountLinks | null;
  setAccountLinks: Action<PaymentModel, AccountLinks | null>;
  createAccountLinks: Thunk<
    PaymentModel,
    CreateAccountLinksOpts,
    Injections,
    StoreModel
  >;
}

export const paymentModel: PaymentModel = {
  refund: null,
  setRefund: action((state, payload) => {
    state.refund = payload;
  }),

  account: null,
  setAccount: action((state, payload) => {
    state.account = payload;
  }),

  accountLinks: null,
  setAccountLinks: action((state, payload) => {
    state.accountLinks = payload;
  }),

  createRefund: thunk(async (actions, payload, { injections }) => {
    const { paymentService } = injections;
    const response = await paymentService.createRefund(payload);
    actions.setRefund(response.data);

    return response;
  }),

  createRefundExternalized: thunk(async (_actions, payload, { injections }) => {
    const { paymentService } = injections;
    const response = await paymentService.createRefundExternalized(payload);

    return response;
  }),

  retryRefundExternalized: thunk(async (_actions, payload, { injections }) => {
    const { paymentService } = injections;
    const response = await paymentService.retryRefundExternalized(payload);

    return response;
  }),

  createConnectedAccount: thunk(async (actions, payload, { injections }) => {
    const { paymentService } = injections;
    const response = await paymentService.createConnectedAccount(payload);
    const { status, data } = response;
    if (status === 200) {
      const result: ConnectedAccount = {
        id: data.id,
        charges_enabled: data.charges_enabled,
        payouts_enabled: data.payouts_enabled,
      };
      actions.setAccount(result);
    }

    return response;
  }),

  createAccountLinks: thunk(async (actions, payload, { injections }) => {
    const { paymentService } = injections;
    const response = await paymentService.createAccountLinks(payload);
    const { status, data } = response;
    if (status === 200) {
      const result: AccountLinks = {
        url: data.url,
      };
      actions.setAccountLinks(result);
    }

    return response;
  }),
};
