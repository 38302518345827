import React, { useEffect, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/Tailwind/Block/Footer";
import GroundFormik from "../../../components/Tailwind/Form";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import { IRange, Value } from "../../../utils/types";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import contextStore from "../../../redux/store";
import history from "../../../history";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { getTranslation } from "../../../utils/translation";
import { EnumPaths } from "../../../utils/navigation";
import {
  eachUnitOfInterval,
  endOfDay,
  startOfDay,
} from "../../../utils/config";
import { Privatization } from "../../../redux/models/Privatization";

interface Props {
  intl: IntlShape;
  match: Match<{ id: string; privatizationId: string }>;
  edition: boolean;
}

const PrivatizationForm = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const { state } = useLocation<{ date: string }>();

  const { intl, match, edition } = props;

  const privatization = contextStore.useStoreState(
    s => s.privatization.privatization
  );

  const getPrivatization = contextStore.useStoreActions(
    a => a.privatization.getPrivatization
  );

  const enterprises = contextStore.useStoreState(
    s => s.enterprise.enterprises.items
  );

  const listEnterprises = contextStore.useStoreActions(
    a => a.enterprise.listEnterprises
  );

  const privatizations = contextStore.useStoreState(
    s => s.privatization.privatizations.items
  );

  const listProductPrivatizations = contextStore.useStoreActions(
    a => a.privatization.listProductPrivatizations
  );

  const createPrivatization = contextStore.useStoreActions(
    a => a.privatization.createPrivatization
  );

  const updatePrivatization = contextStore.useStoreActions(
    a => a.privatization.updatePrivatization
  );

  const product = GroundGraphqlContextStore.useStoreState(
    s => s.product.product
  );

  const getProduct = GroundGraphqlContextStore.useStoreActions(
    a => a.product.getProductOnly
  );

  useEffect(() => {
    listEnterprises(null);
    getProduct({ id: match.params.id });
    listProductPrivatizations({ productId: match.params.id });

    if (edition) {
      getPrivatization({ id: match.params.privatizationId });
    }
  }, []);

  const handleSubmit = (values: {
    enterpriseId: string;
    date: {
      start: Date;
      end: Date;
    };
  }) => {
    const modifiedStart = startOfDay(values.date.start);
    const modifiedEnd = endOfDay(values.date.end);

    setLoading(true);

    if (edition) {
      if (privatization) {
        updatePrivatization({
          id: privatization.id,
          start: modifiedStart,
          end: modifiedEnd,
        })
          .then(() => {
            toast(
              intl.formatMessage({
                id: "page.privatization.update.success",
              }),
              { type: "success" }
            );

            const editEnterpriseLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${values.enterpriseId}/${EnumPaths.EDIT_MODE}`;

            history.push(editEnterpriseLink);
          })
          .catch(() => {
            toast(
              intl.formatMessage({
                id: "page.privatization.update.error",
              }),
              { type: "error" }
            );
          })
          .finally(() => setLoading(false));
      }
    } else {
      createPrivatization({
        productId: match.params.id,
        enterpriseId: values.enterpriseId,
        start: modifiedStart,
        end: modifiedEnd,
      })
        .then(() => {
          toast(
            intl.formatMessage({
              id: "page.privatization.create.success",
            }),
            { type: "success" }
          );

          const editEnterpriseLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${values.enterpriseId}/${EnumPaths.EDIT_MODE}`;

          history.push(editEnterpriseLink);
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.privatization.create.error",
            }),
            { type: "error" }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleHideSavePanel = () => {
    setTogglePanel(false);
  };

  const listDisabledDatesFromPrivatizations = () =>
    privatizations.reduce((disabledDates: Date[], priv: Privatization) => {
      // Exclude privatization dates from disabledDates in order to update it correctly
      if (edition && privatization?.id === priv.id) {
        return disabledDates;
      }

      const dates = eachUnitOfInterval(priv.start, priv.end, "day");

      return disabledDates.concat(dates);
    }, []);

  const getInputDates = (): IRange | undefined => {
    if (edition && privatization) {
      return {
        start: privatization.start, //ISO string
        end: privatization.end, //ISO string
      };
    }

    if (state?.date) {
      return {
        start: state.date,
        end: state.date,
      };
    }

    return undefined;
  };

  const values: Value[] = [
    {
      id: "enterpriseId",
      name: "enterpriseId",
      type: "select",
      label: "general.company",
      placeholder: "general.company",
      required: true,
      items: enterprises,
      values: [
        {
          id: edition ? privatization?.enterprise?.id : null,
          name: edition ? privatization?.enterprise?.name : null,
        },
      ],
      useIntl: true,
      disabled: edition,
    },
    {
      id: "date",
      name: "date",
      type: "range",
      label: "page.privatization.dates",
      required: true,
      value: getInputDates(),
      useIntl: true,
      disabledDates: listDisabledDatesFromPrivatizations(),
    },
  ];

  return loading || (edition && !privatization) ? (
    <div className="loading" />
  ) : (
    <div className="px-8">
      <Block>
        <Header
          item={product}
          title="page.privatization.create.title"
          titleParams={{
            space: getTranslation(product?.name),
            capacity: product?.capacity,
          }}
          entity={EnumPermissionEntity.SPACE}
          checked
          className="border-b border-gray-200"
        />
        <GroundFormik
          item={product}
          values={values}
          onSubmit={handleSubmit}
          onChange={handleShowSavePanel}
          showSavePanel={togglePanel}
          onHideSavePanel={handleHideSavePanel}
        >
          <Footer
            item={product}
            index={values.length + 1}
            labels={["general.cancel", "general.delete"]}
          />
        </GroundFormik>
      </Block>
    </div>
  );
};

export default injectIntl(PrivatizationForm);
