import React, { useState, useEffect, useCallback } from "react";
import { match as Match, withRouter } from "react-router-dom";
import contextStore from "../../redux/store";
import { EnumPaths, getRedirectUrl } from "../../utils/navigation";
import images from "../../images";
import NavItem from "./NavItem";
import ChildNavItem from "./ChildNavItem";
import history from "../../history";
import { Display } from "../../components/OperatorCustomization";
import { getTranslation } from "../../utils/translation";
import MyClickAwayListener from "../../components/ClickAwayListener";
import CenterDropDown from "../../components/CenterDropDown";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../lib/ground-aws-cognito-auth-core";
import {
  EnumBackOfficeUserRole,
  EnumPermissionEntity,
  SearchableCenterSortableFields,
  SearchableNotificationSortableFields,
  SearchableSortDirection,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { UserCognito } from "../../lib/ground-aws-cognito-auth-core/models/Authentication";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import { getOperatorConfiguration } from "../../lib/operator-configuration";
import { isDisplayable } from "../../lib/operator-configuration/backoffice/display";
import { getLocale } from "../../lang";
import { isOperatorAdmin } from "../../utils/types";

interface Props {
  match: Match<{ cid: string }>;
}

const DEFAULT_LIMIT = 20;

const Sidebar = (props: Props) => {
  const { match } = props;

  const selectedNavItem = localStorage.getItem("navItem")
    ? localStorage.getItem("navItem")
    : "";

  const selectedParentNavItem = localStorage.getItem("parentNavItem")
    ? localStorage.getItem("parentNavItem")
    : "";

  const [selectedParentItem, setSelectedParentItem] = useState(
    selectedParentNavItem
  );

  const [open, setOpen] = useState(false);

  const [viewingNavItem, setViewingNavItem] = useState(selectedNavItem);

  const containerClassnames = contextStore.useStoreState(
    state => state.menu.containerClassnames
  );

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const setCenter = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.setCenter
  );

  const searchUnreadNotificationsTotal =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.notification.searchUnreadNotificationsTotal
    );

  const listNotViewedIncidents = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listNotViewedIncidents
  );

  const incidentsTotal = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.notViewedincidents.total
  );

  const unreadNotificationsTotal = GroundGraphqlContextStore.useStoreState(
    state => state.notification.unreadNotificationsTotal
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const searchAllAuthorizations = GroundGraphqlContextStore.useStoreActions(
    actions => actions.authorization.searchAllAuthorizations
  );

  const authorizations = GroundGraphqlContextStore.useStoreState(
    state => state.authorization.authorizations.items
  );

  const searchAllCenters = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.searchAllCenters
  );

  const centers = GroundGraphqlContextStore.useStoreState(
    state => state.center.centers.items
  );

  let operatorAdministrator = false;
  if (me) {
    operatorAdministrator = me.role === EnumBackOfficeUserRole.OperatorAdmin;
  }

  const getCenterAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.getCenter
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    if (!center && match.params.cid) {
      getCenterAction({ id: match.params.cid });
    }
  }, [match.params.cid]);

  const myCenters = operatorAdministrator
    ? centers
    : authorizations?.reduce((acc: Center[], el) => {
        if (el.center && !el.center.markForDelete) {
          acc.push(el.center);
        }

        return acc;
      }, []);

  const setViewPage = (
    path: string,
    withHistory?: boolean,
    parentNavItem?: string
  ) => {
    setViewingNavItem(path);
    setSelectedParentItem(parentNavItem || null);

    localStorage.setItem("navItem", path);
    localStorage.setItem("parentNavItem", parentNavItem || "");

    if (withHistory && match.params.cid) {
      history.push(
        `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${path}`
      );
    }
  };

  const goToPage = (e, path, parentItem) => {
    e.preventDefault();
    setContainerClassnames({
      clickIndex: 2,
      strCurrentClasses: containerClassnames,
    });
    setViewPage(path, true, parentItem);
  };

  const isNavItemActive = parent => {
    return selectedParentItem === parent || viewingNavItem === parent;
  };

  const handleChangeCenter = (e: string) => {
    if (myCenters && myCenters.length > 1) {
      setCenter(null);
      const { path } = match;
      const url = getRedirectUrl(path, e);

      if (e === center?.id) setOpen(false);
      else {
        setViewingNavItem(url);
        setSelectedParentItem(null);

        localStorage.setItem("navItem", String(url?.split("/").pop()));
        localStorage.setItem("parentNavItem", "");
        history.push(url);
      }
    }
  };

  useEffect(() => {
    const promises = [
      searchUnreadNotificationsTotal({
        limit: DEFAULT_LIMIT,
        filter: {
          notificationCenterId: { eq: match.params.cid },
          notificationViewerId: { exists: false },
          markForDelete: { eq: false },
        },
        sort: {
          field: SearchableNotificationSortableFields.CREATION_DATE,
          direction: SearchableSortDirection.desc,
        },
      }),
    ];

    const configuration = getOperatorConfiguration(me?.operator_id);

    // Request the incidents only if the operator has it enabled
    if (isDisplayable(configuration, "sidebar.menu.management.incidents")) {
      promises.push(
        listNotViewedIncidents({
          buildingId: match.params.cid,
          offset: 0,
          limit: 20,
          filter: {
            viewed: { eq: false },
          },
        })
      );
    }

    Promise.all(promises);
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role !== EnumBackOfficeUserRole.OperatorAdmin) {
        fetchAuthorizations(me);
      } else {
        fetchCenters();
      }
    }
  }, [me]);

  const fetchAuthorizations = (meu: UserCognito) => {
    // search all authorizations
    searchAllAuthorizations({
      limit: DEFAULT_LIMIT,
      filter: {
        authorizationUserId: { eq: meu.id },
        entityType: { eq: EnumPermissionEntity.CENTER },
      },
    });
  };

  // We must load all centers
  const fetchCenters = () => {
    searchAllCenters({
      limit: DEFAULT_LIMIT,
      sort: {
        field: SearchableCenterSortableFields.name,
        direction: SearchableSortDirection.asc,
      },
      locale: currentAppLocale.backend_locale,
    });
  };

  const openDropdown = useCallback(() => setOpen(prevState => !prevState), []);

  const operatorAdmin = isOperatorAdmin(me);

  return (
    <div className="hidden md:flex md:flex-shrink-0 w-2/12 pt-20">
      <div className="flex flex-col w-full bg-neutral-300">
        <div className="h-0 flex-1 flex flex-col overflow-y-auto w-full">
          <nav className="flex-1">
            <button
              className="inline-block pt-2 relative min-w-full text-left"
              onClick={() => setOpen(!open)}
              type="button"
            >
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-ground-gray-100">
                <svg
                  className="fill-current text-blue-700 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              <div
                id="open-click-away-center"
                data-cy="open-click-away-center"
                className="block appearance-none w-full min-w-full px-7 py-5 focus:outline-none"
              >
                <span className="line-clamp-2 text-base font-semibold text-blue-700">
                  {getTranslation(center?.name)}
                </span>
              </div>
              {open && (
                <MyClickAwayListener
                  id="click-away-center"
                  item={center}
                  onClickAway={openDropdown}
                >
                  <CenterDropDown
                    open={open}
                    items={myCenters}
                    item={center}
                    onChange={handleChangeCenter}
                  />
                </MyClickAwayListener>
              )}
            </button>
            <div className="divide-gray-200">
              <Display paths={["sidebar.menu.building"]}>
                <NavItem
                  id="menu_general"
                  index={1}
                  icon={images.nav_building_2}
                  text="general.building"
                  type="general"
                >
                  <Display paths={["sidebar.menu.building.informations"]}>
                    <ChildNavItem
                      index={0}
                      text="general.informations"
                      onClick={e =>
                        goToPage(e, EnumPaths.INFORMATIONS, "general")
                      }
                      isActive={isNavItemActive(EnumPaths.INFORMATIONS)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.building.notifications"]}>
                    <ChildNavItem
                      index={1}
                      text="general.notifications"
                      counter={
                        unreadNotificationsTotal > 0
                          ? unreadNotificationsTotal
                          : undefined
                      }
                      onClick={e =>
                        goToPage(e, EnumPaths.NOTIFICATIONS, "general")
                      }
                      isActive={isNavItemActive(EnumPaths.NOTIFICATIONS)}
                    />
                  </Display>
                  {operatorAdmin && (
                    <Display paths={["sidebar.menu.building.documents"]}>
                      <ChildNavItem
                        index={2}
                        text="general.documents"
                        onClick={e =>
                          goToPage(e, EnumPaths.DOCUMENTS, "general")
                        }
                        isActive={isNavItemActive(EnumPaths.DOCUMENTS)}
                        newIcon="newIcon"
                      />
                    </Display>
                  )}
                </NavItem>
              </Display>
              <Display paths={["sidebar.menu.management"]}>
                <hr
                  className="mt-4 mb-2"
                  style={{ backgroundColor: "#ededf5", height: 1 }}
                />
                <NavItem
                  id="menu_pilot"
                  index={2}
                  icon={images.nav_pilot_2}
                  text="sidebar.menu.management"
                  type="pilot"
                >
                  <Display paths={["sidebar.menu.management.dashboards"]}>
                    <ChildNavItem
                      index={0}
                      text="sidebar.menu.management.dashboards"
                      onClick={e => goToPage(e, EnumPaths.DASHBOARD, "pilot")}
                      isActive={isNavItemActive(EnumPaths.DASHBOARD)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.management.calendar"]}>
                    <ChildNavItem
                      index={1}
                      text="sidebar.menu.management.calendar"
                      onClick={e => goToPage(e, EnumPaths.CALENDAR, "pilot")}
                      isActive={isNavItemActive(EnumPaths.CALENDAR)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.management.bookings"]}>
                    <ChildNavItem
                      index={2}
                      text="general.bookings"
                      onClick={e =>
                        goToPage(e, `${EnumPaths.BOOKINGS}`, "pilot")
                      }
                      isActive={isNavItemActive(`${EnumPaths.BOOKINGS}`)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.management.orders"]}>
                    <ChildNavItem
                      index={3}
                      text="general.orders"
                      onClick={e =>
                        goToPage(e, EnumPaths.GLOBAL_ORDERS, "pilot")
                      }
                      isActive={isNavItemActive(EnumPaths.GLOBAL_ORDERS)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.management.incidents"]}>
                    <ChildNavItem
                      index={4}
                      text="sidebar.menu.management.incidents"
                      counter={incidentsTotal > 0 ? incidentsTotal : undefined}
                      onClick={e => goToPage(e, EnumPaths.INCIDENTS, "pilot")}
                      isActive={isNavItemActive(EnumPaths.INCIDENTS)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.management.visits"]}>
                    <ChildNavItem
                      index={5}
                      text="sidebar.menu.management.visits"
                      onClick={e => goToPage(e, EnumPaths.VISITS, "pilot")}
                      isActive={isNavItemActive(EnumPaths.VISITS)}
                    />
                  </Display>
                </NavItem>
              </Display>
              <Display paths={["sidebar.menu.catalog"]}>
                <hr
                  className="mt-4 mb-2"
                  style={{ backgroundColor: "#ededf5", height: 1 }}
                />
                <NavItem
                  id="menu_catalog"
                  index={3}
                  icon={images.nav_catalog_2}
                  text="general.catalog"
                  type="catalog"
                >
                  <Display paths={["sidebar.menu.catalog.workspaces"]}>
                    <ChildNavItem
                      index={0}
                      text="general.workspace"
                      onClick={e => goToPage(e, EnumPaths.SPACES, "catalog")}
                      isActive={isNavItemActive(EnumPaths.SPACES)}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.catalog.services"]}>
                    <ChildNavItem
                      index={1}
                      text="general.services"
                      onClick={e => goToPage(e, EnumPaths.SERVICES, "catalog")}
                      isActive={isNavItemActive(EnumPaths.SERVICES)}
                    />
                  </Display>
                </NavItem>
              </Display>
              <Display paths={["sidebar.menu.communicate"]}>
                <hr
                  className="mt-4 mb-2"
                  style={{ backgroundColor: "#ededf5", height: 1 }}
                />
                <NavItem
                  id="menu_communicate"
                  index={4}
                  icon={images.nav_communication_2}
                  text="sidebar.menu.communicate"
                  type="communicate"
                >
                  <Display paths={["sidebar.menu.communicate.news"]}>
                    <ChildNavItem
                      index={0}
                      text="general.news"
                      onClick={e =>
                        goToPage(
                          e,
                          `${EnumPaths.MARKETING}/${EnumPaths.NEWS}`,
                          "communicate"
                        )
                      }
                      isActive={isNavItemActive(
                        `${EnumPaths.MARKETING}/${EnumPaths.NEWS}`
                      )}
                    />
                  </Display>
                  <Display paths={["sidebar.menu.communicate.events"]}>
                    <ChildNavItem
                      index={1}
                      text="general.events"
                      onClick={e =>
                        goToPage(
                          e,
                          `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`,
                          "communicate"
                        )
                      }
                      isActive={isNavItemActive(
                        `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`
                      )}
                    />
                  </Display>
                </NavItem>
              </Display>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
