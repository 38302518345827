import React, { useEffect, useState } from "react";
import { $enum } from "ts-enum-util";
import _ from "lodash";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../../../utils/overrideClasses";
import { FilterReporting } from "../../../../../components/Tailwind/Filters";
import {
  EnumReportingType,
  EnumReportingView,
} from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";
import PageSubheader from "../../../../../components/PageSubheader";
import ReportingView from "../../../../../components/Tailwind/ReportingView";
import { getReportingDates } from "../../../../../utils/filter";
import {
  EnumCurrency,
  EnumProductTypology,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import SalesCardList from "./sales-card-list";
import { ALL_FILTER } from "../../../../../components/Table";
import { getFormattedPrice } from "../../../../../utils/price-unit";
import ReportingTypology from "../../../../../components/Tailwind/ReportingTypology";
import {
  getTodayInCenterTimezone,
  getDateInUtcUsingOffset,
  addDuration,
  eachUnitOfInterval,
  isSameDates,
  transformDateForQuery,
  displayDayLabel,
} from "../../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const SalesDashboard = (props: Props): JSX.Element => {
  const { intl, match, backURL } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // init weekly view
  const [view, setView] = useState(EnumReportingView.WEEK);

  // init weekly view
  const [typology, setTypology] = useState<EnumProductTypology | undefined>();

  const now = getTodayInCenterTimezone(centerTimezone);
  const { current } = getReportingDates(now, view, centerTimezone);

  // now
  const [date, setDate] = useState(now);

  // current - start date
  const [startDate, setStartDate] = useState(current?.start);

  // current - end date
  const [endDate, setEndDate] = useState(current?.end);

  const days = eachUnitOfInterval(startDate, endDate, "day");

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const listReportingsSales = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listReportingsSales
  );

  const reportingsSales = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsSales.items
  );

  const types = $enum(EnumReportingType).getValues();
  const typologies = $enum(EnumProductTypology).getValues();

  const allCombinations = types.map(type => {
    return [undefined, ...typologies].map(r => {
      return {
        type: type,
        typology: r,
      };
    });
  });

  const combinations = _.flatten(allCombinations);

  const dataByTypeAndTypology = combinations.map(c => {
    const { type, typology } = c;

    let data1 = reportingsSales.filter(
      a => !a.createdStartDate && !a.createdEndDate && a.type === type
    );

    let data2 = reportingsSales.filter(
      a => a.createdStartDate && a.createdEndDate && a.type === type
    );

    if (typology) {
      data1 = data1.filter(a => a.typology === typology);
      data2 = data2.filter(a => a.typology === typology);
    } else {
      data1 = data1.filter(a => !a.typology);
      data2 = data2.filter(a => !a.typology);
    }

    return {
      type,
      typology,
      data: data1,
      range_date: data2,
    };
  });

  const dataAllForView = dataByTypeAndTypology.filter(d => !d.typology);
  console.log({ "dataAllForView::": dataAllForView });
  const dataByType = types.map(type => {
    const data1 = reportingsSales.filter(
      a => a.type === type && !a.createdStartDate && !a.createdEndDate
    );
    const data2 = reportingsSales.filter(
      a => a.type === type && a.createdStartDate && a.createdEndDate
    );

    return {
      type,
      data: data1,
      range_date: data2,
    };
  });

  const getReportingTypeData = (type: EnumReportingType) => {
    let typos = [...typologies];
    if (typology) {
      // filter by typo
      typos = typos.filter(typo => typo === typology);
    }
    const results = typos.map(typo => {
      const data = days.map(date => {
        const result = dataByType.find(a => a.type === type);
        const el = result?.range_date?.filter(r => r.typology === typo);

        const value = el?.find(
          e =>
            isSameDates(
              new Date(e.createdStartDate),
              getDateInUtcUsingOffset(date)
            ) &&
            isSameDates(
              new Date(e.createdEndDate),
              getDateInUtcUsingOffset(addDuration(date, 1, "day"))
            )
        );

        return {
          date: displayDayLabel(date, currentAppLocale, centerTimezone),
          amount: value?.amount || 0,
          variation: value?.variation || 0,
        };
      });

      return {
        name: typo,
        data,
      };
    });

    return results;
  };

  useEffect(() => {
    fetchData();
  }, [startDate, view, typology]);

  const fetchData = () => {
    setLoading(true);

    Promise.all([
      listReportingsSales({
        filter: {
          date: transformDateForQuery(startDate, centerTimezone, view),
          buildingId: match.params.cid,
          view,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (date: Date) => {
    const { current } = getReportingDates(date, view, centerTimezone);
    setDate(date);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const onPrevious = () => {
    const { previous } = getReportingDates(date, view, centerTimezone);

    setStartDate(previous.start);
    setEndDate(previous.end);
    setDate(previous.start);
  };

  const onNext = () => {
    const { next } = getReportingDates(date, view, centerTimezone);

    setStartDate(next.start);
    setEndDate(next.end);
    setDate(next.start);
  };

  const handleOnChangeReportingView = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const view = e.target.value as EnumReportingView;
    setView(view);
    const { current } = getReportingDates(now, view, centerTimezone);
    setDate(current.start);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const handleOnChangeReportingTypology = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value !== ALL_FILTER) {
      setTypology(value as EnumProductTypology);
    } else {
      setTypology(undefined);
    }
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        tools: {
          download: true,
          pan: false,
          reset: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: days.map(d =>
        displayDayLabel(d, currentAppLocale, centerTimezone)
      ),
    },
    colors: [
      "#309bff",
      "#29be95",
      "#f05e5e",
      "#ffe0a4",
      "#928cb8",
      "#d2e5eb",
      "#d417b9",
    ],
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    yaxis: {
      title: {
        text: intl.formatMessage({
          id: `page.dashboards.chart.amount.currency`,
        }),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return getFormattedPrice(val, EnumCurrency.EUR).toString();
        },
      },
    },
  };

  const getTitle = (type: EnumReportingType) => {
    return `${intl.formatMessage({
      id: `page.dashboards.chart.${view.toLowerCase()}.${type.toLowerCase()}.breakdown.title`,
    })}`;
  };

  const renderReporting = (type: EnumReportingType) => {
    const data = getReportingTypeData(type);
    const series = data.map(r => {
      return {
        name: r.name
          ? `${intl.formatMessage({
              id: `page.product.typology.${r.name}`,
            })}`
          : "",
        data: r.data.map(d => Number(d.amount.toFixed(2))),
      };
    });

    return (
      <div className="m-10" key={type}>
        <ReactApexChart
          options={{
            ...options,
            title: {
              text: getTitle(type),
              align: "left",
            },
          }}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId={`btn-back-occupancy-reporting`}
          goBackURL={backURL}
        />

        <div className="flex justify-between px-8">
          <div className="flex items-center gap-4">
            <ReportingView view={view} onChange={handleOnChangeReportingView} />

            <FilterReporting
              date={date}
              view={view}
              locale={currentAppLocale}
              onChangeDate={onChangeDate}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          </div>

          <div className="flex">
            <ReportingTypology
              typology={typology}
              onChange={handleOnChangeReportingTypology}
            />
          </div>
        </div>

        <div className="mx-8">
          <SalesCardList {...props} data={dataAllForView} />
          <div id="chart">{types.map(type => renderReporting(type))}</div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(SalesDashboard);
