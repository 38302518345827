import React, { useState } from "react";
import Select from "react-select";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { EnumRefundReason, getRefundReasons } from "../../../../utils/order";
import DropDownStatus from "../../../../routes/management/global-orders/global-orders-list/order/status";
import { Order } from "../../../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../../../lib/ground-aws-graphql-core/models/OrderItem";
import { EnumOrderStatus } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import IntlMessages from "../../../../utils/messages";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import { displayDayDDMMYYYY_HHMM } from "../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string; id: string }>;
  order: Order;
  onChangeOrderStatus: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    status: EnumOrderStatus
  ) => void;
  onRefund: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    reason: EnumRefundReason
  ) => void;
}

const HeaderOrder = (props: Props) => {
  const [reason, setReason] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const { intl, order, onChangeOrderStatus, onRefund, match } = props;

  const values = {};
  values[order.id] = false;

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const handleChangeOrderStatus = e => {
    if (
      e.value !== EnumOrderStatus.REFUNDED &&
      e.value !== EnumOrderStatus.REFUND_REQUESTED
    ) {
      setReason(false);
      onChangeOrderStatus(order, null, e.value);
    } else {
      setReason(true);
    }
  };

  return (
    <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col ">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center">
        <div className="mt-1">
          <div className="text-neutral-900 leading-5">
            <button
              className="mb-3 btn-link text-ground-blue-100 text-12px "
              type="button"
              onClick={e => {
                e.preventDefault();
                history.push({
                  pathname: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.GLOBAL_ORDERS}/${order.globalOrder?.id}`,
                  state: { from: history.location.pathname },
                });
              }}
            >
              <span className="mr-1 custom-arrow-left" />
              <IntlMessages
                id="page.global.order.number"
                values={{ globalOrderNumber: order.globalOrder?.ormOrder }}
              />
            </button>
          </div>
          <div className="text-17px text-neutral-900 leading-5">
            <IntlMessages
              id="page.order.number"
              values={{ orderNumber: order.ormOrder }}
            />
          </div>
          <div className="text-14px text-ground-gray-100 leading-relaxed">
            {displayDayDDMMYYYY_HHMM(order.timeplaced, centerTimezone)}
          </div>
        </div>
        <div className="flex sm:w-1/6 w-full">
          <DropDownStatus
            operator={meDetails?.operator}
            order={order}
            status={order.status}
            onChangeOrderStatus={handleChangeOrderStatus}
            displayRefundReason
            dropdownTitle="general.status"
            className="items-end"
          />
        </div>
      </div>
      {reason && (
        <div className="flex flex-col sm:flex-row justify-end sm:items-center sm:py-2 py-2">
          <div className="flex sm:w-1/6 w-full">
            <div className="flex justify-end items-center w-full">
              <div id="refund-reason" className="w-full">
                <Select
                  placeholder={intl.formatMessage({
                    id: "general.reason",
                  })}
                  defaultValue=""
                  onChange={e => {
                    onRefund(order, null, e.value);
                  }}
                  options={getRefundReasons(intl)}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(HeaderOrder);
