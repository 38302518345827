import { Buffer } from "buffer";
import {
  getBucket,
  getBucketRegion,
  getImageManagerApi,
  getImageHandlerApi,
  getTodayInCenterTimezone,
} from "../config";
import images from "../../images";
import { EnumPermissionEntity } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import axiosLegacy from "../../axiosLegacy";

window.Buffer = Buffer;

export const uploadPicture = async (
  fileName: string,
  picture: string,
  folder: string
): Promise<any> => {
  const base64Data = Buffer.from(
    picture.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );

  const token = await localStorage.getItem("token");
  const configs = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const data = {
    key: `${folder}/${fileName}.jpg`,
    image: base64Data,
  };

  const api = getImageManagerApi();

  return axiosLegacy.post(api + "/image", data, configs);
};

export const createPictureUrl = (key: string, folder: string): string => {
  const bucketRegion = getBucketRegion();
  const bucket = getBucket();
  const url = `https://s3.${bucketRegion}.amazonaws.com/${bucket}/${folder}/${key}`;

  return `${url}.jpg`;
};

export const bucketFolders = {
  CENTER: "centers",
  EVENT: "events",
  NEWS: "news",
  PRODUCT: "products",
  PROVIDER: "providers",
  SERVICE: "services",
  USER: "users",
  LEAD: "leads",
};

export const getImageUrl = (
  entity: EnumPermissionEntity | null,
  source: string | null,
  width = 320,
  height = 0
): any => {
  const defaultImage = getDefaultImage(entity);
  if (source) {
    const https = source.startsWith("https");
    if (https) {
      const bucket = getBucket();
      const api = getImageHandlerApi();

      const index = source.indexOf(bucket);
      const path = source.slice(index).replace(bucket, "");
      const iwidth = Math.round(width);
      const iheight = Math.round(height);

      const url = api ? `${api}/${iwidth}x${iheight}${path}` : defaultImage;

      return url;
    } else {
      return defaultImage;
    }
  }

  return defaultImage;
};

export const getDefaultImage = (entity: EnumPermissionEntity | null): any => {
  switch (entity) {
    case EnumPermissionEntity.USER:
    case EnumPermissionEntity.BACK_OFFICE_USER:
      return images.accounts;
    default:
      return images.defaultCenter;
  }
};
