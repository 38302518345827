import { uploadPicturesPromise } from "../Image";
import { ServiceOpts } from "../../models/Service";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";

export const createService = async (payload: ServiceOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.SERVICE
  );

  const { urls } = response;

  const params = {
    ...payload.values,
    name: payload.values.name,
    description: payload.values.description,
    pictures: urls,
    centerServicesId: payload.center.id,
    enabled: payload.enabled,
    markForDelete: false,
    authorized: true,
  };

  return await payload.callbacks.createService(params);
};

export const updateService = async (payload: ServiceOpts) => {
  const values = Object.assign({}, payload.values);

  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.SERVICE
  );

  const { urls } = response;

  delete values.providers;

  const params = {
    ...values,
    id: payload.service!.id,
    name: payload.values.name,
    description: !payload.values.description
      ? undefined
      : payload.values.description,
    pictures: urls,
    enabled: payload.enabled,
    markForDelete: false,
    authorized: true,
  };

  return await payload.callbacks.updateService(params);
};
