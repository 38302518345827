import React from "react";
import classnames from "classnames";
import Button from "../../Button";
import LinkButton from "../../LinkButton";
import IntlMessages from "../../../../utils/messages";
import { getCypressTestId } from "../../../../utils/config";

interface Props {
  item: { id: string } | null | undefined;
  index: number;
  last?: boolean;
  onDelete?: (e) => void;
  onCancel?: (e) => void;
  hideSubmitButton?: boolean;
  labels: string[];
  alternate?: boolean;
}

const Footer = (props: Props) => {
  props = {
    ...props,
    alternate: props.alternate != undefined ? props.alternate : true,
  };

  const className = id =>
    classnames(
      "py-5 border-b border-gray-200 px-6 flex flex-row justify-end items-center rounded-b-10",
      {
        "bg-white": props.alternate && id % 2 === 0,
        "bg-ground-white-200": props.alternate && !(id % 2 === 0),
      }
    );

  const classNameDeleteButton = classnames(
    "inline-flex items-center px-4 py-2 border a rounded-5 focus:outline-none transition ease-in-out duration-150",
    {
      "border-transparent text-white bg-red-500 hover:bg-white hover:text-red-500 hover:border-1 hover:border-red-500 focus:border-red-500 active:bg-red-500 active:text-white":
        true,
    }
  );

  let showButtonSumit = true;
  if (props.hideSubmitButton) {
    showButtonSumit = false;
  }

  return (
    <div className={className(props.index)}>
      {props.onDelete && (
        <div className="flex items-center justify-between px-2">
          <button
            id="btn-delete"
            name="btn-delete"
            data-cy="btn-delete"
            data-testid={getCypressTestId(props.item)}
            type="button"
            onClick={props.onDelete}
            className={classNameDeleteButton}
          >
            <span className="text-center">
              <IntlMessages id={props.labels[1]} />
            </span>
          </button>
        </div>
      )}
      {showButtonSumit && (
        <div className="flex items-center justify-between">
          <Button
            id="btn-validate"
            name="btn-validate"
            type="submit"
            item={props.item}
          >
            <span className="text-center">
              <IntlMessages id={"general.submit"} />
            </span>
          </Button>
        </div>
      )}
      {props.onCancel && (
        <div className="flex items-center justify-between px-2">
          <LinkButton
            id="link-cancel"
            name="link-cancel"
            label={props.labels[0]}
            onClick={props.onCancel}
          />
        </div>
      )}
    </div>
  );
};

export default Footer;
