import React, { useEffect, useState } from "react";
import Button from "../Tailwind/Button";
import { AttributeItem } from "../../utils/attribute";
import "./styles.css";
import IntlMessages from "../../utils/messages";

interface Props {
  attributes: AttributeItem[] | null;
  onCreateAccount: (e) => void;
  onLinkAccount: (s: string) => void;
}

const StripeConnect = (props: Props): JSX.Element => {
  const { attributes } = props;
  const [stripeAccountId, setStripeAccountId] = useState<
    string | null | undefined
  >(null);
  const [transactionFeePercent, setTransactionFeePercent] = useState<
    number | null | undefined
  >(null);
  const [stripeChargesEnabled, setStripeChargesEnabled] = useState<
    boolean | undefined
  >(false);
  const [stripePayoutsEnabled, setStripePayoutsEnabled] = useState<
    boolean | undefined
  >(false);

  useEffect(() => {
    if (attributes) {
      setStripeAccountId(
        attributes.find(
          e =>
            e.item?.key?.name ===
            process.env.REACT_APP_ATTRIBUTE_KEY_STRIPE_ACCOUNT_ID
        )?.item.stringValue
      );

      setStripeChargesEnabled(
        attributes.find(
          e =>
            e.item?.key?.name ===
            process.env.REACT_APP_ATTRIBUTE_KEY_STRIPE_CHARGES_ENABLED
        )?.item.booleanValue
      );

      setStripePayoutsEnabled(
        attributes.find(
          e =>
            e.item?.key?.name ===
            process.env.REACT_APP_ATTRIBUTE_KEY_STRIPE_PAYOUTS_ENABLED
        )?.item.booleanValue
      );

      setTransactionFeePercent(
        attributes.find(
          e =>
            e.item?.key?.name ===
            process.env.REACT_APP_ATTRIBUTE_KEY_STRIPE_TRANSACTION_FEE_PERCENT
        )?.item.numberValue
      );
    }
  }, [attributes]);

  const accountEnabled = stripeChargesEnabled && stripePayoutsEnabled;

  return (
    <div>
      <div className="py-5 px-6 w-full">
        {accountEnabled && transactionFeePercent && (
          <span className="text-ground-green-100">
            <IntlMessages id="page.provider.stripe.account.enabled" />
          </span>
        )}
        {accountEnabled && !transactionFeePercent && (
          <span className="text-red-500">
            <IntlMessages id="page.provider.stripe.account.no.transaction.fee" />
          </span>
        )}
        {!accountEnabled && (
          <span className="text-red-500">
            <IntlMessages id="page.provider.stripe.account.disabled" />
          </span>
        )}
      </div>
      <div className="py-5 px-12 w-full flex justify-around">
        <div>
          {!stripeAccountId && (
            <Button
              name="btn-create-connected-account"
              item={null}
              id="btn-create-connected-account"
              onClick={props.onCreateAccount}
              type="button"
            >
              <IntlMessages id="page.provider.stripe.onboarding.step.1" />
            </Button>
          )}
          {stripeAccountId &&
            (!stripeChargesEnabled || !stripePayoutsEnabled) && (
              <Button
                name="btn-create-account-link"
                item={null}
                id="btn-create-account-link"
                onClick={() => props.onLinkAccount(stripeAccountId)}
                type="button"
              >
                {!stripeChargesEnabled ? (
                  <IntlMessages id="page.provider.stripe.onboarding.step.2" />
                ) : (
                  <IntlMessages id="page.provider.stripe.onboarding.step.3" />
                )}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default StripeConnect;
