import _ from "lodash";
import { EnumServiceType } from "../../lib/ground-aws-graphql-core/api/graphql/types";

export const EnumPaths = {
  SIGNIN: "login",
  CHANGE_PASSWORD: "change/password",
  FORGOT_PASSWORD: "forgot/password",
  VALIDATE_PASSWORD: "validate/password",
  ERROR: "error",
  ROOT: "app",
  HOME: "home",
  PROVIDERS: "providers",
  CENTERS: "centers",
  NOTIFICATIONS: "notifications",
  INFORMATIONS: "informations",
  DOCUMENTS: "documents",
  DASHBOARD: "dashboards",
  CALENDAR: "calendar",
  ORDERS: "orders",
  GLOBAL_ORDERS: "global-orders",
  BOOKINGS: "bookings",
  BOOK_AVAILABILITY: "availability",
  BOOK: "book",
  OPTIONS: "options",
  SUMMARY: "summary",
  INTERVENTIONS: "interventions",
  USERS: "users",
  ADMINISTRATORS: "administrators",
  PARAMETERS: "parameters",
  WEBHOOKS: "parameters/webhooks",
  PAYMENT_METHODS: "parameters/payment_methods",
  INDICATORS: "indicators",
  INDICATORS_GLOBAL: "indicators/global",
  INDICATORS_USERS: "indicators/users",
  MARKETING: "marketing",
  NEWS: "news",
  EVENTS: "events",
  SERVICES: "services",
  SPACES: "spaces",
  PRODUCTS: "products",
  CARTS: "carts",
  CREATION_MODE: "creation",
  EDIT_MODE: "edit",
  ENTERPRISE: "enterprise",
  INCIDENTS: "incidents",
  VISITS: "visits",
  VISITORS: "visitors",
  OCCUPANCY: "occupancy",
  SALES: "sales",
  GLOBAL: "global",
};

export enum EnumBookingFrom {
  BOOKING_LIST,
  CALENDAR,
}

export const navTo = (url: string, id: string): string => {
  return `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${url}`;
};

export const getServicesPathByTypology = (
  serviceType: EnumServiceType
): string | undefined => {
  let path;
  if (EnumServiceType.SPACE === serviceType) {
    path = EnumPaths.SPACES;
  }
  if (EnumServiceType.SERVICE === serviceType) {
    path = EnumPaths.SERVICES;
  }

  return path;
};

export const getRedirectUrl = (path: string, id: string): string => {
  const url = _.replace(
    path,
    `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/:cid/`,
    ""
  );
  let value = url;

  switch (url) {
    case EnumPaths.INFORMATIONS:
      break;
    case EnumPaths.DASHBOARD:
      break;
    case EnumPaths.GLOBAL_ORDERS:
      break;
    case `${EnumPaths.GLOBAL_ORDERS}/:id`:
      value = `${EnumPaths.GLOBAL_ORDERS}`;
      break;
    case `${EnumPaths.BOOKINGS}`:
      break;
    case `${EnumPaths.BOOKINGS}/${EnumPaths.BOOK_AVAILABILITY}/:pid/${EnumPaths.BOOK}`:
      value = `${EnumPaths.INFORMATIONS}`;
      break;
    case `${EnumPaths.MARKETING}/${EnumPaths.NEWS}`:
      break;
    case `${EnumPaths.MARKETING}/${EnumPaths.NEWS}/:id/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.MARKETING}/${EnumPaths.NEWS}`;
      break;
    case `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`:
      break;
    case `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}/:id/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`;
      break;
    case `${EnumPaths.SERVICES}`:
      break;
    case `${EnumPaths.SERVICES}/:id`:
      value = `${EnumPaths.SERVICES}`;
      break;
    case `${EnumPaths.SERVICES}/:id/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.SERVICES}`;
      break;
    case `${EnumPaths.SERVICES}/:id/${EnumPaths.PRODUCTS}/:pid/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.SERVICES}`;
      break;
    case `${EnumPaths.SPACES}`:
      break;
    case `${EnumPaths.SPACES}/:id`:
      value = `${EnumPaths.SPACES}`;
      break;
    case `${EnumPaths.SPACES}/:id/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.SPACES}`;
      break;
    case `${EnumPaths.SPACES}/${EnumPaths.PRODUCTS}/:pid/${EnumPaths.EDIT_MODE}`:
      value = `${EnumPaths.SPACES}`;
      break;
    case `${EnumPaths.ORDERS}/:id`:
      value = `${EnumPaths.GLOBAL_ORDERS}`;
      break;
    case `${EnumPaths.INCIDENTS}/:id`:
      value = `${EnumPaths.INCIDENTS}`;
      break;
    case `${EnumPaths.VISITS}/:id`:
      value = `${EnumPaths.VISITS}`;
      break;
    default:
      break;
  }

  return navTo(value, id);
};

export const getSpecificIdFromUrl = (
  url: string,
  elementStringUrl: string
): string => {
  const index = url.indexOf(elementStringUrl);

  if (index !== -1) {
    const indexEnd = url.indexOf("/", index + elementStringUrl.length);

    return url.substring(
      index + elementStringUrl.length,
      indexEnd !== -1 ? indexEnd : undefined
    );
  }

  return "";
};
