import React from "react";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import IntlMessages from "../../../utils/messages";
import { ICountry, ILocale } from "../../../lang";

interface Props {
  name: string;
  value: string;
  label: string;
  index: number;
  required?: boolean;
  onChange: (e) => void;
  grid?: number;
  className?: string;
  useIntl: boolean;
  alternate?: boolean;
  locale?: ILocale;
  countries?: ICountry[];
}

const PhoneNumberInput = (props: Props) => {
  const {
    useIntl,
    index,
    name,
    className,
    label,
    required,
    grid,
    countries,
    locale,
    value,
    onChange,
  } = props;

  let { alternate } = props;
  alternate = alternate || true;

  const newClassName = grid ? `sm:grid-cols-${grid}` : "sm:grid-cols-3";
  const containerClassName = id =>
    classnames(
      `sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 ${newClassName}`,
      className,
      {
        "bg-ground-white-200": alternate && id % 2 === 0,
      }
    );

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": grid === 4,
    }
  );
  const countryCodes = countries?.map(c => c.id.toLowerCase());

  return (
    <div className={containerClassName(index)}>
      <label htmlFor={name} className={labelClassName}>
        {useIntl && <IntlMessages id={label} />}
        {!useIntl && `${label}`} {required && "*"}
      </label>
      <PhoneInput
        country={locale ? locale.country.toLowerCase() : ""}
        onlyCountries={countryCodes}
        value={value}
        inputClass="rounded-md shadow-sm sm:text-14px text-ground-black-100 border-ground-gray-500 max-w-xs w-80 h-10"
        dropdownClass="rounded-md"
        onChange={onChange}
      />
    </div>
  );
};

export default PhoneNumberInput;
