import React, { useState, useEffect } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { FieldAttributes } from "formik";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import ImageGallery from "../../../../components/Tailwind/ImageGallery";
import {
  Image,
  ActionTypes,
  getEventTypeItems,
  EnumAction,
} from "../../../../utils/types";
import CustomDatePicker from "../../../../components/Tailwind/DatePicker";
import contextStore from "../../../../redux/store";
import Footer from "../../../../components/Tailwind/Block/Footer";
import Button from "../../../../components/Tailwind/Button";
import ModalCreateEventType from "../../event-type";
import ConfirmModal from "../../../../utils/modal/confirm";
import {
  DATE_FORMAT_HOUR,
  getCypressTestId,
  getTodayInCenterTimezone,
  isAfter,
  isBefore,
} from "../../../../utils/config";
import IntlMessages from "../../../../utils/messages";
import {
  EnumPermissionEntity,
  SearchableParticipationSortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { EventType } from "../../../../lib/ground-aws-graphql-core/models/EventType";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import FormQuill from "../../../../components/Form/FormQuill";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import Table from "../../../../components/Table";
import { getImageUrl } from "../../../../utils/picture";
import Avatar from "../../../../components/Tailwind/Avatar";
import Badge from "../../../../components/Tailwind/Badge";
import { getRoleLabel } from "../../../../utils/user";
import { getSortFields } from "../../../../utils/filter";
import overrideClasses from "../../../../utils/overrideClasses";

interface Props {
  handleClose?: () => void;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const LIMIT = 20;

const EventForm = (props: Props): JSX.Element => {
  const { intl, edition, match, handleClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // initial date
  const date = getTodayInCenterTimezone(centerTimezone, true);

  const [startDate, setStartDate] = useState<Date | null>(
    !edition ? date : null
  );
  const [endDate, setEndDate] = useState<Date | null>(!edition ? date : null);

  const [publicationDate, setPublicationDate] = useState<Date | null>(
    !edition ? date : null
  );
  const [unpublicationDate, setUnpublicationDate] = useState<Date | null>(
    !edition ? date : null
  );

  const [isValidStart, setIsValidStart] = useState(true);
  const [isValidEnd, setIsValidEnd] = useState(true);

  const [isValidPublication, setIsValidPublication] = useState(true);
  const [isValidUnpublication, setIsValidUnpublication] = useState(true);

  const [pictures, setPictures] = useState([] as Image[]);
  const [enabled, setEnabled] = useState<boolean>(true);

  const [togglePanel, setTogglePanel] = useState(false);

  const [eventType, setEventType] = useState<EventType | null | undefined>(
    null
  );
  const [modalEventTypeOpen, setModalEventTypeOpen] = useState(false);
  const [action, setAction] = useState("");

  const getEvent = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.getEvent
  );

  const setEvent = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.setEvent
  );

  const participants = GroundGraphqlContextStore.useStoreState(
    state => state.participation.participations.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.participation.participations.total
  );

  const searchParticipants = GroundGraphqlContextStore.useStoreActions(
    actions => actions.participation.searchParticipations
  );

  const listEventTypes = GroundGraphqlContextStore.useStoreActions(
    actions => actions.eventType.listEventTypes
  );

  const updateEventAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.updateEvent
  );

  const deleteEventAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.deleteEvent
  );

  const createEventAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.createEvent
  );

  const eventTypes = GroundGraphqlContextStore.useStoreState(
    state => state.eventType.eventTypes.items
  );

  const updateEvent = contextStore.useStoreActions(
    actions => actions.event.updateEvent
  );

  const createEvent = contextStore.useStoreActions(
    actions => actions.event.createEvent
  );

  const event = GroundGraphqlContextStore.useStoreState(
    state => state.event.event
  );

  const handleDelete = (id: string) => {
    setLoading(true);
    deleteEventAction({ id })
      .then(() => {
        const centerId = match.params.cid;
        history.push(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`
        );
        toast(
          intl.formatMessage({
            id: "general.event.delete",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "general.event.delete",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = values => {
    if (startDate && endDate && publicationDate && unpublicationDate) {
      setLoading(true);
      if (edition) {
        updateEvent({
          event,
          values: { ...values, eventTypeId: eventType?.id },
          pictures,
          center: {
            id: match.params.cid,
          },
          start: startDate,
          end: endDate,
          publicationDate: publicationDate,
          unpublicationDate: unpublicationDate,
          enabled,
          callbacks: {
            updateEvent: updateEventAction,
            createEvent: createEventAction,
          },
        })
          .then(() => {
            setLoading(true);
            const centerId = match.params.cid;
            history.push(
              `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`
            );
            toast(
              intl.formatMessage({
                id: "page.event.update.event.success",
              }),
              { type: "success" }
            );
          })
          .catch(() => {
            toast(
              intl.formatMessage({
                id: "page.event.update.event.error",
              }),
              {
                type: "error",
              }
            );
          })
          .finally(() => setLoading(false));
      } else {
        createEvent({
          event: null,
          values: { ...values, eventTypeId: eventType?.id },
          pictures,
          center: {
            id: match.params.cid,
          },
          start: startDate,
          end: endDate,
          publicationDate: publicationDate,
          unpublicationDate: unpublicationDate,
          enabled,
          callbacks: {
            updateEvent: updateEventAction,
            createEvent: createEventAction,
          },
        })
          .then(() => {
            const centerId = match.params.cid;
            history.push(
              `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`
            );
            toast(
              intl.formatMessage({
                id: "page.event.create.event.success",
              }),
              { type: "success" }
            );
          })
          .catch(() => {
            toast(
              intl.formatMessage({
                id: "page.event.create.event.error",
              }),
              {
                type: "error",
              }
            );
          })
          .finally(() => setLoading(false));
      }
    } else {
      setIsValidStart(!!startDate);
      setIsValidEnd(!!endDate);
    }
  };

  useEffect(() => {
    setIsValidStart(!!startDate);
    setIsValidEnd(!!endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    setIsValidPublication(!!publicationDate);
    setIsValidUnpublication(!!unpublicationDate);
  }, [publicationDate, unpublicationDate]);

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setEvent(null);
    };
  }, []);

  const fetchData = (
    pageIndex = 0,
    loader = true,
    sort?: {
      field: string;
      direction: SearchableSortDirection;
    }
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter;
    const defaultSort = getSortFields(EnumPermissionEntity.PARTICIPATION)[0];
    const defaultSortField =
      SearchableParticipationSortableFields[defaultSort.field];
    const defaultSortDirection = defaultSort.directions[0];
    const defautSort = {
      field: defaultSortField,
      direction: defaultSortDirection,
    };
    const promises = [
      listEventTypes({
        filter: {
          centerEventTypesId: { eq: match.params.cid },
          markForDelete: { eq: false },
        },
      }),
    ];

    if (edition) {
      promises.push(
        getEvent({ id: match.params.id }),
        searchParticipants({
          limit: LIMIT,
          filter: {
            ...filter,
            eventParticipationsId: { eq: match.params.id },
          },
          from: pageIndex * LIMIT,
          sort: sort ? { ...sort } : defautSort,
        })
      );
    }

    Promise.all(promises).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (event) {
      setEnabled(event.enabled ? event.enabled : false);

      if (event.start) setStartDate(new Date(event.start));
      if (event.end) setEndDate(new Date(event.end));
      if (event.publicationDate)
        setPublicationDate(new Date(event.publicationDate));
      if (event.unpublicationDate)
        setUnpublicationDate(new Date(event.unpublicationDate));

      if (event.picture) {
        const eventPictures: Image[] = [];
        eventPictures.push({
          picture: event.picture,
          source: false,
          action: ActionTypes.TO_KEEP,
        });
        setPictures(eventPictures);
      }
      setEventType(event.type);
    }
  }, [event]);

  const handleAddPicture = (picture: string | ArrayBuffer) => {
    const items = [...pictures];
    items.push({ picture, source: true, action: ActionTypes.TO_ADD });
    setPictures(items);

    handleShowSavePanel();
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({ ...el, action: ActionTypes.TO_DELETE });
      setPictures(picturesToDelete);
    }

    handleShowSavePanel();
  };

  const eventTypeItems = getEventTypeItems(eventTypes);

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleChangeEventType = e => {
    const eventTypeId = e.target.value;

    const currentEventType = eventTypeId
      ? eventTypes?.find(et => et.id === eventTypeId)
      : null;

    if (currentEventType) setEventType(currentEventType);

    handleShowSavePanel();
  };

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "title",
      label: "general.title",
      placeholder: "general.title",
      initialValue: event?.title,
      required: true,
      translatable: true,
    },
    {
      name: "eventTypeId",
      label: "general.type",
      placeholder: "general.type",
      initialValue: eventType?.id,
      required: true,
      as: "select",
      options: eventTypeItems,
      onChange: handleChangeEventType,
      value: eventType?.id,
      children: (
        <div className="mt-4">
          <button
            id="btn-modify-event-type"
            name="btn-modify-event-type"
            data-cy="btn-modify-event-type"
            data-testid={getCypressTestId(eventType)}
            type="button"
            onClick={() => {
              setAction(EnumAction.UPDATE);
              setModalEventTypeOpen(true);
            }}
            className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
          >
            <span>
              <IntlMessages id="page.list.events.update.event.type" />
            </span>
          </button>
        </div>
      ),
      thirdColComponent: (
        <div>
          <Button
            id="btn-add-event-type"
            name="btn-add-event-type"
            data-cy="btn-add-event-type"
            item={null}
            type="button"
            outline
            onClick={() => {
              setAction(EnumAction.CREATE);
              setModalEventTypeOpen(true);
            }}
          >
            <IntlMessages id="page.list.events.add.event.type" />
          </Button>
        </div>
      ),
    },
    {
      name: "description",
      label: "general.description",
      placeholder: "general.description",
      initialValue: event?.description,
      required: true,
      translatable: true,
      component: formikProps => <FormQuill {...formikProps} />,
    },
  ];

  const labels = {
    creation: "page.list.events.create.event",
    edition: "page.list.events.update.event",
    participants: "page.list.events.participations",
  };

  const thead = [
    "page.event.participant.name",
    "general.role",
    "page.event.participant.response",
  ];

  const tbody = participants?.map(participation => {
    const { value, user } = participation;

    return {
      rowElements: [
        {
          element: (
            <div className="flex">
              <Avatar
                picture={
                  user?.picture
                    ? getImageUrl(EnumPermissionEntity.USER, user.picture)
                    : null
                }
                notification
                enabled={user?.enabled || false}
              />
              <div className="ml-4">
                <div className="text-sm leading-5 font-medium text-ground-black-100">
                  {user?.lastname} {user?.firstname}
                </div>
                <div className="text-sm leading-5 text-gray-500">
                  {user?.email}
                </div>
              </div>
            </div>
          ),
        },
        {
          element: (
            <Badge bgClassName="bg-ground-blue-200">
              {getRoleLabel(intl, user?.role)}
            </Badge>
          ),
        },
        {
          element: (
            <span className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
              {intl.formatMessage({ id: `page.event.participation.${value}` })}
            </span>
          ),
        },
      ],
    };
  });

  return edition && (!event || !eventType) ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8", { hidden: loading })}>
        <Block>
          <Header
            item={event}
            title={edition ? labels.edition : labels.creation}
            entity={EnumPermissionEntity.EVENT}
            checked={enabled}
            onChange={e => {
              setEnabled(e);
              handleShowSavePanel();
            }}
            className="border-b border-gray-200"
          />
          <ModalCreateEventType
            {...props}
            isOpen={modalEventTypeOpen}
            toggle={() => {
              setModalEventTypeOpen(!modalEventTypeOpen);
            }}
            action={action}
            eventType={action === EnumAction.UPDATE ? eventType : null}
            onRequestClose={() => setModalEventTypeOpen(!modalEventTypeOpen)}
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          >
            <>
              <CustomDatePicker
                name="general.startdate"
                label="general.startdate"
                index={formFields.length}
                minDate={date}
                selected={startDate}
                onChange={selectedDate => {
                  setStartDate(selectedDate);

                  if (endDate && isBefore(endDate, selectedDate))
                    setEndDate(selectedDate);

                  if (publicationDate && isAfter(publicationDate, selectedDate))
                    setPublicationDate(selectedDate);

                  handleShowSavePanel();
                }}
                selector="selectsStart"
                startDate={startDate}
                endDate={endDate}
                dateFormat="Pp"
                showTimeSelect
                timeFormat={DATE_FORMAT_HOUR}
                timeIntervals={5}
                timeCaption={intl.formatMessage({ id: "general.hour" })}
                invalid={!isValidStart}
              />
              <CustomDatePicker
                name="general.enddate"
                label="general.enddate"
                index={formFields.length + 1}
                minDate={startDate}
                selected={endDate}
                onChange={selectedDate => {
                  setEndDate(selectedDate);

                  if (startDate && isAfter(startDate, selectedDate))
                    setStartDate(selectedDate);

                  if (
                    unpublicationDate &&
                    isBefore(unpublicationDate, selectedDate)
                  )
                    setUnpublicationDate(selectedDate);

                  handleShowSavePanel();
                }}
                selector="selectsEnd"
                startDate={startDate}
                endDate={endDate}
                dateFormat="Pp"
                showTimeSelect
                timeFormat={DATE_FORMAT_HOUR}
                timeIntervals={5}
                timeCaption={intl.formatMessage({ id: "general.hour" })}
                invalid={!isValidEnd}
              />
            </>
            <>
              <CustomDatePicker
                name="general.publicationDate"
                label="general.publicationDate"
                index={formFields.length + 2}
                minDate={date}
                maxDate={startDate}
                selected={publicationDate}
                onChange={selectedDate => {
                  setPublicationDate(selectedDate);

                  if (startDate && isBefore(startDate, selectedDate))
                    setStartDate(selectedDate);

                  if (
                    unpublicationDate &&
                    isBefore(unpublicationDate, selectedDate)
                  )
                    setUnpublicationDate(selectedDate);

                  handleShowSavePanel();
                }}
                selector="selectsStart"
                startDate={publicationDate}
                endDate={unpublicationDate}
                dateFormat="Pp"
                showTimeSelect
                timeFormat={DATE_FORMAT_HOUR}
                timeIntervals={5}
                timeCaption={intl.formatMessage({ id: "general.hour" })}
                invalid={!isValidPublication}
              />
              <CustomDatePicker
                name="general.unpublicationDate"
                label="general.unpublicationDate"
                index={formFields.length + 3}
                minDate={endDate}
                selected={unpublicationDate}
                onChange={selectedDate => {
                  setUnpublicationDate(selectedDate);

                  if (publicationDate && isAfter(publicationDate, selectedDate))
                    setPublicationDate(selectedDate);

                  if (endDate && isAfter(endDate, selectedDate))
                    setEndDate(selectedDate);

                  handleShowSavePanel();
                }}
                selector="selectsEnd"
                startDate={publicationDate}
                endDate={unpublicationDate}
                dateFormat="Pp"
                showTimeSelect
                timeFormat={DATE_FORMAT_HOUR}
                timeIntervals={5}
                timeCaption={intl.formatMessage({ id: "general.hour" })}
                invalid={!isValidUnpublication}
              />
            </>
            <ImageGallery
              entity={event}
              label="general.image.gallery.photos"
              index={formFields.length + 2}
              max={1}
              images={pictures}
              onAddImage={handleAddPicture}
              onRemoveImage={handleRemovePicture}
            />
            {event && edition && (
              <>
                <Footer
                  item={event}
                  index={formFields.length + 3}
                  labels={["general.cancel", "general.delete"]}
                  onCancel={handleClose}
                  onDelete={() => setShowDeleteModal(!showDeleteModal)}
                />
                <ConfirmModal
                  item={event}
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                  onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
                  handleConfirm={() => handleDelete(event.id)}
                  content={<IntlMessages id="page.event.delete.event" />}
                />
              </>
            )}
            {!edition && (
              <Footer
                item={event}
                index={formFields.length + 3}
                labels={["general.cancel", "general.delete"]}
                onCancel={handleClose}
              />
            )}
          </DefaultForm>
        </Block>

        {event && edition && (
          <Block>
            <Table
              head={thead}
              body={tbody}
              onChange={({ pageIndex, sort }) => {
                fetchData(pageIndex, false, sort);
              }}
              loading={loading}
              paginationLimit={LIMIT}
              paginationTotal={total}
              noDataText="page.event.list.participations.empty"
            />
          </Block>
        )}
      </div>
    </>
  );
};
export default EventForm;
