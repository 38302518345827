import React, { useState } from "react";
import ModalAddOption from "../../../../routes/catalog/product/options/option-modal";
import { ActionTypes } from "../../../../utils/types";
import IntlMessages from "../../../../utils/messages";
import { getCypressTestId } from "../../../../utils/config";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import Add from "../../Button/Add";

interface Props {
  item: { id: string } | null;
  title: string;
  description?: string;
  onAddOption: (o: Product) => void;
  onRemoveOption: (o: Product) => void;
  items: { id: string; option: Product; action: ActionTypes }[];
}

const HeaderOption = (props: Props) => {
  const { title, description, item } = props;
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex justify-between sm:items-center">
        <div>
          <h3 className="text-16px text-ground-black-100">
            <IntlMessages id={title} />
          </h3>
          {description && (
            <p className="mt-1 text-13px text-ground-gray-100">
              <IntlMessages id={description} />
            </p>
          )}
        </div>
        <Add
          dataCy="link-add-option"
          dataTestid={getCypressTestId(item)}
          label="general.add"
          onClick={handleOpenModal}
        />
      </div>
      {open && (
        <ModalAddOption
          {...props}
          isOpen={open}
          onRequestClose={handleOpenModal}
        />
      )}
    </>
  );
};

export default HeaderOption;
