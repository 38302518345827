import React from "react";
import Button from "../Base";
import { ButtonProps } from "../index";

const Secondary = ({
  item,
  children,
  type,
  id,
  name,
  onClick,
  small,
}: ButtonProps) => {
  const fontSize = small ? "sm:text-xs" : "sm:text-sm";
  const secondaryClassName = "bg-transparent text-ground-gray-300 " + fontSize;

  return (
    <Button
      item={item}
      id={id}
      name={name}
      type={type}
      className={secondaryClassName}
      onClick={onClick}
      small={small}
    >
      {children}
    </Button>
  );
};

export default Secondary;
