import { AxiosResponse } from "axios";
import axiosBackend from "../../../../axiosBackend";
import axiosLegacy from "../../../../axiosLegacy";

export interface SignInOpts {
  email: string;
  password: string;
  newPassword?: string;
  mfaCode?: string;
  operator?: string;
}

export interface ReCaptchaOpts {
  token: string;
  scoreToPass: number;
}
export interface ForgotPasswordOpts {
  email: string;
}

export interface ConfirmPasswordOpts {
  email: string;
  confirmation_code: string;
  password: string;
  // operator: string;
}

export interface RefreshTokenOpts {
  refreshToken: string;
  token: string;
  admin?: boolean;
}

/**
 * SignIn
 *
 * @param {SignInOpts} payload
 * @return - A promise resolves tokens
 */
export const signIn = async (payload: SignInOpts): Promise<AxiosResponse> => {
  return axiosBackend.post("/admin/signin", payload);
};

/**
 * forgot password admin user
 *
 * @param email
 * @returns
 */
export const forgotPassword = async (email: string): Promise<AxiosResponse> => {
  return axiosBackend.post("/admin/forgot_password", { email });
};

/**
 * Confirm forgotten password
 *
 * @param {ForgotPasswordOpts} payload
 * @return - A promise resolves tokens
 */
export const confirmPassword = async (payload: ConfirmPasswordOpts) => {
  return axiosBackend.post("/admin/forgot_password/confirmation", payload);
};

/**
 * refreshToken
 *
 * @param {RefreshTokenOpts} payload
 * @return - A promise resolves callback data if success
 */
export const refreshToken = async (payload: RefreshTokenOpts) => {
  const body = { refreshToken: payload.refreshToken };
  const path = "/refreshtoken";
  const url = process.env.REACT_APP_AUTHENTICATION_API;

  return axiosLegacy.post(url + path, body, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  });
};

/**
 * Log out
 */
export const logOut = async () => {
  return axiosBackend.post("/log_out");
};

/**
 *
 * @return - A promise
 */
export const getMe = async () => {
  return axiosBackend.get("/me");
};

/**
 *
 * @return - A promise
 */
export const getMeDetails = async () => {
  return axiosBackend.get("/me/details");
};

export const validateRecaptcha = async (payload: ReCaptchaOpts) => {
  const body = { token: payload.token };
  const url = process.env.REACT_APP_AUTHENTICATION_API;

  return axiosLegacy.post(`${url}/recaptcha`, body, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
