import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import {
  getRefundReason,
  getOrderItemTotal,
  getOrderTotal,
} from "../../../../../../utils/order";
import IntlMessages from "../../../../../../utils/messages";
import { Order } from "../../../../../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../../../../../lib/ground-aws-graphql-core/models/OrderItem";
import {
  EnumChannel,
  EnumOrderStatus,
} from "../../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import FilterSelect from "../../../../../../components/Tailwind/Filters/select";
import {
  EnumFilterFields,
  EnumFilterType,
} from "../../../../../../utils/filter";
import { displayDayDDMMYYYY_HHMM } from "../../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../../lib/ground-aws-graphql-core";

interface Status {
  label: string;
  nextStatus: NextStatus[];
}

export interface NextStatus {
  value: EnumOrderStatus;
  label: string;
}

export const getStatusLabel = (intl: IntlShape, status: string): string => {
  switch (status) {
    case EnumOrderStatus.AWAITING_PAYMENT:
    case EnumOrderStatus.PAID:
    case EnumOrderStatus.PROCESSING:
    case EnumOrderStatus.COMPLETED:
    case EnumOrderStatus.CANCELED:
    case EnumOrderStatus.REFUND_REQUESTED:
    case EnumOrderStatus.REFUNDED:
      return intl.formatMessage({ id: `page.list.orders.status.${status}` });
    default:
      return intl.formatMessage({ id: "general.all" });
  }
};

export const getStatus = (
  intl: IntlShape,
  status: EnumOrderStatus | null | undefined
): Status => {
  switch (status) {
    case EnumOrderStatus.AWAITING_PAYMENT:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.AWAITING_PAYMENT),
        nextStatus: [
          {
            value: EnumOrderStatus.AWAITING_PAYMENT,
            label: getStatusLabel(intl, EnumOrderStatus.AWAITING_PAYMENT),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
        ],
      };
    case EnumOrderStatus.PAID:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.PAID),
        nextStatus: [
          {
            value: EnumOrderStatus.PAID,
            label: getStatusLabel(intl, EnumOrderStatus.PAID),
          },
          {
            value: EnumOrderStatus.PROCESSING,
            label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
          },
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    case EnumOrderStatus.PROCESSING:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
        nextStatus: [
          {
            value: EnumOrderStatus.PROCESSING,
            label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
          },
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    case EnumOrderStatus.COMPLETED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
        nextStatus: [
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    case EnumOrderStatus.CANCELED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
        nextStatus: [
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    case EnumOrderStatus.REFUND_REQUESTED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.REFUND_REQUESTED),
        nextStatus: [
          {
            value: EnumOrderStatus.REFUND_REQUESTED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUND_REQUESTED),
          },
        ],
      };
    case EnumOrderStatus.REFUNDED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
        nextStatus: [
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    default:
      return {
        label: status || "",
        nextStatus: [],
      };
  }
};

export const getCurrentStatus = (
  intl: IntlShape,
  status: EnumOrderStatus | null | undefined
): NextStatus | null | undefined => {
  const value: Status = getStatus(intl, status);
  const { nextStatus } = value;
  const result = nextStatus.find(e => e.value === status);

  return result;
};

interface Props {
  operator: { id: string; payment_externalized: boolean } | null | undefined;
  order: Order;
  status: EnumOrderStatus | null | undefined;
  onChangeOrderStatus: (e) => void;
  displayRefundReason: boolean;
  intl: IntlShape;
  orderItem?: OrderItem;
  dropdownTitle?: string;
  className?: string;
}

const DropDownStatus = (props: Props) => {
  const {
    operator,
    order,
    status,
    onChangeOrderStatus,
    displayRefundReason,
    intl,
    orderItem,
    className,
  } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const current = getCurrentStatus(intl, status);
  const { payment } = order;
  const paymentMethodDetails = payment?.paymentMethodDetails;
  const json = paymentMethodDetails ? JSON.parse(paymentMethodDetails) : {};
  const { type } = json;
  const paymentOnInvoice = type === "invoice";

  const total = orderItem ? getOrderItemTotal(orderItem) : getOrderTotal(order);
  const options = getStatus(intl, status);

  // free case
  if (
    total <= 0 ||
    (paymentOnInvoice && !operator?.payment_externalized) ||
    ![EnumChannel.MOBILE, EnumChannel.WEB].includes(order.channel)
  ) {
    if (current && current.value === EnumOrderStatus.PAID) {
      current.label = intl.formatMessage({ id: "page.order.confirmed" });
    }

    const nextStatus = options.nextStatus.reduce(
      (acc: NextStatus[], el: NextStatus) => {
        if (
          el.value !== EnumOrderStatus.REFUNDED &&
          el.value !== EnumOrderStatus.REFUND_REQUESTED
        ) {
          const newEl = { ...el };
          if (el.value === EnumOrderStatus.PAID) {
            newEl.label = intl.formatMessage({ id: "page.order.confirmed" });
          }
          acc.push(newEl);
        }

        return acc;
      },
      []
    );

    options.nextStatus = nextStatus;
  }

  const orderItems = order.orderItems?.items;

  let disabled = false;
  if (orderItem) {
    // orderitem dropdown status
    disabled = options.nextStatus.length <= 1;
  } else if (orderItems && orderItems?.length > 1) {
    // more than one items
    const orderItemStatus = orderItems.reduce((acc: EnumOrderStatus[], el) => {
      if (el.status) {
        const includes = acc.includes(el.status);
        if (!includes) {
          acc.push(el.status);
        }
      }

      return acc;
    }, []);

    if (orderItemStatus.length > 1) {
      disabled = true;
    } else {
      disabled = options.nextStatus.length <= 1;
    }
  } else {
    disabled = options.nextStatus.length <= 1;
  }

  const refund = orderItem?.refund;

  const itemId = orderItem ? orderItem.id : order.id;
  const itemName = orderItem
    ? "order-items-status-selector"
    : "order-status-selector";

  const field = {
    value: EnumFilterFields[EnumFilterFields.STATUS],
    type: EnumFilterType.SELECT,
    async: false,
    options: options.nextStatus.map(o => ({
      ...o,
      value: `${o.value}`,
    })),
  };

  return (
    <div className={`flex flex-col ${className} w-full`} key={itemId}>
      <div id={itemId} data-cy={itemName} data-testid={itemId}>
        <FilterSelect
          selectedValue={current?.value}
          defaultValue={false}
          disabled={disabled}
          className="w-48"
          fields={[field]}
          onChange={(_type, target) => {
            return onChangeOrderStatus(target);
          }}
        />
      </div>
      {displayRefundReason &&
        refund &&
        (current?.value === EnumOrderStatus.REFUNDED ||
          current?.value === EnumOrderStatus.REFUND_REQUESTED) && (
          <div className="flex flex-col justify-end w-full mt-2">
            <span className="text-12px text-ground-gray-100">
              <IntlMessages id="page.order.refunded.at" />{" "}
              {displayDayDDMMYYYY_HHMM(refund.date, centerTimezone)}
            </span>
            <span className="text-12px text-ground-gray-100">
              <IntlMessages id="general.reason" />:{" "}
              {getRefundReason(intl, refund.reason)?.label}
            </span>
          </div>
        )}
    </div>
  );
};

export default injectIntl(DropDownStatus);
