import { uploadPicturesPromise } from "../Image";
import { ProviderOpts } from "../../models/Provider";
import {
  CreateProviderInput,
  EnumPermissionEntity,
  UpdateProviderInput,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { convertAttributeItems } from "../../../utils/preprocessing";

export const createProvider = async (payload: ProviderOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.PROVIDER
  );

  const { urls } = response;

  const attributes = payload.attributes;

  let params: CreateProviderInput = {
    name: payload.values.name,
    description: payload.values.description,
    address: payload.values.address,
    zipCode: payload.values.zipCode,
    city: payload.values.city,
    country: payload.values.country,
    pictures: urls,
    enabled: payload.enabled,
    phone: payload.values.phone,
    receivingEmails: payload.values.receivingEmails,
    attributes: attributes && convertAttributeItems(attributes),
  };

  if (payload.values.email) {
    params = { ...params, email: payload.values.email };
  }

  const providerCreate = await payload.callbacks.createProvider(params);

  return providerCreate;
};

export const updateProvider = async (payload: ProviderOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.EVENT
  );
  const { urls } = response;
  const attributes = payload.attributes;

  let params: UpdateProviderInput = {
    id: payload.provider!.id,
    name: payload.values.name,
    description: payload.values.description,
    address: payload.values.address,
    zipCode: payload.values.zipCode,
    city: payload.values.city,
    country: payload.values.country,
    pictures: urls,
    enabled: payload.enabled,
    phone: payload.values.phone,
    receivingEmails: payload.values.receivingEmails,
    attributes: attributes && convertAttributeItems(attributes),
  };

  if (payload.values.email) {
    params = { ...params, email: payload.values.email };
  }

  const providerUpdate = await payload.callbacks.updateProvider(params);

  return providerUpdate;
};
