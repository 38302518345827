import React from "react";
import { match as Match } from "react-router-dom";
import ReportingCard from "../reporting-card";
import { injectIntl, IntlShape } from "react-intl";
import images from "../../../../images";
import history from "../../../../history";
import { Display } from "../../../../components/OperatorCustomization";
import { CardInterface } from "..";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  gaUrl: string;
  operatorAdmin: boolean;
}

const GAReportings = (props: Props) => {
  const { intl, gaUrl, operatorAdmin } = props;

  const categories: CardInterface[] = [
    {
      category: "ga",
      key: "ga",
      label: intl.formatMessage({ id: "page.dashboards.card.ga" }),
      icon: images.ga,
      url: gaUrl,
      target: "_blank",
      description: intl.formatMessage({
        id: "page.dashboards.card.ga.description",
      }),
      display: operatorAdmin,
      realtime: false,
    },
  ];

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
      {categories.map(e => {
        return (
          e.display && (
            <div id={e.key} data-cy={e.key} data-testid={e.key} key={e.key}>
              <Display paths={[`feature.dashboards.${e.key}`]}>
                <ReportingCard
                  label={e.label}
                  icon={e.icon}
                  content={e.description}
                  realtime={e.realtime}
                  selected={false}
                  onClick={() => {
                    if (e.target && e.target === "_blank" && e.url) {
                      window.open(e.url);
                    } else {
                      history.push(e.url);
                    }
                  }}
                />
              </Display>
            </div>
          )
        );
      })}
    </div>
  );
};

export default injectIntl(GAReportings);
