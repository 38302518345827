import axiosBackend from "../../../../../axiosBackend";
import {
  UpdateOrderItemStatusOpts,
  UpdateOrderStatusOpts,
} from "../../../models/Api/Order";

export const updateOrderStatus = async ({
  globalOrderId,
  orderId,
  status,
}: UpdateOrderStatusOpts): Promise<any> => {
  return axiosBackend
    .put(`/global_orders/${globalOrderId}/orders/${orderId}`, {
      status,
    })
    .catch(error => {
      return error.response;
    });
};

export const updateOrderItemStatus = async ({
  globalOrderId,
  orderId,
  orderItemId,
  status,
}: UpdateOrderItemStatusOpts): Promise<any> => {
  return axiosBackend
    .put(
      `/global_orders/${globalOrderId}/orders/${orderId}/order_items/${orderItemId}`,
      {
        status,
      }
    )
    .catch(error => {
      return error.response;
    });
};
