import { $enum } from "ts-enum-util";
import { IntlShape, useIntl } from "react-intl";
import { getTranslation } from "../translation";
import { getRoleLabel } from "../user";
import {
  EnumAttributeTypology,
  EnumBackOfficeUserRole,
  EnumLocale,
  EnumNotificationType,
  EnumProductType,
  EnumProductTypology,
  EnumServiceType,
  EnumUserRole,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import { Category } from "../../lib/ground-aws-graphql-core/models/Category";
import { EventType } from "../../lib/ground-aws-graphql-core/models/EventType";
import { Attribute } from "../../lib/ground-aws-graphql-core/models/Attribute";
import { Service } from "../../lib/ground-aws-graphql-core/models/Service";
import { Product } from "../../lib/ground-aws-graphql-core/models/Product";
import { AttributeKey } from "../../lib/ground-aws-graphql-core/models/AttributeKey";
import { CenterType } from "../../lib/ground-aws-graphql-core/models/CenterType";
import { Provider } from "../../lib/ground-aws-graphql-core/models/Provider";
import { UserCognito } from "../../lib/ground-aws-cognito-auth-core/models/Authentication";
import { SelectOption } from "../../components/Form";
import { PaymentMethod } from "../../lib/ground-aws-graphql-core/models/Api/PaymentMethods";
import { EnumWebhookEventType } from "../../lib/ground-aws-graphql-core/models/Api/RefundRequests";

export interface IRange {
  start: string;
  end: string;
}

export enum ActionTypes {
  TO_ADD = "to_add",
  TO_KEEP = "to_keep",
  TO_UPDATE = "to_update",
  TO_DELETE = "to_delete",
}

export interface Image {
  source: boolean;
  picture: string | ArrayBuffer;
  action: ActionTypes;
  file?: File;
}

export interface Value {
  item?: any;
  id: string;
  name: string;
  label: string;
  type:
    | "text"
    | "email"
    | "select"
    | "react-select"
    | "textarea"
    | "number"
    | "password"
    | "switch"
    | "range"
    | "phone"
    | "checkbox";
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
  value?: string | number | boolean | IRange;
  items?: Item[];
  values?: Item[];
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode[];
  step?: number;
  min?: number;
  max?: number;
  useIntl: boolean;
  index?: number;
  translatable?: boolean;
  description?: string;
  rows?: React.ReactNode[];
  disabledDates?: Date[];
  excludeDates?: Date[];
  minDate?: Date;
}

export interface Item {
  id:
    | string
    | EnumServiceType
    | EnumProductType
    | EnumProductTypology
    | null
    | undefined;
  name:
    | string
    | EnumServiceType
    | EnumProductType
    | EnumProductTypology
    | null
    | undefined;
  default?: boolean;
}

export const getCenterTypeItems = (
  intl: IntlShape,
  items: CenterType[] | null
): Item[] | undefined => {
  const results = items?.reduce((acc: Item[], el: CenterType) => {
    let name = `${getTranslation(el.type)}`;
    if (!el.enabled) {
      name = `${getTranslation(el.type)} (${intl.formatMessage({
        id: "general.inactif",
      })})`;
    }
    acc.push({
      id: el.id,
      name,
    });

    return acc;
  }, []);

  return results;
};

export const getProviderItemsForm = (
  intl: IntlShape,
  items: Provider[]
): SelectOption[] | undefined => {
  const results = items?.reduce((acc: SelectOption[], el: Provider) => {
    let label = `${getTranslation(el.name)}`;
    if (!el.enabled) {
      label = `${getTranslation(el.name)} (${intl.formatMessage({
        id: "general.inactif",
      })})`;
    }

    acc.push({
      value: el.id,
      label,
    });

    return acc;
  }, []);

  return results;
};

export const getProviderItemsByIds = (
  ids: string[],
  providers: [Provider] | null
): Item[] | undefined => {
  const results = ids?.reduce((acc: Item[], el) => {
    const provider = providers?.find(e => e.id === el);
    if (provider) {
      acc.push({ id: provider.id, name: getTranslation(provider.name) });
    }

    return acc;
  }, []);

  return results;
};

export const getCategoryOptions = (
  categories: [Category] | null
): SelectOption[] | undefined => {
  const intl = useIntl();

  return categories?.map(cat => ({
    value: cat.id,
    label: `${getTranslation(cat.name)} ${
      !cat.enabled ? `(${intl.formatMessage({ id: "general.inactif" })})` : ""
    }`,
  }));
};

export const getEventTypeItems = (
  eventTypes: [EventType] | null
): SelectOption[] | undefined => {
  const intl = useIntl();

  return eventTypes?.map(et => ({
    value: et.id,
    label: `${getTranslation(et.type)} ${
      !et.enabled ? `(${intl.formatMessage({ id: "general.inactif" })})` : ""
    }`,
  }));
};

export const getServiceTypologyItems = (): Item[] | undefined => {
  const results = $enum(EnumServiceType)
    .getValues()
    ?.reduce((acc: Item[], el) => {
      acc.push({ id: el, name: el });

      return acc;
    }, []);

  return results;
};

export const getProductTypeItems = (intl: IntlShape): Item[] | undefined => {
  const results = $enum(EnumProductType)
    .getValues()
    ?.reduce((acc: Item[], el) => {
      acc.push({
        id: el,
        name: intl.formatMessage({ id: `page.product.type.${el}` }),
      });

      return acc;
    }, []);

  return results;
};

export const getProductTypologyItemsForm = (
  serviceType: EnumServiceType,
  intl: IntlShape
): SelectOption[] | undefined =>
  getProductTypologyByService(serviceType)?.map(el => ({
    value: el,
    label: intl.formatMessage({ id: `page.product.typology.${el}` }),
  }));

export const getBookableTypologies = (): EnumProductTypology[] => {
  return [
    EnumProductTypology.OFFICE,
    EnumProductTypology.COWORKING,
    EnumProductTypology.MEETING_ROOM,
    EnumProductTypology.SERVICED_SPACE,
    EnumProductTypology.PARKING,
  ];
};

export const getSpaceTypologies = (): EnumProductTypology[] => {
  return [
    EnumProductTypology.OFFICE,
    EnumProductTypology.COWORKING,
    EnumProductTypology.MEETING_ROOM,
  ];
};

export const isOfficeOrMeetingRoom = (
  typology: EnumProductTypology | null | undefined
): boolean => {
  const value = typology
    ? [EnumProductTypology.MEETING_ROOM, EnumProductTypology.OFFICE].includes(
        typology
      )
    : false;

  return value;
};

export const getSpaceTypologiesFilter = (): {
  typology: {
    eq: string;
  };
}[] => {
  const values = getSpaceTypologies().map(typo => ({
    typology: {
      eq: typo,
    },
  }));

  return values;
};

export const getServiceTypologies = (): EnumProductTypology[] => {
  return [
    EnumProductTypology.CONSOMMABLE,
    EnumProductTypology.SERVICED_SPACE,
    EnumProductTypology.PARKING,
    EnumProductTypology.PRESTATION,
  ];
};

export const getServiceTypologiesFilter = (): {
  typology: {
    eq: string;
  };
}[] => {
  const values = getServiceTypologies().map(typo => ({
    typology: {
      eq: typo,
    },
  }));

  return values;
};

export const getAsOptionTypologies = (): EnumProductTypology[] => {
  return [
    EnumProductTypology.CONSOMMABLE,
    EnumProductTypology.SERVICED_SPACE,
    EnumProductTypology.PRESTATION,
    EnumProductTypology.PARKING,
  ];
};

export const getInCatalogTypologies = (): EnumProductTypology[] => {
  return [
    EnumProductTypology.CONSOMMABLE,
    EnumProductTypology.SERVICED_SPACE,
    EnumProductTypology.PRESTATION,
    EnumProductTypology.PARKING,
  ];
};

export const getAsOptionTypologiesFilter = (): {
  typology: {
    eq: string;
  };
}[] => {
  const values = getAsOptionTypologies().map(typo => ({
    typology: {
      eq: typo,
    },
  }));

  return values;
};

export const isPrestation = (
  typology: EnumProductTypology | null | undefined
): boolean => {
  const value = typology ? EnumProductTypology.PRESTATION === typology : false;

  return value;
};

export const isConsumable = (
  typology: EnumProductTypology | null | undefined
): boolean => {
  const value = typology ? EnumProductTypology.CONSOMMABLE === typology : false;

  return value;
};

export const isBookableService = (
  typology: EnumProductTypology | null | undefined
): boolean => {
  const value = typology
    ? [
        EnumProductTypology.SERVICED_SPACE,
        EnumProductTypology.PARKING,
      ].includes(typology)
    : false;

  return value;
};

export const isConsumableService = (
  typology: EnumProductTypology | null | undefined
): boolean => {
  const value = typology
    ? [
        EnumProductTypology.CONSOMMABLE,
        EnumProductTypology.PRESTATION,
      ].includes(typology)
    : false;

  return value;
};

export const getProductTypologyByService = (
  serviceType: EnumServiceType
): EnumProductTypology[] => {
  const results = $enum(EnumProductTypology).getValues();

  if (serviceType === EnumServiceType.SERVICE) {
    return results.filter(e => getServiceTypologies().includes(e));
  }
  if (serviceType === EnumServiceType.SPACE) {
    const r = results.filter(e => getSpaceTypologies().includes(e));

    return r;
  }

  return results;
};

export const getNotificationTypeItemsForm = (
  intl: IntlShape
): SelectOption[] | undefined =>
  getNotificationTypes()?.map(el => ({
    value: el,
    label: intl.formatMessage({ id: `page.list.notifications.type.${el}` }),
  }));

export const getNotificationTypes = (): EnumNotificationType[] => {
  const results = $enum(EnumNotificationType).getValues();

  return results.filter(r => ![EnumNotificationType.ORDER].includes(r));
};

export const getPaymentMethodsOptions = (
  paymentMethodsOptions: PaymentMethod[]
): SelectOption[] =>
  paymentMethodsOptions.map(paymentMethod => ({
    value: paymentMethod.code,
    label: getTranslation(paymentMethod.label),
  }));

export const getProductsForServices = (
  items: [Service] | null
): Product[] | undefined => {
  const results = items?.reduce((acc: Product[], el) => {
    const products = el.products?.items;

    return products && products.length > 0 ? acc.concat(products) : acc;
  }, []);

  return results;
};

export const getUserRoleItems = (): SelectOption[] => {
  const intl = useIntl();

  const results = $enum(EnumUserRole)
    .getValues()
    ?.reduce((acc: SelectOption[], el) => {
      if (el === EnumUserRole.Manager || el !== EnumUserRole.Anonymous) {
        acc.push({ value: el, label: getRoleLabel(intl, el) });
      }

      return acc;
    }, []);

  return results;
};

export const getAdminUserRoleItems = (
  me: UserCognito | null
): SelectOption[] => {
  const intl = useIntl();

  const results = $enum(EnumBackOfficeUserRole)
    .getValues()
    ?.reduce((acc: SelectOption[], el) => {
      const roles = getMyRoles(me);
      if (roles.includes(el)) {
        acc.push({ value: el, label: getRoleLabel(intl, el) });
      }

      return acc;
    }, []);

  return results;
};

export const getAdminUserLocaleItems = (): SelectOption[] => {
  const intl = useIntl();

  const results = $enum(EnumLocale)
    .getValues()
    ?.reduce((acc: SelectOption[], el) => {
      if (el === EnumLocale.fr_FR || el === EnumLocale.en_GB) {
        const item = { value: el, label: getLocaleLabel(intl, el) };
        acc.push(item);
      }

      return acc;
    }, []);

  return results;
};

export const getLocaleLabel = (
  intl: IntlShape,
  locale: string | null | undefined
): string => {
  const label = locale
    ? intl.formatMessage({ id: `general.locale.${locale}` })
    : "";

  return label;
};

const getMyRoles = (me: UserCognito | null): string[] => {
  if (me && me.role) {
    const { role } = me;
    const roles: string[] = [];
    switch (role) {
      case EnumBackOfficeUserRole.OperatorAdmin:
        roles.push(EnumBackOfficeUserRole.OperatorAdmin);
        roles.push(EnumBackOfficeUserRole.CenterAdmin);
        break;
      case EnumBackOfficeUserRole.CenterAdmin:
        roles.push(EnumBackOfficeUserRole.CenterAdmin);
        break;
      default:
        break;
    }

    return roles;
  }

  return [];
};

export const isOperatorAdmin = (me: UserCognito | null): boolean => {
  return me?.role === EnumBackOfficeUserRole.OperatorAdmin;
};

export const isBuildingAdmin = (me: UserCognito | null): boolean => {
  return me?.role === EnumBackOfficeUserRole.CenterAdmin;
};

export const isOperatorAdminOrCenterAdmin = (
  me: UserCognito | null
): boolean => {
  if (me && me.role) {
    const value =
      me.role === EnumBackOfficeUserRole.OperatorAdmin ||
      me.role === EnumBackOfficeUserRole.CenterAdmin;

    return value;
  }

  return false;
};

export const getCenterItems = (items: Center[] | null): Item[] | undefined => {
  const results = items?.reduce((acc: Item[], el: Center) => {
    acc.push({
      id: el.id,
      name: `${getTranslation(el.name)}`,
    });

    return acc;
  }, []);

  return results;
};

export const getFieldAttributeByAttributeKey = (
  attributeKey: AttributeKey
): string | undefined => {
  let field;
  if (attributeKey.typology) {
    const { typology } = attributeKey;
    switch (typology) {
      case EnumAttributeTypology.BOOLEAN:
        field = "booleanValue";
        break;
      case EnumAttributeTypology.STRING:
        field = "stringValue";
        break;
      case EnumAttributeTypology.NUMBER:
        field = "numberValue";
        break;
      case EnumAttributeTypology.JSON:
        field = "jsonValue";
        break;
      case EnumAttributeTypology.URL:
        field = "urlValue";
        break;
      case EnumAttributeTypology.PERCENT:
        field = "numberValue";
        break;
      case EnumAttributeTypology.DATETIME:
        field = "dateValue";
        break;
      case EnumAttributeTypology.SELECT:
        field = "selectValue";
        break;
      default:
        break;
    }
  }

  return field;
};

export const getInputTypeByTypology = (
  attributeKey: AttributeKey | null | undefined
): "text" | "number" | "select" => {
  if (
    attributeKey &&
    (attributeKey.typology === EnumAttributeTypology.NUMBER ||
      attributeKey.typology === EnumAttributeTypology.PERCENT)
  ) {
    return "number";
  }
  if (attributeKey && attributeKey.typology === EnumAttributeTypology.SELECT) {
    return "select";
  }

  return "text";
};

export const getAttributeKeyItems = (attributeKey: AttributeKey): Item[] => {
  let items: Item[] = [];
  if (
    attributeKey &&
    attributeKey.metadata &&
    attributeKey.typology === EnumAttributeTypology.SELECT
  ) {
    const metadata = JSON.parse(attributeKey.metadata);
    const { content } = metadata;
    if (content) {
      const { translatable, values } = content;
      const isArray = Array.isArray(values);
      if (isArray) {
        const results = values?.reduce(
          (
            acc: { id: string; name: string; default: boolean }[],
            el: { id: string; value: string; default: boolean }
          ) => {
            let val = el.value;
            if (translatable) {
              val = getTranslation(JSON.stringify(el.value));
            }
            acc.push({
              id: el.id,
              name: val,
              default: el.default ? el.default : false,
            });

            return acc;
          },
          []
        );
        items = results;
      }
    }
  }

  return items;
};

export const getAttributeValueByAttributeKey = (
  attributeKey: AttributeKey,
  attribute: Attribute | null | undefined
): { value: boolean | string | number | undefined; attributeValue: string } => {
  let value;
  if (attributeKey.typology) {
    const { typology } = attributeKey;
    switch (typology) {
      case EnumAttributeTypology.BOOLEAN:
        value = attribute?.booleanValue;
        break;
      case EnumAttributeTypology.STRING:
        value = attribute?.stringValue;
        break;
      case EnumAttributeTypology.NUMBER:
        value = attribute?.numberValue;
        break;
      case EnumAttributeTypology.JSON:
        value = attribute?.jsonValue;
        break;
      case EnumAttributeTypology.URL:
        value = attribute?.urlValue;
        break;
      case EnumAttributeTypology.PERCENT:
        value = attribute?.numberValue;
        break;
      case EnumAttributeTypology.DATETIME:
        value = attribute?.dateValue;
        break;
      case EnumAttributeTypology.SELECT:
        value = attribute?.selectValue;
        break;
      default:
        break;
    }
  }

  let attributeValue = value ? value.toString() : "";
  if (value) {
    if (attributeKey.typology === EnumAttributeTypology.SELECT) {
      try {
        const selectValue = JSON.parse(value.toString());
        attributeValue = selectValue.id;
      } catch (error) {
        // console.error({ 'Attribute - Error parsing select value :': value, ', key:': attributeKey })
        attributeValue = value.toString();
      }
    } else {
      attributeValue = value.toString();
    }
  }

  return { value, attributeValue };
};

export enum EnumAction {
  CREATE = "create",
  UPDATE = "update",
  DUPLICATE = "duplicate",
}

export const getWebhookEndpointEvents = (): SelectOption[] => {
  const intl = useIntl();

  const results = $enum(EnumWebhookEventType)
    .getValues()
    ?.reduce((acc: SelectOption[], el) => {
      const item = {
        value: el,
        label: intl.formatMessage({
          id: el,
        }),
      };
      acc.push(item);

      return acc;
    }, []);

  return results;
};
