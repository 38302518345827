import { uploadPicturesPromise } from "../Image";
import { EventOpts } from "../../models/Event";
import {
  CreateEventInput,
  EnumPermissionEntity,
  UpdateEventInput,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";

export const createEvent = async (payload: EventOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.EVENT
  );

  const { urls } = response;

  const params: CreateEventInput = {
    title: payload.values.title,
    description: payload.values.description,
    start: payload.start,
    end: payload.end,
    publicationDate: payload.publicationDate,
    unpublicationDate: payload.unpublicationDate,
    picture: urls && urls.length > 0 ? urls[0] : null,
    enabled: payload.enabled,
    centerEventsId: payload.center.id,
    eventTypeId: payload.values.eventTypeId,
  };

  return await payload.callbacks.createEvent(params);
};

export const updateEvent = async (payload: EventOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.EVENT
  );

  const { urls } = response;

  const params: UpdateEventInput = {
    id: payload.event!.id,
    title: payload.values.title,
    description: payload.values.description,
    start: payload.start,
    end: payload.end,
    publicationDate: payload.publicationDate,
    unpublicationDate: payload.unpublicationDate,
    picture: urls && urls.length > 0 ? urls[0] : null,
    enabled: payload.enabled,
    eventTypeId: payload.values.eventTypeId,
  };

  return await payload.callbacks.updateEvent(params);
};
