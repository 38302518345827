import React, { useState } from "react";
import { ActionTypes } from "../../../../utils/types";
import IntlMessages from "../../../../utils/messages";
import {
  getCypressTestId,
  getMaxAdministratorsPerCenter,
} from "../../../../utils/config";
import ModalAddAdmin from "../../../../routes/center/admins/admin-modal";
import { Authorization } from "../../../../lib/ground-aws-graphql-core/models/Authorization";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { BackOfficeUser } from "../../../../lib/ground-aws-graphql-core/models/BackOfficeUser";
import Add from "../../Button/Add";

interface Props {
  item: { id: string };
  title: string;
  description?: string;
  center: Center;
  onAddAuthorization: (u: BackOfficeUser) => void;
  onRemoveAuthorization: (a: Authorization) => void;
  items: { authorization: Authorization; action: ActionTypes }[];
}

const HeaderAdmin = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex justify-between sm:items-center">
        <div>
          <h3 className="text-16px text-ground-black-100">
            <IntlMessages id={props.title} />{" "}
            <span className="text-ground-gray-100">
              <IntlMessages
                id="general.max"
                values={{ total: getMaxAdministratorsPerCenter() }}
              />
            </span>
          </h3>
          {props.description && (
            <p className="mt-1 text-13px text-ground-gray-100">
              <IntlMessages id={props.description} />
            </p>
          )}
        </div>
        <Add
          label="general.add"
          dataCy="link-add-administrator"
          dataTestid={getCypressTestId(props.item)}
          onClick={handleOpenModal}
        />
      </div>
      {open && (
        <ModalAddAdmin
          {...props}
          isOpen={open}
          onRequestClose={handleOpenModal}
        />
      )}
    </>
  );
};

export default HeaderAdmin;
