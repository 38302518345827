import React from "react";
import classnames from "classnames";
import { getCypressTestId } from "../../../utils/config";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../utils/messages";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  type: string;
  value: number;
  label: string;
  placeholder?: string;
  index: number;
  last?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors: any;
  touched: any;
  grid?: number;
  step?: number;
  min?: number;
  max?: number;
  className?: string;
  action?: React.ReactNode;
  useIntl: boolean;
  description?: string;
  alternate?: boolean;
}

const NumberInput = (props: Props) => {
  props = {
    ...props,
    alternate: props.alternate != undefined ? props.alternate : true,
  };

  const className = props.grid
    ? `sm:grid-cols-${props.grid}`
    : "sm:grid-cols-3";

  const containerClassName = id =>
    classnames(
      "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " + className,
      props.className,
      {
        "bg-ground-white-200": props.alternate && id % 2 === 0,
        "rounded-b-10": props.last,
      }
    );

  const invalid = props.errors[props.name] && props.touched[props.name];

  const formClassName = classnames(
    "form-input block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
    }
  );

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": props.grid == 4,
    }
  );

  const inputClassName = classnames("mt-1 sm:mt-0 max-w-xs", {
    "sm:col-span-2": !props.action,
  });

  return (
    <div className={containerClassName(props.index)}>
      <label htmlFor={props.name} className={labelClassName}>
        {props.useIntl && <IntlMessages id={props.label} />}
        {!props.useIntl && `${props.label}`} {props.required && "*"}
      </label>
      <div className={inputClassName}>
        <div className="relative rounded-md shadow-sm">
          <input
            key={`${props.name}_${props.index}`}
            className={formClassName}
            id={props.id}
            data-cy={props.name}
            data-testid={getCypressTestId(props.item)}
            name={props.name}
            type={props.type}
            min={props.min ? props.min : 0}
            max={props.max}
            step={props.step ? props.step : 1}
            placeholder={
              props.useIntl
                ? props.intl.formatMessage({ id: `${props.placeholder}` })
                : props.placeholder
            }
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
          />
          {invalid && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${props.name}-error`}>
            {props.errors[props.name]}
          </p>
        )}
      </div>
      {props.action && <div className="my-auto">{props.action}</div>}
    </div>
  );
};

export default injectIntl(NumberInput);
