import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "../../../services/Api/PaymentMethods";

export interface IMultiLanguage {
  "fr-FR": string;
  "en-GB": string;
  "es-ES": string;
  "de-DE": string;
  "pt-PT": string;
}

export enum EnumWebhookEventStatus {
  new = "new",
  done = "done",
  fail = "fail",
}

export enum EnumWebhookEventType {
  // refund order
  REFUND_ORDER_REQUEST_CREATED = "refund.order.request.created",
  // refund order item
  REFUND_ORDER_ITEM_REQUEST_CREATED = "refund.order.item.request.created",

  // building
  BUILDING_CREATED = "building.created",
  BUILDING_UPDATED = "building.updated",
  BUILDING_DELETED = "building.deleted",
  BUILDING_ENABLED = "building.enabled",
  BUILDING_DISABLED = "building.disabled",

  // product
  PRODUCT_CREATED = "product.created",
  PRODUCT_UPDATED = "product.updated",
  PRODUCT_DELETED = "product.deleted",
  PRODUCT_ENABLED = "product.enabled",
  PRODUCT_DISABLED = "product.disabled",
}

export interface RefundRequestEventAttempt {
  id: number;
  next_attempt_date: string;
  status: EnumWebhookEventStatus;
  created_at: string;
  updated_at: string;
}

export interface RefundRequestEvent {
  id: number;
  total_attemps: number;
  created_at: string;
  updated_at: string;
  attempts: RefundRequestEventAttempt[];
}

export interface RefundRequestOrderItem {
  id: number;
  name: IMultiLanguage;
}

export interface RefundRequest {
  id: number;
  amount: number;
  total_price: number;
  total_tax: number;
  reason: string;
  created_at: string;
  updated_at: string;
  ref: string;
  validated: boolean;
  events: RefundRequestEvent[];
  order_items: RefundRequestOrderItem[];
}

export interface ListRefundRequestsOpts {
  globalOrderId: string;
}

export interface RefundRequestsModel {
  refundRequests: {
    items: RefundRequest[];
    total: number;
    has_more: boolean;
  };
  setRefundRequests: Action<
    RefundRequestsModel,
    { items: RefundRequest[]; total: number; has_more: boolean }
  >;
  listRefundRequests: Thunk<
    RefundRequestsModel,
    ListRefundRequestsOpts,
    Injections,
    StoreModel
  >;
}

export const refundRequestsModel: RefundRequestsModel = {
  refundRequests: { items: [], total: 0, has_more: false },
  setRefundRequests: action((state, payload) => {
    state.refundRequests = payload;
  }),
  listRefundRequests: thunk(
    async (actions, payload: ListRefundRequestsOpts, { injections }) => {
      const { refundRequestsService } = injections;
      const response = await refundRequestsService.listRefundRequests(
        payload.globalOrderId
      );
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<RefundRequest> = response.data;
        actions.setRefundRequests(res.data);
      }
    }
  ),
};
