import { Action, Thunk, action, thunk } from "easy-peasy";
import _ from "lodash";
import { ErrorGraphQL } from "./../../services/coreUtils";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import { Category } from "./../Category/index";
import { Delete, ModelProductConnection } from "./../Product/index";
import { Operator } from "../Operator";

import {
  EnumServiceType,
  CreateServiceInput as CreateService,
  UpdateServiceInput as UpdateService,
  SearchableServiceFilterInput,
  SearchableServiceSortableFields,
} from "../../api/graphql/types";

export interface Service {
  id: string;
  type?: EnumServiceType;
  name?: any;
  description?: any | null;
  pictures?: Array<string | null> | null;
  authorized?: boolean | null;
  enabled?: boolean;
  markForDelete?: boolean;
  operator?: Operator;
  category?: Category;
  products?: ModelProductConnection | null;
}

export interface SearchServicesOpts {
  filter: SearchableServiceFilterInput;
  sort?: SearchableServiceSortableFields;
  from?: number | null;
  limit?: number | null;
  gql?: string | null;
}

export interface ModelServiceConnection {
  items: Service[];
  total: number;
}

// Interface declaration
export interface ServiceModel {
  services: ModelServiceConnection;
  spaces: ModelServiceConnection;

  service: Service | null;
  setServices: Action<ServiceModel, { data: ModelServiceConnection }>;
  setSpaces: Action<ServiceModel, { data: ModelServiceConnection }>;

  setService: Action<ServiceModel, Service | null>;
  addService: Action<ServiceModel, Service>;
  replaceService: Action<ServiceModel, Service>;
  removeService: Action<ServiceModel, Delete>;

  getService: Thunk<ServiceModel, GqlQuery, Injections>;
  createService: Thunk<ServiceModel, CreateService, Injections>;
  updateService: Thunk<ServiceModel, UpdateService, Injections>;
  deleteService: Thunk<ServiceModel, UpdateService, Injections>;
  searchAllServices: Thunk<
    ServiceModel,
    SearchServicesOpts,
    Injections,
    StoreModel
  >;
}

export const serviceModel: ServiceModel = {
  services: { items: [], total: 0 },
  service: null,
  spaces: { items: [], total: 0 },

  setServices: action((state, payload: { data: ModelServiceConnection }) => {
    const { data } = payload;
    state.services = data;
  }),

  setService: action((state, payload) => {
    state.service = payload;
  }),

  setSpaces: action((state, payload: { data: ModelServiceConnection }) => {
    const { data } = payload;
    state.spaces = data;
  }),

  addService: action((state, payload) => {
    if (state.services.items) {
      state.services.items.push(payload);
    } else {
      state.services["items"] = [payload];
    }
  }),

  replaceService: action((state, payload) => {
    state.service = payload;
    if (state.services.items) {
      const index = state.services.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.services.items!.splice(index, 1, payload);
    }
  }),

  removeService: action((state, payload) => {
    state.services.items = state.services.items.filter(
      p => p.id !== payload.id
    );
    state.services.total = state.services.total - 1;
  }),

  getService: thunk(async (actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.getService(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setService(response.data.getService as Service);

    return response.data.getService;
  }),

  createService: thunk(async (actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.createService(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createService;
    actions.setService(item);
    actions.addService(item);

    return item;
  }),

  updateService: thunk(async (actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.updateService(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateService;
    actions.setService(item);
    actions.replaceService(item);

    return item;
  }),

  deleteService: thunk(async (actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.deleteService(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateService;
    actions.setService(null);
    actions.removeService(item);

    return item;
  }),

  searchAllServices: thunk(async (actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.searchAllServices(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data: ModelServiceConnection = response.data.searchAllServices;

    if (
      payload &&
      payload.filter &&
      payload.filter.type &&
      payload.filter.type.eq === EnumServiceType.SPACE
    ) {
      actions.setSpaces({ data });
    } else {
      actions.setServices({ data });
    }

    return data;
  }),
};
