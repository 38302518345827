import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import _ from "lodash";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Operator } from "../Operator";
import {
  EnumCategoryType,
  EnumLocale,
  ModelCategoryFilterInput,
  SearchableCategorySortInput,
} from "./../../api/graphql/types";

import {
  CreateCategoryInput as CreateCategory,
  UpdateCategoryInput as UpdateCategory,
  SearchableCategoryFilterInput,
} from "../../api/graphql/types";
import { Center } from "../Center";
import { Service } from "../Service";

export interface Category {
  operator?: Operator;
  id: string;
  name?: any;
  type?: EnumCategoryType;
  description?: any | null;
  picture?: string | null;
  level?: number;
  position?: number;
  enabled?: boolean;
  markForDelete?: boolean;
  center?: Center;
  parent?: Category;
  services?: {
    items: Service[];
  };
}

export interface ListCategoriesOpts {
  limit?: number;
  filter?: ModelCategoryFilterInput;
  gql?: string | null;
}

export interface SearchCategoriesOpts {
  filter: SearchableCategoryFilterInput;
  sort?: SearchableCategorySortInput;
  locale?: EnumLocale;
  limit?: number | null;
  from?: number | null;
  gql?: string | null;
}

export interface ModelCategoryConnection {
  items: Category[];
  total: number;
}

export interface DuplicateCategoryOpts {
  buildingId: string;
  categoryId: string;
  targetBuildingId?: string;
}

// Interface declaration
export interface CategoryModel {
  categories: ModelCategoryConnection;
  attributeCategories: ModelCategoryConnection;
  category: Category | null;

  setCategories: Action<CategoryModel, { data: ModelCategoryConnection }>;
  setAttributeCategories: Action<
    CategoryModel,
    { data: ModelCategoryConnection }
  >;
  setCategory: Action<CategoryModel, Category | null>;

  addCategory: Action<CategoryModel, Category>;
  replaceCategory: Action<CategoryModel, Category>;
  removeCategory: Action<CategoryModel, Category>;

  listAttributeCategories: Thunk<
    CategoryModel,
    ListCategoriesOpts,
    Injections,
    StoreModel
  >;

  createCategory: Thunk<CategoryModel, CreateCategory, Injections>;
  updateCategory: Thunk<CategoryModel, UpdateCategory, Injections>;
  duplicateCategory: Thunk<
    CategoryModel,
    DuplicateCategoryOpts,
    Injections,
    StoreModel
  >;
  deleteCategory: Thunk<CategoryModel, UpdateCategory, Injections>;
  searchAllCategories: Thunk<
    CategoryModel,
    SearchCategoriesOpts,
    Injections,
    StoreModel
  >;
}

export const categoryModel: CategoryModel = {
  categories: { items: [], total: 0 },
  attributeCategories: { items: [], total: 0 },
  category: null,

  setCategories: action((state, payload: { data: ModelCategoryConnection }) => {
    const { data } = payload;
    state.categories = data;
  }),

  setAttributeCategories: action(
    (state, payload: { data: ModelCategoryConnection }) => {
      const { data } = payload;
      state.attributeCategories = data;
    }
  ),

  setCategory: action((state, payload) => {
    state.category = payload;
  }),

  addCategory: action((state, payload) => {
    const { categories } = state;

    state.category = payload;
    state.categories.items.push(payload);
    state.categories.total = categories.total + 1;
  }),

  replaceCategory: action((state, payload) => {
    state.category = payload;

    const { categories } = state;
    const index = categories.items.findIndex(e => e.id === payload.id);
    if (index >= 0) state.categories.items.splice(index, 1, payload);
  }),

  removeCategory: action((state, payload) => {
    const { categories } = state;
    state.categories = {
      items: categories.items.filter(c => c.id !== payload.id),
      total: categories.total - 1,
    };
  }),

  listAttributeCategories: thunk(async (actions, payload, { injections }) => {
    const { categoryService } = injections;
    const response = await categoryService.listCategories(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.listCategories;
    actions.setAttributeCategories({ data: data });

    return data;
  }),

  createCategory: thunk(async (actions, payload, { injections }) => {
    const { categoryService } = injections;
    const response = await categoryService.createCategory(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.createCategory;
    actions.setCategory(item);
    actions.addCategory(item);

    return item;
  }),

  updateCategory: thunk(async (actions, payload, { injections }) => {
    const { categoryService } = injections;
    const response = await categoryService.updateCategory(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateCategory;

    actions.setCategory(item);
    actions.replaceCategory(item);

    return item;
  }),

  duplicateCategory: thunk(
    async (actions, payload, { getState, injections }) => {
      const { categoryService } = injections;
      const response = await categoryService.duplicateCategory(payload);

      const state = getState();
      const { categories } = state;

      const category = categories.items.find(i => i.id === payload.categoryId);

      const duplicated = {
        ...category,
        ...response.data.data,
      };

      actions.setCategory(duplicated);
      actions.addCategory(duplicated);

      return response;
    }
  ),

  deleteCategory: thunk(async (actions, payload, { injections }) => {
    const { categoryService } = injections;
    const response = await categoryService.deleteCategory(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateCategory;
    actions.setCategory(null);
    actions.removeCategory(item);

    return item;
  }),

  searchAllCategories: thunk(async (actions, payload, { injections }) => {
    const { categoryService } = injections;

    const response = await categoryService.searchAllCategories(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.searchAllCategories;
    actions.setCategories({ data });

    return data;
  }),
};
