import React from "react";
import ReactModal from "react-modal";
import IntlMessages from "../../messages";
import Primary from "../../../components/Tailwind/Button/Primary";
import Secondary from "../../../components/Tailwind/Button/Secondary";
import overrideClasses from "../../overrideClasses";

interface Props {
  isOpen: boolean;
  toggle: (e) => void;
  onRequestClose: (e) => void;
  content?: JSX.Element;
  handleConfirm: () => void;
  item?: { id: string };
  description?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  headerClassName?: string;
}

const ConfirmModal = (props: Props): JSX.Element => {
  const {
    isOpen,
    toggle,
    content,
    description,
    item,
    handleConfirm,
    onRequestClose,
    className,
    titleClassName,
    headerClassName,
  } = props;

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
        shouldCloseOnOverlayClick
        onRequestClose={onRequestClose}
        className={overrideClasses("modal-dialog-lg", className)}
      >
        <div className="modal-content overflow-visible">
          <div
            className={overrideClasses(
              "modal-header border-0 p-7",
              headerClassName
            )}
          >
            {content && (
              <h5 className={overrideClasses("modal-title", titleClassName)}>
                <IntlMessages
                  id={content?.props?.id}
                  values={content?.props?.values}
                />
              </h5>
            )}
          </div>

          {description && (
            <div className="modal-body pt-0 pb-0 px-4">{description}</div>
          )}

          <div className="modal-footer border-0 p-7">
            <Secondary
              id="btn-cancel-confirmation-modal"
              name="btn-cancel-confirmation-modal"
              item={item}
              type="button"
              onClick={() => toggle(isOpen)}
            >
              <IntlMessages id="general.cancel" />
            </Secondary>

            <Primary
              id="btn-validate-confirmation-modal"
              name="btn-validate-confirmation-modal"
              item={item}
              type="button"
              onClick={() => handleConfirm()}
            >
              <IntlMessages id="general.confirm" />
            </Primary>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ConfirmModal;
