import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import {
  EnumProductTypology,
  EnumProductType,
  SearchableProductsFilterInput,
  SearchableStringFilterInput,
  EnumUnit,
  EnumCurrency,
  EnumUnavailabilityType,
} from "./../../api/graphql/types";
import { Category } from "./../Category/index";
import { ModelDaySlotConnection } from "./../DaySlot/index";
import { ModelUnavailabilityConnection } from "./../Unavailability/index";
import { ModelPriceUnitConnection, PriceUnit } from "./../PriceUnit/index";
import { ModelClosedDayConnection } from "./../ClosedDay/index";
import { ModelAttributeConnection } from "./../Attribute/index";
import { Provider } from "./../Provider/index";
import { Operator } from "../Operator";

import {
  CreateProductInput as CreateProduct,
  UpdateProductInput as UpdateProduct,
  SearchableProductFilterInput,
  SearchableProductSortableFields,
} from "../../api/graphql/types";
import { ModelOptionConnection } from "../Option";
import { ModelSingleProductConnection } from "../SingleProduct";
import {
  AttributesItem,
  ClosedDaysItem,
  DaySlotsItem,
  ExceptionalOpeningHoursItem,
} from "../../utils/preprocessing";
import { Center, ModelExceptionalOpeningHoursConnection } from "../Center";
import { Privatization } from "../../../../redux/models/Privatization";
import { Service } from "../Service";
import { IMultiLanguage } from "../Api/RefundRequests";
import { BookingIndicators } from "../Reporting";

export interface Product {
  operator?: Operator;
  id: string;
  productOperatorId?: string;
  type?: EnumProductType;
  sku?: string;
  center?: Center;
  name?: any;
  typology?: EnumProductTypology;
  description?: any | null;
  pictures?: Array<string | null> | null;
  category?: Category;
  daySlots?: ModelDaySlotConnection | null;
  closedDays?: ModelClosedDayConnection | null;
  exceptionalOpeningHours?: ModelExceptionalOpeningHoursConnection | null;
  priceUnits?: ModelPriceUnitConnection | null;
  unavailabilities?: ModelUnavailabilityConnection | null;
  service?: Service | null;
  attributes?: ModelAttributeConnection | null;
  provider: Provider;
  options?: ModelOptionConnection | null;
  products?: ModelSingleProductConnection | null;
  privatizations?: Privatization[];
  vat?: number;
  capacity: number;
  stock?: number;
  checkStock: boolean;
  authorized?: boolean | null;
  enabled?: boolean;
  markForDelete?: boolean;
  flexible: boolean;
  isOption: boolean;
  inCatalog: boolean;
}

export enum EnumBookingStatus {
  AVAILABLE = "AVAILABLE",
  CLOSED = "CLOSED",
  COMPLETE = "COMPLETE",
  BOOKED = "BOOKED",
}
interface BookingUser {
  firstname: string;
  lastname: string;
}

export interface Booking {
  id: string;
  start: string;
  end: string;
  type: EnumUnavailabilityType;
  order: {
    id: string;
    ormOrder: string;
  };
  product: {
    id: string;
    name: IMultiLanguage;
  };
  participants: number;
  user: BookingUser;
}

export enum EnumUnavailabilityReasonCode {
  SPACE_NOT_ENABLED = "SPACE_NOT_ENABLED",
  BUILDING_OR_SPACE_CLOSED = "BUILDING_OR_SPACE_CLOSED",
  SPACE_CAPACITY_REACHED = "SPACE_CAPACITY_REACHED",
  NOT_ENOUGH_SPACE_AVAILABLE = "NOT_ENOUGH_SPACE_AVAILABLE",
  BOOKING_UNIT = "BOOKING_UNIT",
  NOT_ENOUGH_STOCK = "NOT_ENOUGH_STOCK",
}

export interface HoursStatus {
  start: string;
  end: string;
  formatted_start: string;
  formatted_end: string;
  status: EnumBookingStatus;
  indicators: BookingIndicators;
}

export interface OpeningClosingHour {
  start: string;
  end: string;
}

export interface TimelineDate {
  date: string;
  formatted_date: string;
  status: EnumBookingStatus;
  closed: boolean;
  available: boolean;
  not_available_reason?: EnumUnavailabilityReasonCode | null;
  hours_status: HoursStatus[];
  opening_hours: OpeningClosingHour[];
  closing_hours: OpeningClosingHour[];
}

export interface SpaceTimeline {
  id: string;
  name: string;
  enabled: boolean;
  flexible: boolean;
  typology: EnumProductTypology;
  pictures: string[];
  capacity: number;
  combined: boolean;
  timeline_dates: TimelineDate[];
}

interface BookingDayCalendar {
  total: number;
  items: Booking[];
}

export interface DayCalendar {
  date: string;
  formatted_date: string;
  status: EnumBookingStatus;
  privatized: boolean;
  bookings: BookingDayCalendar;
  indicators: BookingIndicators;
}

interface Calendar {
  items: DayCalendar[];
}

export interface SpaceCalendar {
  id: string;
  typology: EnumProductTypology;
  enabled: boolean;
  name: any;
  pictures?: string[];
  capacity: number;
  flexible: boolean;
  combined: boolean;
}

export interface SpaceBookingsCalendar extends SpaceCalendar {
  calendar: Calendar;
}

interface SearchSpacesBookingsOutput {
  items: SpaceBookingsCalendar[];
  total: number;
}

interface SpaceBookingsDate {
  bookable: boolean;
  bookings: SearchSpaceDateBookingsOutput;
  privatizable: boolean;
  privatizations: Privatization[];
}

interface SearchSpaceTimelinesOutput {
  items: SpaceTimeline[];
  total: number;
}

interface SearchSpaceDateBookingsOutput {
  items: Booking[];
  total: number;
}

export interface SearchProductsOpts {
  buildingId: string;
  filter: SearchableProductsFilterInput;
  limit?: number | null;
  from?: number;
}

export interface SearchWorkSpacesOpts {
  filter: SearchableProductFilterInput;
  limit?: number | null;
  gql?: string | null;
  from?: number;
}

export interface SearchSpacesOpts {
  filter: SearchableProductFilterInput;
  sort?: SearchableProductSortableFields;
  limit?: number | null;
  from?: number;
}

export interface ModelProductConnection {
  items: Product[];
  total: number;
}

// Interface declaration
export type Create = CreateProduct & {
  id: string;
  productOperatorId: string;
  closedDays: ClosedDaysItem[];
  daySlots: DaySlotsItem[];
  attributes: AttributesItem[];
  priceUnits: PriceUnit[];
};

export interface CreateProductOpts {
  product: Create;
  gql?: string | null;
}

export type Update = UpdateProduct & {
  id: string;
  productOperatorId: string;
  closedDays?: ClosedDaysItem[];
  daySlots?: DaySlotsItem[];
  exceptionalOpeningHours?: ExceptionalOpeningHoursItem[];
  attributes: AttributesItem[];
  priceUnits?: PriceUnit[];
};

export interface UpdateProductOpts {
  product: Update;
  gql?: string | null;
}

export type Delete = {
  id: string;
};

export interface DeleteProductOpts {
  product: Delete;
  gql?: string | null;
}

export enum EnumCalendarType {
  WEEK = "WEEK",
}

export enum EnumReportingView {
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export enum EnumReportingGlobalEntity {
  CENTER = "CENTER",
  USER = "USER",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export enum EnumReportingGlobalStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  DELETED = "DELETED",
}

export enum EnumReportingType {
  SALES = "SALES",
  CANCELLATION = "CANCELLATION",
  REFUND = "REFUND",
}

export enum EnumReportingBookingType {
  OCCUPANCY = "OCCUPANCY",
  BOOKINGS = "BOOKINGS",
}

export enum EnumSpaceTypology {
  COWORKING = "COWORKING",
  MEETING_ROOM = "MEETING_ROOM",
  OFFICE = "OFFICE",
  PARKING = "PARKING",
  SERVICED_SPACE = "SERVICED_SPACE",
}

export enum EnumSearchSpaceBookings {
  START = "START",
}

export interface SearchSpacesBookingsOpts {
  filter: SearchSpacesBookingsFilterInput;
  limit?: number;
  from?: number;
}

export interface SearchableSpacesBookingsCapacity {
  min: number;
  max?: number;
}
interface SearchSpacesBookingsFilterInput {
  centerId: string;
  start: string;
  type: EnumCalendarType;
  capacity?: SearchableSpacesBookingsCapacity[];
  typology?: [EnumSpaceTypology];
}

interface FilterSearchSpacesTimeline {
  typology?: SearchableStringFilterInput;
  or?: Array<FilterSearchSpacesTimeline>;
}

export interface SearchSpaceDateBookingsOpts {
  productId: string;
  date: string;
  sort?: any;
}

export interface SearchSpaceTimelinesOpts {
  buildingId: string;
  date: string;
  filter?: FilterSearchSpacesTimeline;
  limit?: number;
  from?: number;
}

export interface SearchProductOptionsOpts {
  buildingId: string;
  productId: string;
  priceUnitVariationId?: string;
}

export interface ProductPriceUnitVariation {
  id: string;
  code: string;
}

export interface ProductPriceUnit {
  id: string;
  price: number;
  unit: EnumUnit;
  currency: EnumCurrency;
  variation?: ProductPriceUnitVariation;
}

export interface ServiceOption {
  id: string;
  name?: IMultiLanguage;
}

export interface ProviderOption {
  id: string;
  name?: IMultiLanguage;
}

export interface ProductOption {
  id: string;
  name?: IMultiLanguage;
  typology?: EnumProductTypology;
  description?: IMultiLanguage;
  vat?: number;
  stock?: number;
  capacity?: number;
  picture?: string | null;
  price_units?: {
    items: ProductPriceUnit[];
  };
  service?: ServiceOption;
  provider?: ProviderOption;
}

export interface ProductModel {
  products: ModelProductConnection;
  product: Product | null;
  productOptions: ProductOption[] | null;

  spacesBookings: SpaceBookingsCalendar[] | null;
  spacesBookingsTotal: number;
  spaceDateBookings: SpaceBookingsDate;

  spaceTimelines: SpaceTimeline[] | null;
  spacesTimelinesTotal: number;
  spaceTimeline: SpaceTimeline | null;

  setProducts: Action<ProductModel, ModelProductConnection>;

  setProduct: Action<ProductModel, Product | null>;

  setProductOptions: Action<ProductModel, ProductOption[] | null>;

  setSpacesBookings: Action<ProductModel, { data: SearchSpacesBookingsOutput }>;
  setSpaceDateBookings: Action<ProductModel, { data: SpaceBookingsDate }>;
  setSpaceTimelines: Action<ProductModel, { data: SearchSpaceTimelinesOutput }>;
  setSpaceTimeline: Action<ProductModel, SpaceTimeline | null>;
  addProduct: Action<ProductModel, Product>;
  replaceProduct: Action<ProductModel, Product>;
  removeProduct: Action<ProductModel, Delete>;

  getProduct: Thunk<ProductModel, GqlQuery, Injections>;
  getProductOnly: Thunk<ProductModel, GqlQuery, Injections>;

  getSpaceTimeline: Thunk<
    ProductModel,
    { id: string; date: string },
    Injections
  >;
  createProduct: Thunk<ProductModel, CreateProductOpts, Injections>;
  updateProduct: Thunk<ProductModel, UpdateProductOpts, Injections>;
  deleteProduct: Thunk<ProductModel, DeleteProductOpts, Injections>;
  searchProducts: Thunk<
    ProductModel,
    SearchProductsOpts,
    Injections,
    StoreModel
  >;
  searchWorkSpaces: Thunk<
    ProductModel,
    SearchWorkSpacesOpts,
    Injections,
    StoreModel
  >;
  searchSpaces: Thunk<ProductModel, SearchSpacesOpts, Injections, StoreModel>;
  searchSpacesBookings: Thunk<
    ProductModel,
    SearchSpacesBookingsOpts,
    Injections,
    StoreModel
  >;
  searchSpaceDateBookings: Thunk<
    ProductModel,
    SearchSpaceDateBookingsOpts,
    Injections,
    StoreModel
  >;
  searchSpaceTimelines: Thunk<
    ProductModel,
    SearchSpaceTimelinesOpts,
    Injections,
    StoreModel
  >;
  searchProductOptions: Thunk<
    ProductModel,
    SearchProductOptionsOpts,
    Injections,
    StoreModel
  >;
}

export const productModel: ProductModel = {
  products: { items: [], total: 0 },
  product: null,
  productOptions: [],

  spacesBookings: [],
  spacesBookingsTotal: 0,
  spaceDateBookings: {
    bookings: { items: [], total: 0 },
    bookable: false,
    privatizable: false,
    privatizations: [],
  },

  spaceTimelines: [],
  spacesTimelinesTotal: 0,
  spaceTimeline: null,

  setProducts: action((state, payload) => {
    state.products = payload;
  }),

  setProduct: action((state, payload) => {
    state.product = payload;
  }),

  setProductOptions: action((state, payload) => {
    state.productOptions = payload;
  }),

  setSpaceTimeline: action((state, payload) => {
    state.spaceTimeline = payload;
  }),

  setSpacesBookings: action(
    (state, payload: { data: SearchSpacesBookingsOutput }) => {
      state.spacesBookings = payload.data.items;
      state.spacesBookingsTotal = payload.data.total;
    }
  ),

  setSpaceDateBookings: action(
    (state, payload: { data: SpaceBookingsDate }) => {
      state.spaceDateBookings = payload.data;
    }
  ),

  setSpaceTimelines: action(
    (state, payload: { data: SearchSpaceTimelinesOutput }) => {
      state.spaceTimelines = payload.data.items;
      state.spacesTimelinesTotal = payload.data.total;
    }
  ),

  addProduct: action((state, payload) => {
    if (state.products.items) {
      state.products.items.push(payload);
    } else {
      state.products["items"] = [payload];
    }
  }),

  replaceProduct: action((state, payload) => {
    state.product = payload;
    if (state.products.items) {
      const index = state.products.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.products.items!.splice(index, 1, payload);
    }
  }),

  removeProduct: action((state, payload) => {
    state.products.items = state.products.items.filter(
      p => p.id !== payload.id
    );
    state.products.total = state.products.total - 1;
  }),

  getProduct: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.getProduct(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setProduct(response.data.getProduct);

    return response.data.getProduct;
  }),

  getProductOnly: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.getProductOnly(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setProduct(response.data.getProduct);

    return response.data.getProduct;
  }),

  getSpaceTimeline: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.getSpaceTimeline(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setSpaceTimeline(response.data.getSpaceTimeline as SpaceTimeline);

    return response.data.getSpaceTimeline;
  }),

  createProduct: thunk(async (actions, payload, { injections }) => {
    const { product, gql } = payload;
    const { productOperatorId, ...input } = product;
    const { productService } = injections;
    const response = await productService.createProduct(input, gql);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createProduct;
    actions.setProduct(item);
    actions.addProduct(item);

    return item;
  }),

  updateProduct: thunk(async (actions, payload, { injections }) => {
    const { product, gql } = payload;
    const { productOperatorId, ...input } = product;
    const { productService } = injections;
    const response = await productService.updateProduct(input, gql);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateProduct;
    actions.setProduct(item);
    actions.replaceProduct(item);

    return item;
  }),

  deleteProduct: thunk(async (actions, payload, { injections }) => {
    const { product, gql } = payload;
    const { productService } = injections;
    const response = await productService.deleteProduct(
      {
        id: product.id,
      },
      gql
    );

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    actions.setProduct(null);
    actions.removeProduct(product);
  }),

  searchProducts: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchProducts(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchProducts) {
      const data = response.data.searchProducts;
      actions.setProducts(data);

      return data;
    }

    return null;
  }),

  searchWorkSpaces: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchWorkSpaces(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchSpaces) {
      const data = response.data.searchSpaces;
      actions.setProducts(data);

      return data;
    }

    return null;
  }),

  searchSpaces: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchSpaces(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchSpaces) {
      const data = response.data.searchSpaces;
      actions.setProducts(data);

      return data;
    }

    return null;
  }),

  searchSpacesBookings: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchSpacesBookings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchSpacesBookings) {
      const data = response.data.searchSpacesBookings;
      actions.setSpacesBookings({ data });

      return data;
    }

    return null;
  }),

  searchSpaceDateBookings: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchSpaceDateBookings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchSpaceDateBookings) {
      const data = response.data.searchSpaceDateBookings;
      actions.setSpaceDateBookings({ data });

      return data;
    }

    return null;
  }),

  searchSpaceTimelines: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchSpaceTimelines(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchSpaceTimelines) {
      const data = response.data.searchSpaceTimelines;
      actions.setSpaceTimelines({ data });

      return data;
    }

    return null;
  }),

  searchProductOptions: thunk(async (actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.searchProductOptions(payload);

    if (response?.data?.success) {
      actions.setProductOptions(response?.data.data);

      return response.data.data;
    }

    return null;
  }),
};
