import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { getSupportedLocales } from "../../../lang";
import contextStore from "../../../redux/store";
import { useTranslatableField } from "../../../utils/locale";
import LocaleSwitcher from "../../LocaleSwitcher";
import images from "../../../images";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  type: string;
  value: string | null | undefined;
  placeholder?: string | null | undefined;
  label: string;
  index: number;
  last?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  rows?: React.ReactNode;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors: any;
  touched: any;
  grid?: number;
  className?: string;
  action?: React.ReactNode;
  translatable?: boolean;
  autoComplete?: string;
  onlyInput?: boolean;
  useIntl: boolean;
  description?: string;
  alternate?: boolean;
  width?: string;
}

const TextInput = (props: Props) => {
  props = {
    ...props,
    alternate: props.alternate != undefined ? props.alternate : true,
  };

  const [fieldType, setFieldType] = useState(props.type);

  const className = props.grid
    ? `sm:grid-cols-${props.grid}`
    : "sm:grid-cols-3";

  const locale = contextStore.useStoreState(state => state.settings.locale);

  const { value, displayedValue, currentLocale, setCurrentLocale, onChange } =
    useTranslatableField(
      !!props.translatable,
      props.value,
      props.onChange,
      locale
    );

  const locales = getSupportedLocales();

  const containerClassName = id =>
    props.name === "nocs"
      ? classnames("sm:grid sm:items-start")
      : classnames(
          "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " +
            className,
          props.className,
          {
            "bg-ground-white-200": props.alternate && id % 2 === 0,
            "rounded-b-10": props.last,
          }
        );

  const invalid = props.errors[props.name] && props.touched[props.name];

  const formClassName = classnames(
    "rounded-md shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
      "bg-ground-white-200": props.disabled,
    }
  );

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": props.grid === 4,
    }
  );

  const inputClassName = classnames(
    `mt-1 sm:mt-0 ${props.width ? props.width : ""}`,
    {
      "sm:col-span-2": !props.action,
    }
  );

  const changeShowHidePassword = useCallback(() => {
    setFieldType(prevType => (prevType === "password" ? "text" : "password"));
  }, []);

  const newPlaceHolder =
    props.placeholder &&
    (props.useIntl
      ? props.intl.formatMessage({ id: `${props.placeholder}` })
      : props.placeholder);

  return props.onlyInput ? (
    <>
      <input
        className={formClassName}
        placeholder={newPlaceHolder || ""}
        id={props.id}
        data-cy={props.name}
        data-testid={getCypressTestId(props.item)}
        name={props.name}
        type={props.type}
        value={displayedValue}
        disabled={props.disabled}
        onChange={onChange}
        onBlur={props.onBlur}
        autoComplete={props.autoComplete}
      />
      {props.translatable && (
        <LocaleSwitcher
          currentLocale={currentLocale}
          setCurrentLocale={setCurrentLocale}
          locales={locales}
          value={value}
        />
      )}
    </>
  ) : (
    <div className={containerClassName(props.index)}>
      {props.label && (
        <label htmlFor={props.name} className={labelClassName}>
          {props.useIntl && <IntlMessages id={props.label} />}
          {!props.useIntl && `${props.label}`} {props.required && "*"}
        </label>
      )}
      <div className={inputClassName}>
        <div className="relative flex items-center justify-between">
          <input
            className={formClassName}
            placeholder={newPlaceHolder || ""}
            id={props.id}
            data-cy={props.name}
            data-testid={getCypressTestId(props.item)}
            name={props.name}
            type={fieldType}
            value={displayedValue}
            disabled={props.disabled}
            onChange={onChange}
            onBlur={props.onBlur}
            autoComplete={props.autoComplete}
          />
          {props.type === "password" && (
            <div
              className="absolute cursor-pointer inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={changeShowHidePassword}
            >
              {fieldType === "password" ? (
                <img alt="eye blocked" src={images.eyeBlocked} />
              ) : (
                <img alt="eye" src={images.eye} />
              )}
            </div>
          )}
          {invalid && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          {props.children && <>{props.children[0]}</>}
        </div>
        <div>
          {props.translatable && (
            <LocaleSwitcher
              data-cy="locale-switcher"
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              locales={getSupportedLocales()}
              value={value}
            />
          )}
        </div>
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${props.name}-error`}>
            {props.errors[props.name]}
          </p>
        )}
        {props.rows && (
          <div className="mt-1 sm:mt-0 sm:col-span-1">{props.rows[0]}</div>
        )}
      </div>
      {props.action && <div className="my-auto">{props.action}</div>}
    </div>
  );
};

export default injectIntl(TextInput);
