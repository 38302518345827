import React from "react";
import Button from "../Button";
import LinkButton from "../LinkButton";
import IntlMessages from "../../../utils/messages";

interface Props {
  label: string;
  visible: boolean;
  onSave?: (e) => void;
  onCancel?: (e) => void;
}

const SavePanel = (props: Props) => {
  return (
    <div className="bg-gray-900 opacity-90 shadow sm:rounded-lg fixed transform -translate-x-1/2 left-1/2 bottom-40 z-50">
      {props.visible && (
        <div className="px-4 py-5 sm:p-5">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="max-w-xl leading-5 text-white">
              <p className="text-14px">
                <IntlMessages id={props.label} />
              </p>
            </div>
            <div className="mx-6">
              <Button
                item={null}
                id="btn-save-panel"
                name="btn-save-panel"
                type="button"
                onClick={props.onSave}
              >
                <IntlMessages id="general.save" />
              </Button>
            </div>
            <LinkButton
              id="link-cancel-panel"
              name="link-cancel-panel"
              label="general.cancel"
              onClick={props.onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SavePanel;
