import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import ReactModal from "react-modal";
import CategoryForm from "./form";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";
import { EnumCategoryType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { EnumAction } from "../../../../utils/types";

interface Props {
  type: EnumCategoryType;
  isOpen: boolean;
  toggle: (e, category?: any) => void;
  action: EnumAction | undefined;
  category: Category | null;
  intl: IntlShape;
  parent: boolean;
  categories: Category[] | null;
  centerId: string;
  /** Use existing categories provided in `categories` instead of creating one */
  existing?: boolean;
  onRequestClose?: (e: any) => void;
}

const ModalCreateUpdateCategory = (props: Props) => {
  const { onRequestClose, isOpen } = props;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-lg modal-content"
    >
      <CategoryForm {...props} />
    </ReactModal>
  );
};

export default injectIntl(ModalCreateUpdateCategory);
