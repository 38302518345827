import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import AuthenticationContainer from "../authentication/container";
import images from "../../images";
import Button from "../../components/Tailwind/Button";
import history from "../../history";
import IntlMessages from "../../utils/messages";

interface Props {
  intl: IntlShape;
  location: Location;
}

const Error = (props: Props) => {
  const { location, intl } = props;
  const message = location?.state?.message
    ? location.state.message
    : "page.error.message";

  return (
    <AuthenticationContainer>
      <Helmet>
        <title>{intl.formatMessage({ id: "page.error.document.title" })}</title>
      </Helmet>
      <div className="flex flex-col relative items-center h-screen justify-center">
        <div className="flex justify-center w-full sm:w-3/4 lg:w-5/12 cursor-pointer">
          <img
            alt=""
            src={images.ground}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
        <div className="flex flex-col mb-5">
          <p className="pt-3 m-5 text-30px text-ground-black-100 text-center leading-5 font-semibold">
            <IntlMessages id={message} />
          </p>
        </div>
        <Button
          id="btn-back-to-menu"
          name="btn-back-to-menu"
          item={null}
          type="button"
          onClick={() => {
            history.push("/");
          }}
        >
          <IntlMessages id="page.error.back" />
        </Button>
      </div>
    </AuthenticationContainer>
  );
};

export default injectIntl(Error);
