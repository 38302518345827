/* eslint-disable no-case-declarations */
import { IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import { ALL_FILTER } from "../../components/Table";
import {
  EnumOrderStatus,
  EnumPermissionEntity,
  SearchableEventSortableFields,
  SearchableNewsSortableFields,
  SearchableParticipationSortableFields,
  SearchableSortDirection,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { EnumReportingView } from "../../lib/ground-aws-graphql-core/models/Product";
import { getStatusLabel } from "../../routes/management/global-orders/global-orders-list/order/status";
import { EnumEventStatus } from "../../routes/marketing/event/events-list";
import { EnumNewsStatus } from "../../routes/marketing/news/news-list/table";
import { ProductAttributeKeys } from "../attribute";
import { EnumUserStatus } from "../user";
import { getSpaceTypologies } from "../types";
import {
  getMonthInLocal,
  getNextMonthInLocal,
  getNextWeek,
  getPreviousMonthInLocal,
  getPreviousWeek,
  getWeekInCenterTimezone,
} from "../config";

export enum EnumFilterFields {
  NAME,
  ADDRESS,
  CITY,
  LASTNAME,
  FIRSTNAME,
  EMAIL,
  PROVIDER,
  CLIENT_NAME,
  INCIDENT_TYPE,
  INCIDENT_REASON,
  STATUS,
  ENABLED,
  TITLE,
  PUBLICATION_DATE,
  UNPUBLICATION_DATE,
  START_DATE,
  END_DATE,
  TYPOLOGY,
  CAPACITY,
  ATTRIBUTE,
  START,
  PINNED,
  CREATION_START_DATE,
  CREATION_END_DATE,
}

export enum EnumFilterType {
  SELECT,
  INPUT,
  DATE,
  RANGE_DATE,
  SWITCH,
}

export const capacityValuesList = [
  { min: 0, max: 5 },
  { min: 6, max: 10 },
  { min: 11, max: 20 },
  { min: 21, max: 30 },
  { min: 31, max: 50 },
  { min: 50 },
];

export interface IFilter {
  value: string;
  type: EnumFilterType;
  defaultValue?: string | number | Date;
  customInput?: boolean;
  async?: boolean;
  title?: string;
  options?: {
    value: string | number;
    label: string;
    code?: number;
  }[];
  dependsOn?: {
    field: string;
    operator: string;
  }[];
  disabled?: boolean;
}

export const getFilterFields = (
  intl: IntlShape,
  entity?: EnumPermissionEntity
): {
  values: IFilter[];
  multiple?: {
    [key: string]: boolean;
  };
} => {
  if (!entity) {
    return { values: [] };
  }

  let values: IFilter[] = [];
  const multiple: {
    [key: string]: boolean;
  } = {};
  switch (entity) {
    case EnumPermissionEntity.CENTER:
    case EnumPermissionEntity.PROVIDER:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.NAME],
          type: EnumFilterType.INPUT,
        },
        {
          value: EnumFilterFields[EnumFilterFields.ADDRESS],
          type: EnumFilterType.INPUT,
        },
        {
          value: EnumFilterFields[EnumFilterFields.CITY],
          type: EnumFilterType.INPUT,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = false;
      break;
    case EnumPermissionEntity.USER:
      const userOptions = $enum(EnumUserStatus)
        .getKeys()
        .map(s => ({
          value: s,
          label: intl.formatMessage({ id: `general.${s}` }),
        }));
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.STATUS],
          type: EnumFilterType.SELECT,
          title: "general.status",
          options: [
            {
              value: ALL_FILTER,
              label: getStatusLabel(intl, ALL_FILTER),
            },
            ...userOptions,
          ],
          async: false,
        },
        {
          value: EnumFilterFields[EnumFilterFields.LASTNAME],
          type: EnumFilterType.INPUT,
          title: "general.filter.LASTNAME",
        },
        {
          value: EnumFilterFields[EnumFilterFields.FIRSTNAME],
          type: EnumFilterType.INPUT,
          title: "general.filter.FIRSTNAME",
        },
        {
          value: EnumFilterFields[EnumFilterFields.EMAIL],
          type: EnumFilterType.INPUT,
          title: "general.filter.EMAIL",
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = false;
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = false;
      break;
    case EnumPermissionEntity.BACK_OFFICE_USER:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.LASTNAME],
          type: EnumFilterType.INPUT,
          title: "general.filter.LASTNAME",
        },
        {
          value: EnumFilterFields[EnumFilterFields.FIRSTNAME],
          type: EnumFilterType.INPUT,
          title: "general.filter.FIRSTNAME",
        },
        {
          value: EnumFilterFields[EnumFilterFields.EMAIL],
          type: EnumFilterType.INPUT,
          title: "general.filter.EMAIL",
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = false;
      break;
    case EnumPermissionEntity.BOOKING:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.LASTNAME],
          type: EnumFilterType.SELECT,
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.FIRSTNAME],
          type: EnumFilterType.SELECT,
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.EMAIL],
          type: EnumFilterType.SELECT,
          async: true,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = false;
      break;
    case EnumPermissionEntity.BOOKING_LIST:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.START],
          type: EnumFilterType.DATE,
          defaultValue: new Date(),
          customInput: true,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.DATE]}`] = false;
      break;
    case EnumPermissionEntity.GLOBAL_ORDER:
      const globalOrderOptions = $enum(EnumOrderStatus)
        .getKeys()
        .map(s => ({
          value: s,
          label: getStatusLabel(intl, s),
        }))
        .filter(e => e.value !== EnumOrderStatus.PENDING);
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.STATUS],
          type: EnumFilterType.SELECT,
          title: "general.status",
          options: [
            {
              value: ALL_FILTER,
              label: getStatusLabel(intl, ALL_FILTER),
            },
            ...globalOrderOptions,
          ],
          async: false,
        },
        {
          value: EnumFilterFields[EnumFilterFields.PROVIDER],
          type: EnumFilterType.SELECT,
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.CLIENT_NAME],
          type: EnumFilterType.INPUT,
        },
        {
          value: EnumFilterFields[EnumFilterFields.EMAIL],
          type: EnumFilterType.INPUT,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = false;
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = false;
      break;
    case EnumPermissionEntity.INCIDENT:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.STATUS],
          type: EnumFilterType.SELECT,
          title: "general.filter.STATUS",
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.INCIDENT_TYPE],
          type: EnumFilterType.SELECT,
          title: "general.filter.INCIDENT_TYPE",
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.INCIDENT_REASON],
          type: EnumFilterType.SELECT,
          title: "general.filter.INCIDENT_REASON",
          disabled: true,
          async: false,
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.INCIDENT_TYPE],
              operator: "filter_value",
            },
          ],
        },
        {
          value: EnumFilterFields[EnumFilterFields.CLIENT_NAME],
          type: EnumFilterType.INPUT,
          title: "general.filter.CLIENT_NAME",
        },
        {
          value: EnumFilterFields[EnumFilterFields.CREATION_START_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.creation.startdate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.CREATION_END_DATE],
              operator: "lte",
            },
          ],
        },
        {
          value: EnumFilterFields[EnumFilterFields.CREATION_END_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.creation.enddate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.CREATION_START_DATE],
              operator: "gte",
            },
          ],
        },
        {
          value: EnumFilterFields[EnumFilterFields.PINNED],
          type: EnumFilterType.SWITCH,
          title: "general.filter.PINNED",
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = true;
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = true;
      break;
    case EnumPermissionEntity.VISIT:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.START_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.date",
        },
      ]);
      break;
    case EnumPermissionEntity.NEWS:
      const newsOptions = $enum(EnumNewsStatus)
        .getKeys()
        .map(s => ({
          value: s,
          label: intl.formatMessage({ id: `general.${s}` }),
        }));
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.ENABLED],
          type: EnumFilterType.SELECT,
          title: "general.status",
          options: [
            {
              value: ALL_FILTER,
              label: getStatusLabel(intl, ALL_FILTER),
            },
            ...newsOptions,
          ],
          async: false,
        },
        {
          value: EnumFilterFields[EnumFilterFields.TITLE],
          type: EnumFilterType.INPUT,
          title: "general.title",
        },
        {
          value: EnumFilterFields[EnumFilterFields.PUBLICATION_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.publicationDate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.UNPUBLICATION_DATE],
              operator: "lte",
            },
          ],
        },
        {
          value: EnumFilterFields[EnumFilterFields.UNPUBLICATION_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.unpublicationDate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.PUBLICATION_DATE],
              operator: "gte",
            },
          ],
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = true;
      multiple[`${EnumFilterType[EnumFilterType.DATE]}`] = true;
      break;
    case EnumPermissionEntity.EVENT:
      const eventOptions = $enum(EnumEventStatus)
        .getKeys()
        .map(s => ({
          value: s,
          label: intl.formatMessage({ id: `general.${s}` }),
        }));
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.ENABLED],
          type: EnumFilterType.SELECT,
          title: "general.status",
          options: [
            {
              value: ALL_FILTER,
              label: getStatusLabel(intl, ALL_FILTER),
            },
            ...eventOptions,
          ],
          async: false,
        },
        {
          value: EnumFilterFields[EnumFilterFields.TITLE],
          type: EnumFilterType.INPUT,
          title: "general.title",
        },
        {
          value: EnumFilterFields[EnumFilterFields.START_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.startdate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.END_DATE],
              operator: "lte",
            },
          ],
        },
        {
          value: EnumFilterFields[EnumFilterFields.END_DATE],
          type: EnumFilterType.DATE,
          customInput: false,
          title: "general.filter.enddate",
          dependsOn: [
            {
              field: EnumFilterFields[EnumFilterFields.START_DATE],
              operator: "gte",
            },
          ],
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.INPUT]}`] = true;
      multiple[`${EnumFilterType[EnumFilterType.DATE]}`] = true;
      break;
    case EnumPermissionEntity.CALENDAR:
      const typologyOptions = getSpaceTypologies().map(s => ({
        value: s,
        label: intl.formatMessage({ id: `page.product.typology.${s}` }),
      }));

      const capacityOptions = capacityValuesList.map(capacityValues => ({
        value: capacityValues.min,
        label: intl.formatMessage(
          {
            id: capacityValues.max
              ? "page.calendar.filter.capacity.people"
              : "page.calendar.filter.capacity.people.max",
          },
          capacityValues
        ),
      }));
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.TYPOLOGY],
          type: EnumFilterType.SELECT,
          title: "page.calendar.filter.space.type",
          options: typologyOptions,
          async: false,
        },
        {
          value: EnumFilterFields[EnumFilterFields.CAPACITY],
          type: EnumFilterType.SELECT,
          title: "general.capacity",
          options: capacityOptions,
          async: false,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = true;
      break;
    case EnumPermissionEntity.BOOKING_SPACE_LIST:
      const typologies = getSpaceTypologies().map(s => ({
        value: s,
        label: intl.formatMessage({ id: `page.product.typology.${s}` }),
      }));

      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.TYPOLOGY],
          type: EnumFilterType.SELECT,
          title: "page.calendar.filter.space.type",
          options: [
            {
              value: ALL_FILTER,
              label: intl.formatMessage({ id: "general.all" }),
            },
            ...typologies,
          ],
          async: false,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = true;
      break;
    case EnumPermissionEntity.SERVICE:
      const options = [
        ProductAttributeKeys.AS_OPTION,
        ProductAttributeKeys.IN_CATALOG,
      ].map(s => ({
        value: s,
        label: intl.formatMessage({
          id: `page.list.products.${EnumPermissionEntity.SERVICE}.filter.visibility.${s}`,
        }),
      }));
      values = values.concat([
        {
          title: "general.services.filter",
          value: EnumFilterFields[EnumFilterFields.ATTRIBUTE],
          type: EnumFilterType.SELECT,
          options: [
            {
              value: ALL_FILTER,
              label: getStatusLabel(intl, ALL_FILTER),
            },
            ...options,
          ],
          async: false,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = false;
      break;
    case EnumPermissionEntity.NOTIFICATION:
      values = values.concat([
        {
          value: EnumFilterFields[EnumFilterFields.LASTNAME],
          type: EnumFilterType.SELECT,
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.FIRSTNAME],
          type: EnumFilterType.SELECT,
          async: true,
        },
        {
          value: EnumFilterFields[EnumFilterFields.EMAIL],
          type: EnumFilterType.SELECT,
          async: true,
        },
      ]);
      multiple[`${EnumFilterType[EnumFilterType.SELECT]}`] = true;
      break;
    default:
      break;
  }

  return { values, multiple };
};

export const getSortFields = (
  entity: EnumPermissionEntity
): {
  field: string;
  directions: SearchableSortDirection[];
}[] => {
  let values: {
    field: string;
    directions: SearchableSortDirection[];
  }[] = [];
  switch (entity) {
    case EnumPermissionEntity.NEWS:
      values = values.concat([
        {
          field: SearchableNewsSortableFields.publicationDate,
          directions: [
            SearchableSortDirection.desc,
            SearchableSortDirection.asc,
          ],
        },
        {
          field: SearchableNewsSortableFields.unpublicationDate,
          directions: [
            SearchableSortDirection.desc,
            SearchableSortDirection.asc,
          ],
        },
      ]);
      break;
    case EnumPermissionEntity.EVENT:
      values = values.concat([
        {
          field: SearchableEventSortableFields.start,
          directions: [
            SearchableSortDirection.desc,
            SearchableSortDirection.asc,
          ],
        },
        {
          field: SearchableEventSortableFields.end,
          directions: [
            SearchableSortDirection.desc,
            SearchableSortDirection.asc,
          ],
        },
      ]);
      break;
    case EnumPermissionEntity.PARTICIPATION:
      values = values.concat([
        {
          field: SearchableParticipationSortableFields.createdAt,
          directions: [
            SearchableSortDirection.desc,
            SearchableSortDirection.asc,
          ],
        },
      ]);
      break;
    default:
      break;
  }

  return values;
};

interface IDateRange {
  start: Date;
  end: Date;
}

export const getReportingDates = (
  date: Date,
  view: EnumReportingView,
  timezone: string
): {
  previous: IDateRange;
  current: IDateRange;
  next: IDateRange;
} => {
  if (view === EnumReportingView.WEEK) {
    const { startWeek, endWeek } = getWeekInCenterTimezone(date, timezone);
    const current = {
      start: startWeek,
      end: endWeek,
    };

    const p = getPreviousWeek(current);
    const n = getNextWeek(current);

    const previous = {
      start: p.startWeek,
      end: p.endWeek,
    };

    const next = {
      start: n.startWeek,
      end: n.endWeek,
    };

    return {
      current,
      previous,
      next,
    };
  } else {
    const { startMonth, endMonth } = getMonthInLocal(date);

    const p = getPreviousMonthInLocal({
      start: startMonth,
      end: endMonth,
    });

    const n = getNextMonthInLocal({
      start: startMonth,
      end: endMonth,
    });

    const previous = {
      start: p.startMonth,
      end: p.endMonth,
    };

    const next = {
      start: n.startMonth,
      end: n.endMonth,
    };

    return {
      current: {
        start: startMonth,
        end: endMonth,
      },
      previous,
      next,
    };
  }
};
