import {
  Form,
  Formik,
  FormikValues,
  setNestedObjectValues,
  FormikTouched,
  Field,
} from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PhoneNumberInput from "../Phone";
import IntlMessages from "../../../utils/messages";
import { IRange, Value } from "../../../utils/types";
import SearchBox, { Address } from "../../SearchBox";
import NumberInput from "../NumberInput";
import SavePanel from "../SavePanel";
import Select from "../Select";
import Switch from "../Switch";
import TextArea from "../TextArea";
import TextInput from "../TextInput";
import DateComponent from "../Date";
import { ICountry, ILocale } from "../../../lang";
import Checkbox from "../Checkbox";
import MultipleInput from "../MultipleInput";
import { OpeningClosingHour } from "../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  item: { id: string } | null | undefined;
  children?: React.ReactNode;
  values: Array<Value> | null;
  onSubmit?: (e) => void;
  onChange?: (e, attrKey: any) => void;
  onChangeCategory?: (e) => void;
  onChangeTypology?: (e) => void;
  onChangePhone?: (e) => void;
  onChangeSwitch?: (checked: boolean, e) => void;
  enableReinitialize?: boolean;
  showSavePanel?: boolean;
  onHideSavePanel?: () => void;
  grid?: number;
  className?: string;
  alternate?: boolean;
  width?: string;
  validationSchema?: any;
  date?: Date;
  minTime?: Date;
  maxTime?: Date;
  centerOpeningHours?: OpeningClosingHour[] | null;
  locale?: ILocale;
  countries?: ICountry[];
}

const GroundFormik = (props: Props, ref): JSX.Element => {
  let { alternate, width } = props;
  const { countries } = props;
  alternate = alternate || true;
  width = width || "max-w-xs";

  const initialValues = props.values?.reduce((acc: FormikValues, el) => {
    if (el.type === "select") {
      const ids = el.values?.reduce((accIds: string[], elValue) => {
        if (elValue && elValue.id) {
          accIds.push(elValue.id);
        }

        return accIds;
      }, []);

      if (el.multiple) {
        return { ...acc, [el.name]: ids };
      }

      return { ...acc, [el.name]: ids && ids.length > 0 ? ids[0] : "" };
    }

    return { ...acc, [el.name]: el.value };
  }, {});

  const onSubmitForm = values => {
    if (props.onSubmit) {
      props.onSubmit(values);
    }
  };

  const formRef = useRef<any>();

  const validateForm = () => {
    return formRef.current.validateForm();
  };

  const showErrors = errors => {
    formRef.current.setTouched(
      setNestedObjectValues<FormikTouched<FormikValues>>(errors, true)
    );

    const selector = `[id="${Object.keys(errors)[0]}"]`;

    const errorElement: HTMLElement | null = document.querySelector(selector);

    if (errorElement) {
      errorElement.focus();
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      validateForm,
      showErrors,
    }),
    []
  );

  return (
    <Formik
      enableReinitialize={props.enableReinitialize ?? true}
      innerRef={formRef}
      initialValues={initialValues || {}}
      validationSchema={props.validationSchema}
      validate={(values: FormikValues) => {
        const errors = props.values?.reduce((acc, el) => {
          let valueKo = false;
          let value;
          if (el.translatable) {
            try {
              value = values[el.name];
              const json = values[el.name] ? JSON.parse(values[el.name]) : null;
              if (json) {
                const locales = Object.keys(json);
                locales.forEach(locale => {
                  if (!json[`${locale}`]) {
                    valueKo = true;
                  }
                });
              } else {
                valueKo = !values[el.name];
              }
            } catch (error) {
              // console.error({ 'Unable to parse value: ': error })
              valueKo = !values[el.name];
            }
          } else {
            value = values[el.name];
            valueKo = !values[el.name];
          }
          if (el.type === "number") {
            const floatValue = parseFloat(value);
            valueKo = floatValue === 0 || Number.isNaN(floatValue);
          }
          if (!valueKo && el.type === "email") {
            if (el.multiple) {
              value.forEach(email => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                  acc[el.name] = (
                    <IntlMessages id="general.field.email.invalid" />
                  );
                }
              });
            } else {
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                acc[el.name] = (
                  <IntlMessages id="general.field.email.invalid" />
                );
              }
            }
          }
          if (!valueKo && el.type === "range") {
            if (!value.start && !value.end) {
              acc[el.name] = <IntlMessages id="general.required.field" />;
            }
          }
          if (el.required && valueKo) {
            acc[el.name] = <IntlMessages id="general.required.field" />;
          }

          return { ...acc };
        }, {});

        return errors;
      }}
      onSubmit={onSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setTouched,
      }) => {
        const handleOnChange = (e, el: Value) => {
          if (el.type === "email" && el.multiple && props.onChange) {
            props.onChange(
              {
                target: {
                  name: "email",
                  value: e,
                },
              },
              el.item
            );
            setTouched({ email: true });
            setFieldValue("email", e);

            return;
          }

          handleChange(e);

          if (props.onChange) {
            props.onChange(e, el.item);
          }

          // change category
          if (
            e.target &&
            (e.target.name === "serviceCategoryId" ||
              e.target.name === "productCategoryId" ||
              e.target.name === "eventTypeId" ||
              e.target.name === "newsCategoryId") &&
            props.onChangeCategory
          ) {
            const { value } = e.target;
            props.onChangeCategory(value);
          }

          // change typology (product)
          if (
            e.target &&
            e.target.name === "typology" &&
            props.onChangeTypology
          ) {
            props.onChangeTypology(e.target.value);
          }

          // change phone (user)
          if (el.type === "phone" && props.onChangePhone) {
            props.onChangePhone(e);
          }
        };

        const changeLocation = (data: Address | null) => {
          if (data) {
            setFieldValue("address", data.street);
            setFieldValue("zipCode", data.zipCode);
            setFieldValue("city", data.city);
            setFieldValue("country", data.country_code);

            if (data?.location) {
              setFieldValue(
                "latitude",
                parseFloat(data.location.lat.toFixed(7))
              );
              setFieldValue(
                "longitude",
                parseFloat(data.location.lon.toFixed(7))
              );
            }
          } else {
            setFieldValue("address", "");
            setFieldValue("zipCode", "");
            setFieldValue("city", "");
            setFieldValue("country", "");
            setFieldValue("latitude", "");
            setFieldValue("longitude", "");
          }
        };

        const onChangeTime = time => {
          setFieldValue("time.start", time.startDate);
          setFieldValue("time.end", time.endDate);
        };

        const onChangeDate = (dates: { startDate: Date; endDate: Date }) => {
          setFieldValue("date.start", dates.startDate);
          setFieldValue("date.end", dates.endDate);
        };

        return (
          <Form>
            {props.values?.map((e, index) => {
              const counter = index;

              return (
                <div key={counter} className={e.hidden ? "hidden" : ""}>
                  {(e.type === "text" ||
                    (e.type === "email" && !e.multiple) ||
                    e.type === "password") && (
                    <TextInput
                      item={props.item}
                      label={e.label}
                      index={counter}
                      type={e.type}
                      id={e.id}
                      name={e.name}
                      required={e.required}
                      value={values[e.name]}
                      placeholder={e.placeholder}
                      onChange={el => handleOnChange(el, e)}
                      disabled={e.disabled}
                      hidden={e.hidden}
                      rows={
                        e.id === "address-search"
                          ? [
                              <SearchBox
                                key={0}
                                placesAutocomplete
                                getGeolocation={address =>
                                  changeLocation(address)
                                }
                              />,
                            ]
                          : e.rows
                      }
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      className={props.className}
                      translatable={e.translatable}
                      autoComplete="new-password"
                      useIntl={e.useIntl}
                      description={e.description}
                      alternate={alternate}
                      width={width}
                    >
                      {e.children}
                    </TextInput>
                  )}
                  {e.type === "email" && e.multiple && (
                    <MultipleInput
                      label={e.label}
                      index={counter}
                      name={e.name}
                      value={values[e.name]}
                      onChange={el => handleOnChange(el, e)}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      className={props.className}
                      useIntl={e.useIntl}
                      alternate={alternate}
                      width={width}
                    >
                      {e.children}
                    </MultipleInput>
                  )}
                  {e.type === "number" && (
                    <NumberInput
                      item={props.item}
                      label={e.label}
                      index={counter}
                      type={e.type}
                      id={e.id}
                      required={e.required}
                      name={e.name}
                      value={values[e.name]}
                      placeholder={e.placeholder}
                      onChange={el => handleOnChange(el, e)}
                      disabled={e.disabled}
                      hidden={e.hidden}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      className={props.className}
                      step={e.step}
                      min={e.min}
                      max={e.max}
                      useIntl={e.useIntl}
                      description={e.description}
                      alternate={alternate}
                    >
                      {e.children}
                    </NumberInput>
                  )}
                  {e.type === "select" && e.multiple && (
                    <Select
                      item={props.item}
                      label={e.label}
                      index={counter}
                      id={e.id}
                      name={e.name}
                      required={e.required}
                      placeholder={e.placeholder}
                      items={e.items}
                      values={values[e.name]}
                      multiple={e.multiple}
                      onChange={el => handleOnChange(el, e)}
                      disabled={e.disabled}
                      hidden={e.hidden}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      className={props.className}
                      useIntl={e.useIntl}
                      description={e.description}
                      alternate={alternate}
                    >
                      {e.children}
                    </Select>
                  )}
                  {e.type === "select" && !e.multiple && (
                    <Select
                      item={props.item}
                      label={e.label}
                      index={counter}
                      id={e.id}
                      name={e.name}
                      required={e.required}
                      placeholder={e.placeholder}
                      items={e.items}
                      value={values[e.name]}
                      multiple={e.multiple}
                      onChange={el => handleOnChange(el, e)}
                      disabled={e.disabled}
                      hidden={e.hidden}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      className={props.className}
                      useIntl={e.useIntl}
                      description={e.description}
                      alternate={alternate}
                    >
                      {e.children}
                    </Select>
                  )}
                  {e.type === "textarea" && (
                    <TextArea
                      item={props.item}
                      id={e.id}
                      name={e.name}
                      label={e.label}
                      required={e.required}
                      placeholder={e.placeholder}
                      rows={7}
                      index={counter}
                      value={values[e.name]}
                      onChange={el => handleOnChange(el, e)}
                      disabled={e.disabled}
                      hidden={e.hidden}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      grid={props.grid}
                      translatable={e.translatable}
                      useIntl={e.useIntl}
                      alternate={alternate}
                    >
                      {e.children}
                    </TextArea>
                  )}
                  {e.type === "switch" && (
                    <Switch
                      item={props.item}
                      id={e.name}
                      name={e.name}
                      label={e.label}
                      value={values[e.name]}
                      index={counter}
                      onChange={(checked, el) => {
                        setFieldValue(el.target.id, checked);
                        if (props.onChangeSwitch) {
                          props.onChangeSwitch(checked, el);
                        }
                      }}
                      useIntl={e.useIntl}
                      description={e.description}
                      alternate={alternate}
                    >
                      {e.children}
                    </Switch>
                  )}
                  {e.type === "checkbox" && (
                    <Checkbox
                      name={e.name}
                      label={e.label}
                      value={values[e.name]}
                      index={counter}
                      useIntl={e.useIntl}
                      onChange={(checked, el) => {
                        setFieldValue(e.name, checked);
                        if (props.onChangeSwitch) {
                          props.onChangeSwitch(checked, el);
                        }
                      }}
                      alternate={alternate}
                    >
                      {e.children}
                    </Checkbox>
                  )}
                  {e.type === "range" && (
                    <Field name={e.name}>
                      {() => {
                        const range = e.value as IRange;

                        return (
                          <DateComponent
                            index={counter}
                            onChange={onChangeDate}
                            required={e.required}
                            start={range?.start}
                            end={range?.end}
                            disabledDates={e.disabledDates}
                            name={e.name}
                            alternate={alternate || true}
                            errors={errors}
                            touched={touched}
                            useIntl={e.useIntl}
                            label={e.label}
                          />
                        );
                      }}
                    </Field>
                  )}
                  {e.type === "phone" && (
                    <PhoneNumberInput
                      label={e.label}
                      index={counter}
                      required={e.required}
                      name={e.name}
                      value={values[e.name]}
                      onChange={el => handleOnChange(el, e)}
                      grid={props.grid}
                      className={props.className}
                      useIntl={e.useIntl}
                      alternate={alternate}
                      locale={props.locale}
                      countries={countries}
                    />
                  )}
                </div>
              );
            })}
            {props.children}
            {ref && props.showSavePanel && props.onHideSavePanel && (
              <SavePanel
                label="general.panel.save"
                visible={props.showSavePanel}
                onCancel={props.onHideSavePanel}
                onSave={() => {
                  ref!.current!.onSubmitForm(values, { setSubmitting: false });
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(GroundFormik);
