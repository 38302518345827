import React from "react";
import classnames from "classnames";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import { getSupportedLocales } from "../../../lang";
import { useTranslatableField } from "../../../utils/locale";
import LocaleSwitcher from "../../LocaleSwitcher";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  rows: number;
  value: string;
  label: string;
  placeholder?: string;
  description?: string;
  index: number;
  last?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors?: any;
  touched?: any;
  grid?: number;
  translatable?: boolean;
  useIntl: boolean;
  alternate?: boolean;
}

const TextArea = (props: Props) => {
  props = {
    ...props,
    alternate: props.alternate != undefined ? props.alternate : true,
  };

  const locale = contextStore.useStoreState(state => state.settings.locale);

  const { value, displayedValue, currentLocale, setCurrentLocale, onChange } =
    useTranslatableField(
      !!props.translatable,
      props.value,
      props.onChange,
      locale
    );

  // const displayedValue = getTranslation(props.value)
  const className = props.grid
    ? `sm:grid-cols-${props.grid}`
    : "sm:grid-cols-3";

  const containerClassName = id =>
    classnames(
      "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " + className,
      {
        "bg-ground-white-200": props.alternate && id % 2 === 0,
        "rounded-b-10": props.last,
      }
    );

  const invalid = props.errors[props.name] && props.touched[props.name];

  const formClassName = classnames(
    "form-textarea block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
    }
  );

  return (
    <div className={containerClassName(props.index)}>
      <label
        htmlFor={props.name}
        className="block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 "
      >
        {props.useIntl && <IntlMessages id={props.label} />}
        {!props.useIntl && `${props.label}`} {props.required && "*"}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <textarea
            className={formClassName}
            id={props.id}
            data-cy={props.name}
            data-testid={getCypressTestId(props.item)}
            name={props.name}
            rows={props.rows}
            placeholder={
              props.useIntl
                ? props.intl.formatMessage({ id: `${props.placeholder}` })
                : props.placeholder
            }
            value={displayedValue}
            disabled={props.disabled}
            onChange={onChange}
          />
        </div>
        {props.translatable && (
          <LocaleSwitcher
            currentLocale={currentLocale}
            setCurrentLocale={setCurrentLocale}
            locales={getSupportedLocales()}
            value={value}
          />
        )}
        {props.description && (
          <p className="mt-2 text-14px text-gray-500">{props.description}</p>
        )}
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${props.name}-error`}>
            {props.errors[props.name]}
          </p>
        )}
      </div>
    </div>
  );
};

export default injectIntl(TextArea);
