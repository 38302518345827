import React from "react";
import Button from "../Base";
import { ButtonProps } from "../index";

const Primary = ({
  item,
  children,
  type,
  id,
  name,
  onClick,
  small,
}: ButtonProps) => {
  const fontSize = small ? "sm:text-xs" : "sm:text-sm";
  const primaryClassName = "bg-ground-blue-100 text-white " + fontSize;

  return (
    <Button
      item={item}
      id={id}
      name={name}
      type={type}
      className={primaryClassName}
      onClick={onClick}
      small={small}
    >
      {children}
    </Button>
  );
};

export default Primary;
