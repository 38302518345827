import React from "react";

interface Props {
  id: string;
  src: string;
  onClick: () => void;
}

const NavLogo = (props: Props) => {
  return (
    <div
      id={props.id}
      data-cy={props.id}
      className="flex-shrink-0 flex items-center"
      onClick={props.onClick}
    >
      <img className="block lg:hidden w-18 h-auto" src={props.src} alt="" />
      <img className="hidden lg:block w-55 h-auto" src={props.src} alt="" />
    </div>
  );
};

export default NavLogo;
