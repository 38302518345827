import axios from "axios";
import history from "./history";
import { asyncLocalStorage } from "./utils/token";

const axiosLegacy = axios.create();

const signOut = () => {
  asyncLocalStorage.clear().then(() => {
    history.push("/");
  });
};

const handleCallback = (): void => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  const url = process.env.REACT_APP_AUTHENTICATION_API;
  if (token && refreshToken && refreshToken !== "undefined") {
    console.log("Start refreshing token...");
    const body = {
      refreshToken,
    };
    const path = "/refreshtoken";
    axiosLegacy
      .post(url + path, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        console.log("Refreshing token success...");
        // set tokens
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", refreshToken);
        window.location.reload();
      })
      .catch(err => {
        console.error({
          "Error occured when refreshing token, error:": err,
        });
        signOut();
      });
  }
};

// we intercept every response
axiosLegacy.interceptors.response.use(undefined, error => {
  if (error.response?.status === 401) {
    // sign out
    handleCallback();
  }

  return Promise.reject(error);
});

export default axiosLegacy;
