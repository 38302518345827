import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import {
  EnumProductTypology,
  ListAssetReportingsFilterInput,
  ListReportingsGlobalFilterInput,
  ListIncidentReportingsFilterInput,
  ListOrderReportingsFilterInput,
  ListReportingsUserFilterInput,
  ListReportingsIncidentFilterInput,
  ListReportingsIncidentReasonFilterInput,
  ListReportingsSalesFilterInput,
} from "./../../api/graphql/types";
import { ErrorGraphQL } from "../../services/coreUtils";
import {
  Booking,
  EnumReportingBookingType,
  EnumSpaceTypology,
  SpaceCalendar,
} from "../Product";
import { IMultiLanguage } from "../Api";

export interface ListAssetReportingsOpts {
  filter: ListAssetReportingsFilterInput;
}

export interface AssetReportingTypology {
  typology: EnumSpaceTypology;
  total_spaces: number;
  indicators: AssetReportingIndicators;
}

export interface BookingIndicators {
  bookings: Booking[];
  occupancy_rate: number;
  total_remaining_places?: number;
}

export interface AssetReportingSpaceIndicators {
  space: SpaceCalendar;
  indicators: BookingIndicators;
}

export interface AssetReportingIndicators extends BookingIndicators {
  spaces: AssetReportingSpaceIndicators[];
}

export interface AssetReportingDateTypology {
  date: string;
  indicators: AssetReportingIndicators;
  data: AssetReportingTypology[];
}

export interface ModelAssetReportingConnection {
  items: AssetReportingDateTypology[];
}

export interface OrderReportingIndicators {
  total_sales: number;
  total_cancellations: number;
  total_refunds: number;
  sale_rate: number;
  cancellation_rate: number;
  refund_rate: number;
}

export interface OrderReportingTypology {
  typology: EnumProductTypology;
  indicators: OrderReportingIndicators;
}
export interface OrderReportingDateTypology {
  date: string;
  indicators: OrderReportingIndicators;
  data: OrderReportingTypology[];
}

export interface IncidentReportingIndicators {
  total_incidents: number;
}

export interface IncidentReportingStatus {
  status: {
    code: number;
    label: IMultiLanguage;
  };
  indicators: IncidentReportingIndicators;
}

export interface IncidentReportingType {
  type: {
    code: string;
    label: IMultiLanguage;
  };
  indicators: IncidentReportingIndicators;
}

export interface IncidentReportingDate {
  date: string;
  indicators: IncidentReportingIndicators;
  indicators_status: IncidentReportingStatus[];
  indicators_type: IncidentReportingType[];
}

export interface ReportingUserDate {
  date: string;
  createdStartDate: string;
  createdEndDate: string;
  role: string;
  status: string;
  total: number;
  variation: number;
  evolutionRate: number;
}

export interface ReportingGlobalDate {
  date: string;
  createdStartDate: string;
  createdEndDate: string;
  status: string;
  total: number;
  variation: number;
  evolutionRate: number;
}

export interface ReportingBookingsDate {
  date: string;
  createdStartDate: string;
  createdEndDate: string;
  view: string;
  type: EnumReportingBookingType;
  typology: string;
  total: number;
  variation: number;
  evolutionRate: number;
}

export interface ReportingSalesDate {
  date: string;
  createdStartDate: string;
  createdEndDate: string;
  type: string;
  typology: string;
  amount: number;
  variation: number;
  rate: number;
  evolutionRate: number;
}

export interface ReportingIncidentDate {
  date: string;
  createdStartDate: string;
  createdEndDate: string;
  total: number;
  variation: number;
  status: {
    id: number;
    code: number;
  };
  type: {
    id: number;
    code: string;
  };
  reason: {
    id: number;
    code: string;
  };
  evolutionRate: number;
  resolutionTime: number;
}

export interface ReportingIncidentStatus {
  code: number;
  label: IMultiLanguage;
}

export interface ReportingIncidentType {
  id: number;
  code: string;
  label: IMultiLanguage;
}

export interface ReportingIncidentReason {
  code: string;
  label: IMultiLanguage;
}

export interface ListOrderReportingsOpts {
  filter: ListOrderReportingsFilterInput;
}

export interface ListIncidentReportingsOpts {
  filter: ListIncidentReportingsFilterInput;
}

export interface ListReportingsUserOpts {
  filter: ListReportingsUserFilterInput;
}

export interface ListReportingsGlobalOpts {
  filter: ListReportingsGlobalFilterInput;
}

export interface ListReportingsSalesOpts {
  filter: ListReportingsSalesFilterInput;
}

export interface ListReportingsIncidentOpts {
  filter: ListReportingsIncidentFilterInput;
}

export interface ListReportingsBookingsOpts {
  filter: ListReportingsSalesFilterInput;
}

export interface ModelOrderReportingConnection {
  items: OrderReportingDateTypology[];
}

export interface ModelIncidentReportingConnection {
  items: IncidentReportingDate[];
}

export interface ModelUserReportingConnection {
  items: ReportingUserDate[];
}

export interface ModelGlobalReportingConnection {
  items: ReportingGlobalDate[];
}

export interface ModelSalesReportingConnection {
  items: ReportingSalesDate[];
}

export interface ModelReportingsIncidentConnection {
  items: ReportingIncidentDate[];
}

export interface ModelReportingsIncidentStatusConnection {
  items: ReportingIncidentStatus[];
}

export interface ModelReportingsIncidentTypeConnection {
  items: ReportingIncidentType[];
}

export interface ModelReportingsIncidentReasonConnection {
  items: ReportingIncidentReason[];
}

export interface ListReportingsIncidentReasonOpts {
  filter: ListReportingsIncidentReasonFilterInput;
}

export interface ModelBookingsReportingConnection {
  items: ReportingBookingsDate[];
}

// Interface declaration
export interface ReportingModel {
  assetReportings: ModelAssetReportingConnection;
  orderReportings: ModelOrderReportingConnection;
  incidentReportings: ModelIncidentReportingConnection;

  reportingsUser: ModelUserReportingConnection;
  reportingsGlobal: ModelGlobalReportingConnection;
  reportingsSales: ModelSalesReportingConnection;
  reportingsIncident: ModelReportingsIncidentConnection;
  reportingsIncidentStatus: ModelReportingsIncidentStatusConnection;
  reportingsIncidentType: ModelReportingsIncidentTypeConnection;
  reportingsIncidentReason: ModelReportingsIncidentReasonConnection;
  reportingsBookings: ModelBookingsReportingConnection;

  setAssetReportings: Action<ReportingModel, ModelAssetReportingConnection>;
  setOrderReportings: Action<ReportingModel, ModelOrderReportingConnection>;
  setIncidentReportings: Action<
    ReportingModel,
    ModelIncidentReportingConnection
  >;
  setReportingsUser: Action<ReportingModel, ModelUserReportingConnection>;
  setReportingsGlobal: Action<ReportingModel, ModelGlobalReportingConnection>;
  setReportingsSales: Action<ReportingModel, ModelSalesReportingConnection>;
  setReportingsIncident: Action<
    ReportingModel,
    ModelReportingsIncidentConnection
  >;
  setReportingsIncidentStatus: Action<
    ReportingModel,
    ModelReportingsIncidentStatusConnection
  >;
  setReportingsIncidentType: Action<
    ReportingModel,
    ModelReportingsIncidentTypeConnection
  >;
  setReportingsIncidentReason: Action<
    ReportingModel,
    ModelReportingsIncidentReasonConnection
  >;
  setReportingsBookings: Action<
    ReportingModel,
    ModelBookingsReportingConnection
  >;

  listAssetReportings: Thunk<
    ReportingModel,
    ListAssetReportingsOpts,
    Injections,
    StoreModel
  >;

  listOrderReportings: Thunk<
    ReportingModel,
    ListOrderReportingsOpts,
    Injections,
    StoreModel
  >;

  listIncidentReportings: Thunk<
    ReportingModel,
    ListIncidentReportingsOpts,
    Injections,
    StoreModel
  >;

  listReportingsUser: Thunk<
    ReportingModel,
    ListReportingsUserOpts,
    Injections,
    StoreModel
  >;

  listReportingsGlobal: Thunk<
    ReportingModel,
    ListReportingsGlobalOpts,
    Injections,
    StoreModel
  >;

  listReportingsSales: Thunk<
    ReportingModel,
    ListReportingsSalesOpts,
    Injections,
    StoreModel
  >;

  listReportingsIncident: Thunk<
    ReportingModel,
    ListReportingsIncidentOpts,
    Injections,
    StoreModel
  >;

  listReportingsIncidentStatus: Thunk<
    ReportingModel,
    undefined,
    Injections,
    StoreModel
  >;

  listReportingsIncidentType: Thunk<
    ReportingModel,
    undefined,
    Injections,
    StoreModel
  >;

  listReportingsIncidentReason: Thunk<
    ReportingModel,
    ListReportingsIncidentReasonOpts,
    Injections,
    StoreModel
  >;

  listReportingsBookings: Thunk<
    ReportingModel,
    ListReportingsBookingsOpts,
    Injections,
    StoreModel
  >;
}

export const reportingModel: ReportingModel = {
  assetReportings: { items: [] },
  orderReportings: { items: [] },
  incidentReportings: { items: [] },

  reportingsUser: { items: [] },
  reportingsGlobal: { items: [] },
  reportingsSales: { items: [] },
  reportingsIncident: { items: [] },
  reportingsIncidentStatus: { items: [] },
  reportingsIncidentType: { items: [] },
  reportingsIncidentReason: { items: [] },
  reportingsBookings: { items: [] },

  setAssetReportings: action((state, data: ModelAssetReportingConnection) => {
    state.assetReportings = { items: data.items };
  }),

  setOrderReportings: action((state, data: ModelOrderReportingConnection) => {
    state.orderReportings = { items: data.items };
  }),

  setIncidentReportings: action(
    (state, data: ModelIncidentReportingConnection) => {
      state.incidentReportings = { items: data.items };
    }
  ),

  setReportingsUser: action((state, data: ModelUserReportingConnection) => {
    state.reportingsUser = { items: data.items };
  }),

  setReportingsGlobal: action((state, data: ModelGlobalReportingConnection) => {
    state.reportingsGlobal = { items: data.items };
  }),

  setReportingsSales: action((state, data: ModelSalesReportingConnection) => {
    state.reportingsSales = { items: data.items };
  }),

  setReportingsIncident: action(
    (state, data: ModelReportingsIncidentConnection) => {
      state.reportingsIncident = { items: data.items };
    }
  ),

  setReportingsIncidentStatus: action(
    (state, data: ModelReportingsIncidentStatusConnection) => {
      state.reportingsIncidentStatus = { items: data.items };
    }
  ),

  setReportingsIncidentType: action(
    (state, data: ModelReportingsIncidentTypeConnection) => {
      state.reportingsIncidentType = { items: data.items };
    }
  ),

  setReportingsIncidentReason: action(
    (state, data: ModelReportingsIncidentReasonConnection) => {
      state.reportingsIncidentReason = { items: data.items };
    }
  ),

  setReportingsBookings: action(
    (state, data: ModelBookingsReportingConnection) => {
      state.reportingsBookings = { items: data.items };
    }
  ),

  listAssetReportings: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listAssetReportings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listAssetReportings) {
      const data = response.data.listAssetReportings;
      actions.setAssetReportings(data);

      return data;
    }

    return null;
  }),

  listOrderReportings: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listOrderReportings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listOrderReportings) {
      const data = response.data.listOrderReportings;
      actions.setOrderReportings(data);

      return data;
    }

    return null;
  }),

  listIncidentReportings: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listIncidentReportings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listIncidentReportings) {
      const data = response.data.listIncidentReportings;
      actions.setIncidentReportings(data);

      return data;
    }

    return null;
  }),

  listReportingsUser: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listReportingsUser(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listReportingsUser) {
      const data = response.data.listReportingsUser;
      actions.setReportingsUser(data);

      return data;
    }

    return null;
  }),

  listReportingsGlobal: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listReportingsGlobal(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listReportingsGlobal) {
      const data = response.data.listReportingsGlobal;
      actions.setReportingsGlobal(data);

      return data;
    }

    return null;
  }),

  listReportingsSales: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listReportingsSales(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listReportingsSales) {
      const data = response.data.listReportingsSales;
      actions.setReportingsSales(data);

      return data;
    }

    return null;
  }),

  listReportingsIncident: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listReportingsIncident(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listReportingsIncident) {
      const data = response.data.listReportingsIncident;
      actions.setReportingsIncident(data);

      return data;
    }

    return null;
  }),

  listReportingsIncidentStatus: thunk(
    async (actions, payload, { injections }) => {
      const { reportingService } = injections;
      const response = await reportingService.listReportingsIncidentStatus();
      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);
      if (response.data.listReportingsIncidentStatus) {
        const data = response.data.listReportingsIncidentStatus;
        actions.setReportingsIncidentStatus(data);

        return data;
      }

      return null;
    }
  ),

  listReportingsIncidentType: thunk(
    async (actions, payload, { injections }) => {
      const { reportingService } = injections;
      const response = await reportingService.listReportingsIncidentType();
      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);
      if (response.data.listReportingsIncidentType) {
        const data = response.data.listReportingsIncidentType;
        actions.setReportingsIncidentType(data);

        return data;
      }

      return null;
    }
  ),

  listReportingsIncidentReason: thunk(
    async (actions, payload, { injections }) => {
      const { reportingService } = injections;
      const response = await reportingService.listReportingsIncidentReason(
        payload
      );
      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);
      if (response.data.listReportingsIncidentReason) {
        const data = response.data.listReportingsIncidentReason;
        actions.setReportingsIncidentReason(data);

        return data;
      }

      return null;
    }
  ),

  listReportingsBookings: thunk(async (actions, payload, { injections }) => {
    const { reportingService } = injections;
    const response = await reportingService.listReportingsBookings(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.listReportingsBookings) {
      const data = response.data.listReportingsBookings;
      actions.setReportingsBookings(data);

      return data;
    }

    return null;
  }),
};
