import { v4 as uuidv4 } from "uuid";
import { Promise } from "bluebird";
import { Buffer } from "buffer";
import { getImageManagerApi } from "../../../utils/config";
import { Image, ActionTypes } from "../../../utils/types";
import { createPictureUrl } from "../../../utils/picture";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import axiosLegacy from "../../../axiosLegacy";

window.Buffer = Buffer;

export function uploadPicturesPromise(
  pictures: Image[] | undefined,
  entity: EnumPermissionEntity
): Promise<any> {
  return new Promise((resolve): void => {
    uploadPictures(pictures, entity, (_error, data) => {
      resolve(data);
    });
  });
}

type Callback = (
  error?: Error | null,
  data?: { success: boolean; urls: string[] | undefined }
) => void;

function uploadPictures(
  pictures: Image[] | undefined,
  entity: EnumPermissionEntity,
  callback: Callback
) {
  const picturesToKeep = pictures?.filter(
    e => e.action === ActionTypes.TO_KEEP
  );

  const urls = picturesToKeep?.map(el => el.picture);

  const picturesToUpload = pictures?.filter(
    e => e.source === true && e.action === ActionTypes.TO_ADD
  );

  if (picturesToUpload && picturesToUpload.length > 0) {
    Promise.mapSeries(picturesToUpload, (item: Image) => {
      const timestamp = new Date();
      const pictureKey = `${uuidv4()}_${timestamp.getTime()}`;
      const bucket = getBucketFolder(entity);

      return uploadPicture(pictureKey, item.picture!, bucket);
    })
      .then(results => {
        // picture urls uploaded
        const elements = results.reduce(
          (acc, el: { success: boolean; url: string }) => {
            if (el.success) {
              acc.push(el.url);
            }

            return acc;
          },
          []
        );

        if (elements && elements.length === 0) {
          callback(null, { success: false, urls: urls });
        } else {
          callback(null, {
            success: true,
            urls: urls?.concat(elements),
          });
        }
      })
      .catch((err: any) => {
        console.error({ "UPLOAD IMAGES FAILED !": err });
        callback(null, { success: false, urls: urls });
      });
  } else {
    callback(null, { success: true, urls: urls });
  }
}

async function uploadPicture(
  fileName: string,
  picture: string,
  folder: string
) {
  const base64Data = Buffer.from(
    picture.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );

  const token = await localStorage.getItem("token");
  const configs = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const data = {
    key: `${folder}/${fileName}.jpg`,
    image: base64Data,
  };

  const api = getImageManagerApi();

  return new Promise(function (resolve, reject) {
    const response = axiosLegacy.post(api + "/image", data, configs);
    response
      .then(() => {
        const url = createPictureUrl(fileName, folder);
        resolve({ success: true, url: url });
      })
      .catch(err => {
        reject(err);
      });
  });
}

const EnumBucketFolders = {
  CENTER: "centers",
  EVENT: "events",
  NEWS: "news",
  PRODUCT: "products",
  PROVIDER: "providers",
  SERVICE: "services",
  USER: "users",
  LEAD: "leads",
};

const getBucketFolder = (entity: EnumPermissionEntity) => {
  switch (entity) {
    case EnumPermissionEntity.PRODUCT:
      return EnumBucketFolders.PRODUCT;
    case EnumPermissionEntity.CENTER:
      return EnumBucketFolders.CENTER;
    case EnumPermissionEntity.PROVIDER:
      return EnumBucketFolders.PROVIDER;
    case EnumPermissionEntity.SERVICE:
      return EnumBucketFolders.SERVICE;
    case EnumPermissionEntity.EVENT:
      return EnumBucketFolders.EVENT;
    case EnumPermissionEntity.NEWS:
      return EnumBucketFolders.NEWS;
    case EnumPermissionEntity.USER:
      return EnumBucketFolders.USER;
    default:
      return "";
  }
};
