import React, { Fragment } from "react";
import classNames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import { Tooltip as ReactToolip } from "react-tippy";
import cls from "classnames";
import {
  Booking,
  EnumBookingStatus,
  HoursStatus,
  OpeningClosingHour,
  SpaceTimeline,
  TimelineDate,
} from "../../lib/ground-aws-graphql-core/models/Product";
import IntlMessages from "../../utils/messages";
import { getCellBackgroundColorFromStatus } from "../../enums/BookingStatusEnum";
import { EnumUnavailabilityType } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { getTranslation } from "../../utils/translation";
import {
  getBookingInBuildingTZ,
  getMinMaxHoursRanges,
} from "../../utils/config";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";

export interface IInterval {
  start: Date;
  end: Date;
}

export type SpaceOpeningHours = {
  openingHours: OpeningClosingHour[] | null | undefined;
  closingHours: OpeningClosingHour[] | null | undefined;
  timeInterval: IInterval | null | undefined;
};

const getHoursStatus = (
  space: SpaceTimeline,
  { status, formatted_start, formatted_end, indicators }: HoursStatus,
  index: number,
  hoursStatusArray: HoursStatus[],
  intl: IntlShape,
  timezone: string
) => {
  const last = hoursStatusArray.length === index + 1;

  const { occupancy_rate, total_remaining_places, bookings } = indicators;

  const dayClassName = cls(
    "border-t border-b border-neutral-100",
    index === 0 ? "border-l" : "",
    index !== hoursStatusArray.length - 1 ? "border-r" : "border-r",
    getCellBackgroundColorFromStatus(status)
  );

  const hourCss = cls("text-12px leading-5 text-ground-gray-100", {
    invisible: false,
  });

  const dayStyle = {
    width: "48px",
    height: "32px",
  };

  // start and end for space
  const startHour = formatted_start;
  const endHour = formatted_end;

  const closed = status === EnumBookingStatus.CLOSED;

  const renderBooking = (
    booking: Booking,
    space: SpaceTimeline,
    intl: IntlShape
  ) => {
    const { sameDay, startDay, endDay, startHour, endHour } =
      getBookingInBuildingTZ(booking, timezone);

    const { product, type } = booking;

    const currentSpace =
      type === EnumUnavailabilityType.PARENT && product.id === space.id;

    const className = () =>
      classNames(
        "w-full h-15 flex justify-between items-center rounded-4 px-5 text-12px border border-white-100",
        {
          "text-ground-red-100": !currentSpace,
          "text-ground-white-100": currentSpace,
        }
      );

    return (
      <div key={`booking_${booking.id}`} className={className()}>
        <div className="flex flex-col">
          {currentSpace && (
            <span>
              {intl.formatMessage(
                { id: "page.list.bookings.booking.number" },
                {
                  ormOrder: booking.order.ormOrder,
                  name: "",
                }
              )}
            </span>
          )}
          {!currentSpace && (
            <span>
              {intl.formatMessage(
                { id: "page.list.bookings.booking.number" },
                {
                  ormOrder: booking.order.ormOrder,
                  name: `- ${getTranslation(booking.product.name)}`,
                }
              )}
            </span>
          )}
        </div>
        {!sameDay && (
          <div className="flex flex-col ml-1">
            <span>
              {startDay} {startHour}
            </span>
            <span>
              {endDay} {endHour}
            </span>
            <span>
              {intl.formatMessage({ id: "general.from" })} {startDay}{" "}
              {intl.formatMessage({ id: "general.at" })} {startHour}
            </span>
            <span>
              {intl.formatMessage({ id: "general.to" })} {endDay}{" "}
              {intl.formatMessage({ id: "general.at" })} {endHour}
            </span>
          </div>
        )}
        {sameDay && (
          <span className="ml-1">
            {startHour}
            {" - "}
            {endHour}
          </span>
        )}
      </div>
    );
  };

  return (
    <Fragment key={`hour_status_${index}`}>
      <ReactToolip
        position="top"
        arrowSize="small"
        animation="fade"
        trigger="mouseenter"
        html={
          <div className="flex flex-col align-items-start">
            {!closed && (
              <span className="text-12px">
                {intl.formatMessage(
                  { id: "general.occupancy.rate" },
                  { rate: (occupancy_rate * 100).toFixed(3) }
                )}
              </span>
            )}
            {!closed && total_remaining_places && (
              <span className="text-12px">
                {intl.formatMessage(
                  { id: "general.total.remaining.places" },
                  { total: total_remaining_places }
                )}
              </span>
            )}
            {closed && (
              <span className="text-12px">
                {intl.formatMessage({
                  id: "page.list.bookings.not.bookable.reason.BUILDING_OR_SPACE_CLOSED",
                })}
              </span>
            )}
            <div className="mt-2">
              {bookings?.map(booking => renderBooking(booking, space, intl))}
            </div>
          </div>
        }
      >
        <div className="my-1 day-status">
          <span className={hourCss}>{startHour}</span>
          <div className={dayClassName} style={dayStyle} />
        </div>
      </ReactToolip>
      {last && (
        <div className="my-1 day-status">
          <span className={hourCss}>{endHour}</span>
        </div>
      )}
    </Fragment>
  );
};

export const getSpaceOpeningHours = (
  timeline: TimelineDate | null | undefined
): SpaceOpeningHours => {
  if (!timeline) {
    return {
      openingHours: [],
      closingHours: [],
      timeInterval: null,
    };
  }

  const { opening_hours, closing_hours } = timeline;
  const range = getMinMaxHoursRanges(opening_hours);

  return {
    openingHours: opening_hours,
    closingHours: closing_hours,
    timeInterval: range,
  };
};

interface Props {
  intl: IntlShape;
  space: SpaceTimeline;
  timelineDate: TimelineDate | undefined;
}

const TimeFrame = ({ timelineDate, space, intl }: Props): JSX.Element => {
  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  return (
    <>
      <div className="timeline-content flex flex-wrap">
        {timelineDate?.hours_status?.map((h, index, array) =>
          getHoursStatus(space, h, index, array, intl, centerTimezone)
        )}
      </div>
      {!timelineDate?.available && (
        <div className="flex flex-wrap text-red-500">
          <IntlMessages
            id={
              intl.messages[
                `page.list.bookings.not.bookable.reason.${timelineDate?.not_available_reason}`
              ]
                ? `page.list.bookings.not.bookable.reason.${timelineDate?.not_available_reason}`
                : "page.list.bookings.not.bookable.reason.no.reason"
            }
          />
        </div>
      )}
    </>
  );
};

export default injectIntl(TimeFrame);
