import { IntlShape } from "react-intl";
import { Order } from "../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../lib/ground-aws-graphql-core/models/OrderItem";

export const getOrderTotal = (order: Order): number =>
  order.price + order.totalTax;

export const getOrderItemTotal = (orderItem: OrderItem): number =>
  orderItem.totalPrice + orderItem.totalTax;

export enum EnumRefundReason {
  DUPLICATE = "duplicate",
  FRAUDULENT = "fraudulent",
  REQUESTED_BY_CUSTOMER = "requested_by_customer",
}

export interface ReasonInterface {
  value: EnumRefundReason;
  label: string;
}

export const getRefundReasons = (intl: IntlShape): ReasonInterface[] => {
  return [
    {
      value: EnumRefundReason.DUPLICATE,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.DUPLICATE}`,
      }),
    },
    {
      value: EnumRefundReason.FRAUDULENT,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.FRAUDULENT}`,
      }),
    },
    {
      value: EnumRefundReason.REQUESTED_BY_CUSTOMER,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.REQUESTED_BY_CUSTOMER}`,
      }),
    },
  ];
};

export const getRefundReason = (
  intl: IntlShape,
  reason: string
): ReasonInterface | undefined => {
  const item = getRefundReasons(intl).find(r => r.value === reason);

  return item;
};

export enum EnumIncidentStatus {
  NEW = 0,
  CLOSED = 1,
  CANCELED = 2,
}
