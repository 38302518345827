import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import IntlMessages from "../../../utils/messages";
import {
  EnumNotificationType,
  SearchableNotificationSortableFields,
  SearchableSortDirection,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { Notification } from "../../../lib/ground-aws-graphql-core/models/Notification";
import Table from "../../../components/Table";
import { getTranslation } from "../../../utils/translation";
import PageSubheader from "../../../components/PageSubheader";
import {
  displayDayDDMMYYYY_HHMM,
  getTodayInCenterTimezone,
} from "../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const NotificationsList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const { match, intl } = props;

  const searchNotifications = GroundGraphqlContextStore.useStoreActions(
    actions => actions.notification.searchNotifications
  );

  const notifications = GroundGraphqlContextStore.useStoreState(
    state => state.notification.notifications.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.notification.notifications.total
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const updateNotification = GroundGraphqlContextStore.useStoreActions(
    actions => actions.notification.updateNotification
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const onNotificationClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    notification: Notification
  ) => {
    e.preventDefault();

    const now = getTodayInCenterTimezone(centerTimezone);

    const getNow = () =>
      new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();

    if (me) {
      updateNotification({
        id: notification.id,
        viewDate: getNow(),
        notificationViewerId: me.id,
      }).finally(() => {
        goTo(notification);
      });
    } else {
      goTo(notification);
    }
  };

  const goToPage = (path: string, navItem: string, parentNavItem: string) => {
    localStorage.setItem("navItem", navItem);
    localStorage.setItem("parentNavItem", parentNavItem);
    history.push(path);
  };

  const goTo = (notification: Notification) => {
    const { type, entity } = notification;

    if (type === EnumNotificationType.ORDER) {
      try {
        const order = JSON.parse(entity);
        const { id } = order;

        if (id) {
          goToPage(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.ORDERS}/${id}`,
            EnumPaths.ORDERS,
            "pilot"
          );
        } else {
          goToPage(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.GLOBAL_ORDERS}`,
            EnumPaths.GLOBAL_ORDERS,
            "pilot"
          );
        }
      } catch (error) {
        goToPage(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.GLOBAL_ORDERS}`,
          EnumPaths.GLOBAL_ORDERS,
          "pilot"
        );
      }
    }
  };

  const getOperatorAttributeValue = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeValue.getOperatorAttributeValue
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (pageIndex = 0) => {
    setLoading(true);

    const promises = [
      searchNotifications({
        limit: LIMIT,
        filter: {
          notificationCenterId: { eq: match.params.cid },
          markForDelete: { eq: false },
        },
        sort: {
          field: SearchableNotificationSortableFields.CREATION_DATE,
          direction: SearchableSortDirection.desc,
        },
        from: pageIndex * LIMIT,
      }),
    ];
    if (me) {
      promises.push(
        getOperatorAttributeValue({
          operatorId: me.operator_id,
          attributeKeyName: "ONESIGNAL_BO_APP_ID",
        })
      );
    }
    await Promise.all(promises)
      .then(r => {
        if (r.length > 1) {
          const attrValue = r[1].data.data;
          if (attrValue && attrValue.value) {
            setNotificationEnabled(true);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const notificationsTableHead = [
    "general.type",
    "general.description",
    "general.date",
  ];

  const notificationsTableBody = notifications?.map(notification => {
    const { type, description, creationDate, viewDate } = notification;

    return {
      rowElements: [
        {
          element: <IntlMessages id={`page.list.notifications.type.${type}`} />,
        },
        { element: getTranslation(description) },
        {
          element: displayDayDDMMYYYY_HHMM(creationDate, centerTimezone),
        },
      ],
      onRowClick: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) =>
        onNotificationClick(e, notification),
      rowClassName: viewDate ? "" : "bg-gray-100",
    };
  });

  const handleCreateNotification = () => {
    goToPage(
      `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.NOTIFICATIONS}/${EnumPaths.CREATION_MODE}`,
      "notifications",
      "general"
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.notifications.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="general.notifications"
        nbOfResults={total}
        buttonRightTitle="page.notification.create.notification"
        buttonRightAction={
          notificationEnabled ? handleCreateNotification : undefined
        }
        buttonRightId="btn-create-notification"
      />

      <Table
        head={notificationsTableHead}
        body={notificationsTableBody}
        noDataText="page.list.notifications.empty"
        paginationTotal={total}
        paginationLimit={LIMIT}
        onChange={({ pageIndex }) => fetchData(pageIndex)}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />
    </>
  );
};

export default injectIntl(NotificationsList);
