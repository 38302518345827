import { createContextStore, persist } from "easy-peasy";
import { storeModel } from "../models";
import * as PaymentService from "../services/Payment";

export interface Injections {
  paymentService: typeof PaymentService;
}

export const contextStore = createContextStore(storeModel, {
  name: "Ground Payment Stripe Store",
  injections: {
    paymentService: PaymentService,
  },
});

export const contextStorePersist = storage =>
  createContextStore(
    persist(storeModel, {
      storage: storage,
    }),
    {
      name: "Ground Payment Stripe Store Persist",
      injections: {
        paymentService: PaymentService,
      },
    }
  );
