import { Token as token } from "../../store/provider";
import {
  CreateRefundOpts,
  CreateConnectedAccountOpts,
  CreateAccountLinksOpts,
  CreateRefundExternalizedOpts,
  RetryRefundExternalizedOpts,
} from "../../models/Payment";
import axiosLegacy from "../../../../axiosLegacy";
import axiosBackend from "../../../../axiosBackend";

/**
 * create a refund
 *
 * @return - A promise resolves create a refund
 */
export const createRefund = async (payload: CreateRefundOpts) => {
  const path = `/${payload.operatorName}/refund`;
  const url = process.env.REACT_APP_PAYMENT_API;

  return axiosLegacy.post(url + path, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

/**
 * create a refund with the payment externalization
 *
 * @return - A promise resolves create a refund
 */
export const createRefundExternalized = async (
  payload: CreateRefundExternalizedOpts
) => {
  let path = `/global_orders/${payload.globalOrderId}/orders/${payload.orderId}`;

  if (payload.orderItemId) path += `/order_items/${payload.orderItemId}`;

  path += "/refund_request";

  let body = {};
  if (payload.reason) {
    body = { reason: payload.reason };
  }

  return axiosBackend.post(path, body);
};

/**
 * retry a refund request with the payment externalization
 *
 * @return - A promise resolves retry a refund request
 */
export const retryRefundExternalized = async (
  payload: RetryRefundExternalizedOpts
) => {
  return axiosBackend.post(`/webhook_events/${payload.id}`);
};

/**
 * create connected account
 *
 * @return - A promise resolves create connected account
 */
export const createConnectedAccount = async (
  payload: CreateConnectedAccountOpts
) => {
  const path = `/${payload.operatorName}/accounts/${payload.id}`;
  const url = process.env.REACT_APP_PAYMENT_API;

  return axiosLegacy.post(
    url + path,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

/**
 * create account links
 *
 * @return - A promise resolves create account links
 */
export const createAccountLinks = async (payload: CreateAccountLinksOpts) => {
  const path = `/${payload.operatorName}/accounts/links/${payload.id}`;
  const url = process.env.REACT_APP_PAYMENT_API;

  return axiosLegacy.post(
    url + path,
    {
      refreshUrl: payload.refreshUrl,
      returnUrl: payload.returnUrl,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};
