import { IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import {
  EnumBackOfficeUserRole,
  EnumChannel,
  EnumUserRole,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { User } from "../../lib/ground-aws-graphql-core/models/User";

export type UserStatusType =
  | "UNCONFIRMED"
  | "CONFIRMED"
  | "ARCHIVED"
  | "COMPROMISED"
  | "UNKNOWN"
  | "RESET_REQUIRED"
  | "EXTERNAL_PROVIDER"
  | "FORCE_CHANGE_PASSWORD";

export const UserStatus = {
  UNCONFIRMED: "UNCONFIRMED",
  CONFIRMED: "CONFIRMED",
  ARCHIVED: "ARCHIVED",
  COMPROMISED: "COMPROMISED",
  UNKNOWN: "UNKNOWN",
  RESET_REQUIRED: "RESET_REQUIRED",
  EXTERNAL_PROVIDER: "EXTERNAL_PROVIDER",
  FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
};

export const EnumUserStatus = {
  UNCONFIRMED: "UNCONFIRMED",
  CONFIRMED: "CONFIRMED",
};

export const getRoleLabel = (
  intl: IntlShape,
  role: EnumUserRole | EnumBackOfficeUserRole | undefined
): string => {
  const label = intl.formatMessage({ id: `general.role.${role}` });

  return label;
};

export const getRole = (role: string): EnumBackOfficeUserRole | undefined => {
  const backOfficeUserRoles = $enum(EnumBackOfficeUserRole).getValues();
  const r1 = backOfficeUserRoles.find(e => e === role);

  return r1;
};

export const getRoleBadgeElements = (
  role: EnumUserRole | EnumBackOfficeUserRole | undefined
): { backgroundColor: string; textColor: string } => {
  switch (role) {
    case EnumUserRole.User:
      return {
        backgroundColor: "bg-ground-blue-200",
        textColor: "text-white",
      };
    case EnumUserRole.Manager:
      return {
        backgroundColor: "bg-yellow-100",
        textColor: "text-black-100",
      };
    case EnumBackOfficeUserRole.CenterAdmin:
      return {
        backgroundColor: "bg-ground-blue-200",
        textColor: "text-white",
      };
    case EnumBackOfficeUserRole.OperatorAdmin:
      return {
        backgroundColor: "bg-yellow-100",
        textColor: "text-black-100",
      };
    default:
      return {
        backgroundColor: "bg-red-500",
        textColor: "text-white",
      };
      break;
  }
};

export const retrieveUserFromOrder = (
  user: User | undefined,
  metadata: string | undefined,
  channel: EnumChannel | undefined
): { client: string | undefined; email: string | undefined } => {
  let client = user && `${user.lastname?.toUpperCase()} ${user.firstname}`;
  let email = user && `${user.email}`;

  const orderMetadata = metadata ? JSON.parse(metadata) : {};

  if ((channel === EnumChannel.BACK_OFFICE || !user) && orderMetadata?.user) {
    client = `${orderMetadata.user?.lastname?.toUpperCase()} ${
      orderMetadata.user?.firstname
    }`;
    email = orderMetadata.user?.email;
  }

  return { client, email };
};
