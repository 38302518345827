import React from "react";
import classnames from "classnames";
import images from "../../../images";
import ImageInput from "../ImageInput";
import { ActionTypes, Image } from "../../../utils/types";
import ImageComponent from "./Image";
import IntlMessages from "../../../utils/messages";
import { getCypressTestId } from "../../../utils/config";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import { News } from "../../../lib/ground-aws-graphql-core/models/News";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";
import { Service } from "../../../lib/ground-aws-graphql-core/models/Service";
import { Provider } from "../../../lib/ground-aws-graphql-core/models/Provider";
import { Event } from "../../../lib/ground-aws-graphql-core/models/Event";

interface Props {
  entity: Product | Provider | Center | Service | Event | News | null;
  label: string;
  images: Image[];
  max: number;
  index: number;
  last?: boolean;
  onAddImage: (result: string | ArrayBuffer, file: File) => void;
  onRemoveImage?: (el: Image) => void;
  alternate?: boolean;
}

const ImageGallery = (props: Props): JSX.Element => {
  const {
    alternate,
    last,
    images: galleryImages,
    index,
    label,
    max,
    entity,
    onAddImage,
    onRemoveImage,
  } = props;

  const className = (id: number, value: string) =>
    classnames(value, {
      "bg-ground-white-200": alternate ?? id % 2 === 0,
      "rounded-b-10": last,
    });

  const pictures = galleryImages.filter(
    e => e.action !== ActionTypes.TO_DELETE
  );

  return (
    <>
      <div className={className(index, "sm:py-4 px-6")}>
        <div className="flex items-center">
          <span className="block text-16px text-ground-black-100">
            <IntlMessages id={label} />
          </span>
          <span className="ml-1 text-16px text-ground-gray-100">
            <IntlMessages id="general.image.gallery.max" values={{ max }} />
          </span>
        </div>

        <span className="text-ground-gray-100 text-14px leading-5">
          <IntlMessages id="general.image.max.size" />
        </span>
      </div>
      <div
        className={className(
          index,
          "sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start sm:py-2 px-6"
        )}
      >
        {pictures && pictures.length < max && (
          <ImageInput
            item={entity}
            label="general.add"
            onAddImage={onAddImage}
            className="mt-2 sm:mt-0 hover:bg-gray-200"
          />
        )}
        {pictures.map((image, i) => {
          return (
            <div key={i} className="relative mt-2 sm:mt-0">
              <ImageComponent image={image} width={200} height={140} />
              {onRemoveImage && (
                <img
                  id={image.picture || undefined}
                  data-cy={image.picture}
                  data-testid={getCypressTestId(entity)}
                  src={images.trash}
                  alt="trash"
                  className="absolute cursor-pointer right-2 top-2 bg-white border border-red-500 opacity-100 p-1 rounded-5"
                  onClick={() => {
                    if (onRemoveImage) {
                      onRemoveImage(image);
                    }
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImageGallery;
