export const getBackOfficeUser = /* GraphQL */ `
  query GetBackOfficeUser($id: ID!) {
    getBackOfficeUser(id: $id) {
      id
      firstname
      lastname
      email
      phone
      role
      picture
      enabled
      markForDelete
      userPoolId
      identityPoolId
      tier
      clientId
      username
      shortname
      locale
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      email
      phone
      enterprise {
        id
        name
      }
      priceUnitVariation {
        id
        name
      }
      paymentMethods {
        items {
          id
          code
          label
        }
      }
      attributes {
        items {
          id
          key {
            id
            name
            type
            description
            picture
            enabled
            markForDelete
            position
            typology
            system
            required
            roles
            unit
            metadata
            createdAt
            updatedAt
          }
          booleanValue
          stringValue
          value
          jsonValue
          numberValue
          urlValue
          dateValue
          selectValue
          enabled
          markForDelete
          createdAt
          updatedAt
        }
      }
      role
      picture
      enabled
      markForDelete
      userPoolId
      identityPoolId
      tier
      clientId
      username
      shortname
      locale
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const getCenter = /* GraphQL */ `
  query GetCenter($id: ID!) {
    getCenter(id: $id) {
      operator {
        id
      }
      id
      type {
        id
        type
        name
        enabled
        markForDelete
        createdAt
        updatedAt
      }
      name
      description
      address
      zipCode
      city
      country
      location {
        lat
        lon
      }
      timezone
      pictures
      attributes {
        items {
          id
          key {
            id
            name
            type
            description
            picture
            enabled
            markForDelete
            position
            typology
            system
            required
            roles
            unit
            metadata
            createdAt
            updatedAt
          }
          booleanValue
          stringValue
          value
          jsonValue
          numberValue
          urlValue
          dateValue
          selectValue
          enabled
          markForDelete
          createdAt
          updatedAt
        }
      }
      daySlots {
        items {
          id
          day
          name
          markForDelete
          hoursRange {
            items {
              id
              start
              end
              name
              markForDelete
              createdAt
              updatedAt
            }
          }
        }
      }
      closedDays {
        items {
          id
          start
          end
          markForDelete
          createdAt
          updatedAt
        }
      }
      exceptionalOpeningHours {
        items {
          id
          start
          end
          name
          enabled
          startAt
          endAt
        }
      }
      enabled
      markForDelete
      phone
      email
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const listCenterTypes = /* GraphQL */ `
  query ListCenterTypes {
    listCenterTypes {
      items {
        id
        type
        name
        enabled
        markForDelete
        createdAt
        updatedAt
      }
    }
  }
`;

export const getEnterprise = /* GraphQL */ `
  query GetEnterprise($id: ID!) {
    getEnterprise(id: $id) {
      id
      name
      siret
      paymentOnInvoice
      markForDelete
      createdAt
      updatedAt
      users {
        id
        firstname
        lastname
        email
        phone
        role
        status
        picture
        enabled
        markForDelete
        userPoolId
        identityPoolId
        tier
        clientId
        username
        shortname
        locale
        metadata
        createdAt
        updatedAt
      }
      totalUsers
    }
  }
`;
export const listEnterprises = /* GraphQL */ `
  query ListEnterprises {
    listEnterprises {
      items {
        id
        name
        siret
        paymentOnInvoice
        markForDelete
        createdAt
        updatedAt
        totalUsers
      }
      total
    }
  }
`;
export const listEnterprisePrivatizations = /* GraphQL */ `
  query ListEnterprisePrivatizations($enterpriseId: ID!) {
    listEnterprisePrivatizations(enterpriseId: $enterpriseId) {
      items {
        id
        start
        end
        product {
          id
          type
          sku
          name
          typology
          description
          pictures
          vat
          capacity
          stock
          checkStock
          authorized
          enabled
          markForDelete
          flexible
          isOption
          inCatalog
          center {
            id
            name
            description
            address
            zipCode
            city
            country
            pictures
          }
        }
        markForDelete
        createdAt
        updatedAt
      }
    }
  }
`;

export const getPrivatization = /* GraphQL */ `
  query GetPrivatization($id: ID!) {
    getPrivatization(id: $id) {
      id
      start
      end
      product {
        id
        type
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        checkStock
        authorized
        enabled
        markForDelete
        flexible
        isOption
        inCatalog
      }
      enterprise {
        id
        name
      }
      markForDelete
      createdAt
      updatedAt
    }
  }
`;

export const listProductPrivatizations = /* GraphQL */ `
  query ListProductPrivatizations($productId: ID!) {
    listProductPrivatizations(productId: $productId) {
      items {
        id
        start
        end
        enterprise {
          id
          name
        }
        product {
          id
          type
          sku
          name
          typology
          description
          pictures
          vat
          capacity
          stock
          checkStock
          authorized
          enabled
          markForDelete
          flexible
          isOption
          inCatalog
        }
        markForDelete
        createdAt
        updatedAt
      }
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      type
      name
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      products {
        items {
          id
          name
          pictures
          flexible
          isOption
          inCatalog
          category {
            id
            type
            name
            description
            picture
            level
            position
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
          }
          priceUnits {
            items {
              id
              unit
              currency
              increment
              price
              variation {
                id
                key
                name
                enabled
                markForDelete
                metadata
                createdAt
                updatedAt
              }
              minQuantity
              maxQuantity
              enabled
              markForDelete
              buyableUnit
              buyableDescription
              flexible
              minApprovalMinutes
              minCancelMinutes
              metadata
              createdAt
              updatedAt
            }
          }
          attributes {
            items {
              id
              key {
                id
                name
                type
                description
                picture
                enabled
                markForDelete
                position
                typology
                system
                required
                roles
                unit
                metadata
                createdAt
                updatedAt
              }
              booleanValue
              stringValue
              value
              jsonValue
              numberValue
              urlValue
              dateValue
              selectValue
              enabled
              markForDelete
              createdAt
              updatedAt
            }
          }
          options {
            items {
              id
              product {
                id
                type
                sku
                name
                typology
                description
                pictures
                vat
                capacity
                stock
                checkStock
                authorized
                enabled
                markForDelete
                flexible
                isOption
                inCatalog
                metadata
                createdAt
                updatedAt
              }
            }
          }
          provider {
            id
            name
            description
            address
            zipCode
            city
            country
            phone
            email
            pictures
            authorized
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
          }
          products {
            items {
              id
              product {
                id
                type
                sku
                name
                typology
                description
                pictures
                vat
                capacity
                stock
                checkStock
                authorized
                enabled
                markForDelete
                flexible
                isOption
                inCatalog
                provider {
                  id
                  name
                  description
                  address
                  zipCode
                  city
                  country
                  phone
                  email
                  pictures
                  authorized
                  enabled
                  markForDelete
                  metadata
                  createdAt
                  updatedAt
                }
                metadata
                createdAt
                updatedAt
              }
            }
          }
          enabled
          markForDelete
          metadata
          createdAt
          updatedAt
        }
      }
      attributes {
        items {
          id
          key {
            id
            name
            type
            description
            picture
            enabled
            markForDelete
            position
            typology
            system
            required
            roles
            unit
            metadata
            createdAt
            updatedAt
          }
          booleanValue
          stringValue
          value
          jsonValue
          numberValue
          urlValue
          dateValue
          selectValue
          enabled
          markForDelete
          createdAt
          updatedAt
        }
      }
      authorized
      enabled
      markForDelete
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories($filter: ModelCategoryFilterInput, $limit: Int) {
    listCategories(filter: $filter, limit: $limit) {
      items {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        parent {
          id
          type
          name
          description
          picture
          level
          position
          enabled
          markForDelete
          metadata
        }
        categories {
          items {
            id
            type
            name
            description
            picture
            level
            position
            enabled
            markForDelete
            metadata
            parent {
              id
              type
              name
              description
              picture
              level
              position
              enabled
              markForDelete
              metadata
            }
          }
        }
      }
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      name
      description
      address
      zipCode
      city
      country
      phone
      email
      pictures
      attributes {
        items {
          id
          key {
            id
            name
            type
            description
            picture
            enabled
            markForDelete
            position
            typology
            system
            required
            roles
            unit
            metadata
            createdAt
            updatedAt
          }
          booleanValue
          stringValue
          value
          jsonValue
          numberValue
          urlValue
          dateValue
          selectValue
          enabled
          markForDelete
          createdAt
          updatedAt
        }
      }
      enabled
      receivingEmails
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      productOperatorId
      center {
        id
        name
        description
        address
        zipCode
        city
        country
        pictures
        enabled
        markForDelete
        phone
        email
        metadata
        createdAt
        updatedAt
      }
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      priceUnits {
        id
        unit
        currency
        increment
        price
        variation {
          id
          key
          name
          enabled
          markForDelete
          metadata
          createdAt
          updatedAt
        }
        minQuantity
        maxQuantity
        enabled
        markForDelete
        buyableUnit
        buyableDescription
        flexible
        minApprovalMinutes
        minCancelMinutes
        createdAt
        updatedAt
      }
      provider {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      vat
      capacity
      stock
      checkStock
      authorized
      enabled
      markForDelete
      flexible
      isOption
      inCatalog
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const getProductOnly = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      type
      sku
      name
      typology
      description
      pictures
      vat
      capacity
      stock
      checkStock
      authorized
      enabled
      markForDelete
      flexible
      isOption
      inCatalog
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const getGlobalOrder = /* GraphQL */ `
  query GetGlobalOrder($id: ID!) {
    getGlobalOrder(id: $id) {
      id
      ormOrder
      user {
        id
        firstname
        lastname
        email
      }
      touchPoint
      orders {
        items {
          id
          ormOrder
          channel
          orderItems {
            items {
              id
              product
              sku
              name
              status
              description
              pictures
              category
              provider
              totalPrice
              totalTax
              totalAdjustment
              price
              currency
              unit
              increment
              unitQuantity
              unitPrice
              unitTax
              unitAdjustment
              quantity
              priceUnit
              variation
              unavailability {
                id
                type
                start
                end
                status
                relatedUnavailabilityId
                locale
                metadata
                createdAt
                updatedAt
              }
              vat
              refunded
              relatedOrderItemId
              refund {
                id
                refundId
                amount
                date
                status
                reason
                createdAt
                updatedAt
              }
              locale
              metadata
              createdAt
              updatedAt
            }
          }
          provider {
            id
            name
          }
          payment {
            id
            paymentIntentId
            paymentMethodDetails
          }
        }
      }
      price
      totalTax
      totalAdjustment
      currency
      channel
      timeplaced
      status
      locale
      metadata
      comment
      createdAt
      updatedAt
    }
  }
`;

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      ormOrder
      touchPoint
      user {
        id
        firstname
        lastname
        email
      }
      globalOrder {
        id
        ormOrder
        channel
      }
      orderItems {
        items {
          id
          product
          sku
          name
          status
          description
          pictures
          category
          provider
          totalPrice
          totalTax
          totalAdjustment
          price
          currency
          unit
          increment
          unitQuantity
          unitPrice
          unitTax
          unitAdjustment
          quantity
          priceUnit
          variation
          unavailability {
            id
            type
            start
            end
            status
            relatedUnavailabilityId
            locale
            metadata
            createdAt
            updatedAt
          }
          vat
          refunded
          relatedOrderItemId
          refund {
            id
            refundId
            amount
            date
            status
            reason
            createdAt
            updatedAt
          }
          locale
          metadata
          createdAt
          updatedAt
        }
      }
      price
      totalTax
      totalAdjustment
      transactionFeePercent
      currency
      channel
      timeplaced
      deliveryDate
      status
      provider {
        id
        name
      }
      payment {
        id
        paid
        refunded
        captured
        amountCaptured
        amountRefunded
        amountRemaining
        amountTransfered
        currency
        captureDate
        paymentDate
        charge
        paymentChargeId
        intent
        paymentIntentId
        paymentMethodId
        paymentMethodDetails
        receiptUrl
        status
        metadata
        createdAt
        updatedAt
      }
      locale
      metadata
      reason
      comment
      createdAt
      updatedAt
    }
  }
`;

export const getOrderItems = /* GraphQL */ `
  query GetOrderItems($orderId: ID!) {
    getOrderItems(orderId: $orderId) {
      items {
        id
        product
        sku
        name
        status
        description
        pictures
        category
        provider
        totalPrice
        totalTax
        totalAdjustment
        price
        currency
        unit
        increment
        unitQuantity
        unitPrice
        unitTax
        unitAdjustment
        quantity
        priceUnit
        variation
        vat
        refunded
        relatedOrderItemId
        refund {
          id
          refundId
          amount
          date
          status
          reason
        }
      }
      total
    }
  }
`;
export const searchAllPriceUnitVariations = /* GraphQL */ `
  query SearchAllPriceUnitVariations(
    $filter: SearchablePriceUnitVariationFilterInput
    $from: Int
    $limit: Int
  ) {
    searchAllPriceUnitVariations(filter: $filter, from: $from, limit: $limit) {
      items {
        id
        key
        name
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      picture
      start
      end
      publicationDate
      unpublicationDate
      type {
        id
        type
        name
        enabled
      }
      enabled
    }
  }
`;
export const listEventTypes = /* GraphQL */ `
  query ListEventTypes($filter: SearchableEventTypeFilterInput) {
    listEventTypes(filter: $filter) {
      items {
        id
        type
        name
        enabled
        markForDelete
      }
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      title
      description
      picture
      publicationDate
      unpublicationDate
      enabled
      markForDelete
      createdAt
      updatedAt
      category {
        id
        name
      }
    }
  }
`;
export const searchBackOfficeUsers = /* GraphQL */ `
  query SearchBackOfficeUsers(
    $filter: SearchableBackOfficeUserFilterInput
    $limit: Int
    $from: Int
  ) {
    searchBackOfficeUsers(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        firstname
        lastname
        email
        phone
        role
        status
        picture
        enabled
        markForDelete
        userPoolId
        identityPoolId
        tier
        clientId
        username
        shortname
        locale
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchAuthorizations = /* GraphQL */ `
  query SearchAuthorizations(
    $filter: SearchableAuthorizationFilterInput
    $limit: Int
    $from: Int
  ) {
    searchAuthorizations(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        entityType
        entityId
        createdAt
        updatedAt
        center {
          id
          name
          description
          address
          zipCode
          city
          country
          pictures
          enabled
          markForDelete
          phone
          email
          createdAt
          updatedAt
        }
        user {
          email
          status
          enabled
          username
          lastname
          firstname
          id
          picture
        }
      }
      total
    }
  }
`;
export const searchAllAuthorizations = /* GraphQL */ `
  query SearchAllAuthorizations(
    $filter: SearchableAuthorizationFilterInput
    $limit: Int
    $from: Int
  ) {
    searchAllAuthorizations(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        entityType
        entityId
        createdAt
        updatedAt
        center {
          id
          name
          description
          address
          zipCode
          city
          country
          pictures
          enabled
          markForDelete
          phone
          email
          createdAt
          updatedAt
        }
        user {
          email
          status
          enabled
          username
          lastname
          firstname
          id
          picture
        }
      }
      total
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $limit: Int
    $from: Int
  ) {
    searchUsers(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        firstname
        lastname
        email
        phone
        enterprise {
          id
          name
        }
        role
        status
        picture
        enabled
        markForDelete
        tier
        username
        shortname
        locale
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const exportUsers = /* GraphQL */ `
  query ExportUsers(
    $filter: SearchableUserFilterInput
    $limit: Int
    $from: Int
  ) {
    exportUsers(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        firstname
        lastname
        email
        phone
        locale
      }
      total
    }
  }
`;
export const exportAdministrators = /* GraphQL */ `
  query ExportAdministrators(
    $filter: SearchableBackOfficeUserFilterInput
    $limit: Int
    $from: Int
  ) {
    exportAdministrators(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        firstname
        lastname
        email
        role
        locale
      }
      total
    }
  }
`;
export const searchCenters = /* GraphQL */ `
  query SearchCenters(
    $filter: SearchableCenterFilterInput
    $limit: Int
    $from: Int
    $locale: EnumLocaleDB
    $sort: SearchableCenterSortInput
  ) {
    searchCenters(
      filter: $filter
      limit: $limit
      from: $from
      locale: $locale
      sort: $sort
    ) {
      items {
        id
        name
        description
        address
        zipCode
        city
        country
        pictures
        enabled
        markForDelete
        phone
        email
        type {
          id
          type
          name
        }
        timezone
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchAllCenters = /* GraphQL */ `
  query SearchAllCenters(
    $filter: SearchableCenterFilterInput
    $limit: Int
    $from: Int
    $locale: EnumLocaleDB
    $sort: SearchableCenterSortInput
  ) {
    searchAllCenters(
      filter: $filter
      limit: $limit
      from: $from
      locale: $locale
      sort: $sort
    ) {
      items {
        id
        name
        timezone
      }
      total
    }
  }
`;
export const searchAllCategories = /* GraphQL */ `
  query SearchAllCategories(
    $filter: SearchableCategoryFilterInput
    $limit: Int
    $locale: EnumLocaleDB
    $sort: SearchableCategorySortInput
  ) {
    searchAllCategories(
      filter: $filter
      limit: $limit
      sort: $sort
      locale: $locale
    ) {
      items {
        id
        type
        name
        description
        picture
        level
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
        center {
          id
        }
        parent {
          id
          type
          name
          description
          picture
          level
          position
          enabled
          markForDelete
          metadata
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            type
            name
            description
            picture
            level
            position
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
            parent {
              id
              type
              name
              description
              picture
              level
              position
              enabled
              markForDelete
              metadata
              createdAt
              updatedAt
            }
          }
        }
      }
      total
    }
  }
`;
export const searchAllServices = /* GraphQL */ `
  query SearchAllServices($filter: SearchableServiceFilterInput, $limit: Int) {
    searchAllServices(filter: $filter, limit: $limit) {
      items {
        id
        type
        name
        description
        pictures
        authorized
        enabled
        markForDelete
        products {
          items {
            id
            type
            sku
            name
            typology
            description
            pictures
            vat
            capacity
            stock
            checkStock
            authorized
            enabled
            markForDelete
            flexible
            isOption
            inCatalog
            metadata
            provider {
              markForDelete
            }
            createdAt
            updatedAt
          }
        }
        category {
          id
          type
          name
          description
          picture
          level
          position
          enabled
          markForDelete
          metadata
          createdAt
          updatedAt
          parent {
            id
            type
            name
            description
            picture
            level
            position
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
          }
        }
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchProviders = /* GraphQL */ `
  query SearchProviders(
    $filter: SearchableProviderFilterInput
    $sort: SearchProvidersSortInput
    $limit: Int
    $from: Int
    $locale: EnumLocaleDB
  ) {
    searchProviders(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
      locale: $locale
    ) {
      items {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchAllProviders = /* GraphQL */ `
  query SearchAllProviders(
    $filter: SearchableProviderFilterInput
    $sort: SearchProvidersSortInput
    $limit: Int
    $from: Int
    $locale: EnumLocaleDB
  ) {
    searchAllProviders(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
      locale: $locale
    ) {
      items {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchSpacesBookings = /* GraphQL */ `
  query SearchSpacesBookings(
    $filter: SearchableSpacesBookingsFilterInput!
    $limit: Int
    $from: Int
  ) {
    searchSpacesBookings(filter: $filter, limit: $limit, from: $from) {
      total
      items {
        id
        flexible
        combined
        capacity
        enabled
        name
        pictures
        typology
        calendar {
          items {
            date
            formatted_date
            status
            privatized
            indicators {
              bookings {
                id
                start
                end
                type
                order {
                  id
                  ormOrder
                }
                product {
                  id
                  name
                }
              }
              occupancy_rate
              total_remaining_places
            }
          }
        }
      }
    }
  }
`;

export const searchSpaceDateBookings = /* GraphQL */ `
  query SearchSpaceDateBookings($productId: ID!, $date: String!) {
    searchSpaceDateBookings(productId: $productId, date: $date) {
      bookable
      bookings {
        total
        items {
          id
          start
          end
          participants
          type
          order {
            id
            ormOrder
          }
          product {
            id
            name
          }
          user {
            firstname
            lastname
          }
        }
      }
      privatizable
      privatizations {
        id
        start
        end
        enterprise {
          id
          name
        }
      }
    }
  }
`;

export const searchSpaceTimelines = /* GraphQL */ `
  query SearchSpaceTimelines(
    $buildingId: ID!
    $date: String!
    $from: Int
    $limit: Int
    $filter: SearchableSpaceTimelineFilterInput
  ) {
    searchSpaceTimelines(
      buildingId: $buildingId
      date: $date
      from: $from
      limit: $limit
      filter: $filter
    ) {
      items {
        id
        name
        typology
        capacity
        flexible
        enabled
        pictures
        combined
        timeline_dates {
          date
          formatted_date
          available
          not_available_reason
          closed
          hours_status {
            start
            end
            formatted_start
            formatted_end
            status
            reason
            indicators {
              bookings {
                id
                start
                end
                type
                order {
                  id
                  ormOrder
                }
                product {
                  id
                  name
                }
              }
              occupancy_rate
              total_remaining_places
            }
          }
          opening_hours {
            start
            end
          }
          closing_hours {
            start
            end
          }
        }
      }
      total
    }
  }
`;

export const getSpaceTimeline = /* GraphQL */ `
  query GetSpaceTimeline(
    $id: ID!
    $startDate: String!
    $endDate: String
    $granularity: Float
  ) {
    getSpaceTimeline(
      id: $id
      startDate: $startDate
      endDate: $endDate
      granularity: $granularity
    ) {
      id
      name
      typology
      capacity
      flexible
      enabled
      pictures
      combined
      timeline_dates {
        date
        formatted_date
        available
        not_available_reason
        closed
        hours_status {
          start
          end
          formatted_start
          formatted_end
          status
          reason
          indicators {
            bookings {
              id
              start
              end
              type
              order {
                id
                ormOrder
              }
              product {
                id
                name
              }
            }
            occupancy_rate
            total_remaining_places
          }
        }
        opening_hours {
          start
          end
        }
        closing_hours {
          start
          end
        }
      }
    }
  }
`;

export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $buildingId: ID!
    $filter: SearchableProductsFilterInput!
    $limit: Int
    $from: Int
  ) {
    searchProducts(
      buildingId: $buildingId
      filter: $filter
      limit: $limit
      from: $from
    ) {
      items {
        id
        type
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        checkStock
        authorized
        enabled
        markForDelete
        flexible
        isOption
        inCatalog
        center {
          id
          name
        }
        category {
          id
          name
        }
        provider {
          id
          name
          enabled
          markForDelete
        }
        service {
          id
          name
          type
          category {
            id
            name
          }
        }
        priceUnits {
          items {
            id
            unit
            currency
            price
            enabled
            markForDelete
            variation {
              id
              key
              name
              enabled
              markForDelete
            }
          }
        }
        attributes {
          items {
            id
            key {
              id
              name
              type
            }
            booleanValue
            stringValue
            value
            jsonValue
            numberValue
            urlValue
            dateValue
            selectValue
            enabled
          }
        }
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const searchSpaces = /* GraphQL */ `
  query SearchSpaces(
    $filter: SearchableSpaceFilterInput
    $limit: Int
    $from: Int
  ) {
    searchSpaces(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        checkStock
        enabled
        markForDelete
        flexible
        isOption
        inCatalog
        center {
          id
          name
        }
        provider {
          id
          name
          enabled
        }
        priceUnits {
          items {
            id
            unit
            currency
            price
          }
        }
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const searchWorkSpaces = /* GraphQL */ `
  query SearchWorkSpace(
    $filter: SearchableSpaceFilterInput
    $limit: Int
    $from: Int
  ) {
    searchSpaces(filter: $filter, limit: $limit, from: $from) {
      items {
        id
        type
        sku
        name
        typology
        description
        pictures
        vat
        capacity
        stock
        checkStock
        authorized
        enabled
        markForDelete
        flexible
        isOption
        inCatalog
        center {
          id
          name
        }
        category {
          id
          name
        }
        privatizations(current: true) {
          id
          start
          end
          enterprise {
            id
            name
          }
        }
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchAllAttributeKeys = /* GraphQL */ `
  query SearchAllAttributeKeys(
    $filter: SearchableAttributeKeyFilterInput
    $limit: Int
  ) {
    searchAllAttributeKeys(filter: $filter, limit: $limit) {
      items {
        id
        name
        attributeKeyCategoryId
        type
        description
        picture
        enabled
        markForDelete
        position
        typology
        system
        required
        roles
        unit
        metadata
        createdAt
        updatedAt
        category {
          id
          name
          level
          enabled
          parent {
            id
            name
            level
            enabled
          }
        }
      }
      total
    }
  }
`;
export const searchUnavailabilities = /* GraphQL */ `
  query SearchUnavailabilities(
    $filter: SearchableUnavailabilityFilterInput!
    $sort: SearchableUnavailabilitySortInput
    $limit: Int
    $from: Int
  ) {
    searchUnavailabilities(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
    ) {
      items {
        id
        type
        start
        end
        status
        relatedUnavailabilityId
        locale
        metadata
        createdAt
        updatedAt
        user {
          id
          firstname
          lastname
        }
        product {
          id
          name
          pictures
        }
        orderItem {
          id
          quantity
          order {
            id
            channel
            metadata
            reason
            globalOrder {
              id
              ormOrder
            }
            orderItems {
              items {
                id
                name
                unitQuantity
                relatedOrderItemId
              }
            }
          }
        }
      }
      total
    }
  }
`;
export const searchGlobalOrders = /* GraphQL */ `
  query SearchGlobalOrders(
    $filter: SearchableGlobalOrderFilterInput!
    $sort: SearchableGlobalOrderSortInput
    $limit: Int
    $from: Int
  ) {
    searchGlobalOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
    ) {
      items {
        id
        user {
          id
          firstname
          lastname
        }
        orders {
          items {
            id
            payment {
              paymentMethodDetails
            }
            orderItems {
              items {
                id
                name
                quantity
                currency
                totalPrice
                totalTax
                status
                unit
                increment
                unitQuantity
                unitPrice
                unitTax
                unitAdjustment
                quantity
                relatedOrderItemId
                refund {
                  id
                  refundId
                  amount
                  date
                  status
                  reason
                }
                unavailability {
                  id
                  start
                  end
                }
              }
            }
            provider {
              id
              name
            }
            ormOrder
            price
            totalTax
            totalAdjustment
            transactionFeePercent
            currency
            channel
            timeplaced
            deliveryDate
            status
            locale
            metadata
            reason
            comment
            createdAt
            updatedAt
          }
        }
        ormOrder
        price
        totalTax
        totalAdjustment
        currency
        channel
        timeplaced
        status
        locale
        metadata
        comment
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $limit: Int
    $from: Int
    $sort: SearchableEventSortInput
    $locale: EnumLocaleDB
  ) {
    searchEvents(
      filter: $filter
      limit: $limit
      from: $from
      sort: $sort
      locale: $locale
    ) {
      items {
        id
        title
        type {
          id
          type
          name
          enabled
          markForDelete
          createdAt
          updatedAt
        }
        description
        picture
        start
        end
        publicationDate
        unpublicationDate
        authorized
        enabled
        markForDelete
        author {
          id
          firstname
          lastname
        }
        participations {
          items {
            id
            value
          }
          total
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchParticipations = /* GraphQL */ `
  query SearchParticipations(
    $filter: SearchableParticipationFilterInput
    $limit: Int
    $from: Int
    $sort: SearchableParticipationSortInput
  ) {
    searchParticipations(
      filter: $filter
      limit: $limit
      from: $from
      sort: $sort
    ) {
      items {
        id
        value
        user {
          id
          firstname
          lastname
          email
          picture
          role
          enabled
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchNews = /* GraphQL */ `
  query SearchNews(
    $filter: SearchableNewsFilterInput
    $limit: Int
    $from: Int
    $sort: SearchableNewsSortInput
    $locale: EnumLocaleDB
  ) {
    searchNews(
      filter: $filter
      limit: $limit
      from: $from
      sort: $sort
      locale: $locale
    ) {
      items {
        id
        title
        description
        picture
        publicationDate
        unpublicationDate
        authorized
        enabled
        markForDelete
        author {
          id
          firstname
          lastname
          email
          phone
          role
          picture
          enabled
          markForDelete
          userPoolId
          identityPoolId
          tier
          clientId
          username
          shortname
          locale
          metadata
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchNotificationsFilterInput
    $sort: SearchNotificationsSortInput
    $limit: Int
    $from: Int
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      from: $from
    ) {
      items {
        id
        type
        entity
        description
        enabled
        markForDelete
        creationDate
        viewDate
        locale
        metadata
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const listProductOptions = /* GraphQL */ `
  query ListProductOptions($productId: ID!) {
    listProductOptions(productId: $productId) {
      items {
        id
        product {
          id
          type
          sku
          name
          typology
          description
          pictures
          vat
          capacity
          stock
          checkStock
          authorized
          enabled
          markForDelete
          flexible
          isOption
          inCatalog
          service {
            category {
              name
            }
          }
          attributes {
            items {
              id
              key {
                id
                name
                type
                description
                picture
                enabled
                markForDelete
                position
                typology
                system
                required
                roles
                unit
                metadata
                createdAt
                updatedAt
              }
              booleanValue
              stringValue
              value
              jsonValue
              numberValue
              urlValue
              dateValue
              selectValue
              enabled
              markForDelete
              createdAt
              updatedAt
            }
          }
          provider {
            id
            name
            authorized
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const searchDaySlots = /* GraphQL */ `
  query SearchDaySlots(
    $filter: SearchableDaySlotFilterInput
    $from: Int
    $limit: Int
  ) {
    searchDaySlots(filter: $filter, from: $from, limit: $limit) {
      items {
        id
        day
        dayBreak
        name
        hoursRange {
          items {
            id
            start
            end
            name
            markForDelete
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const listAssetReportings = /* GraphQL */ `
  query ListAssetReportings($filter: ListAssetReportingsFilterInput!) {
    listAssetReportings(filter: $filter) {
      items {
        date
        indicators {
          bookings {
            id
            start
            end
            type
          }
          occupancy_rate
          spaces {
            space {
              id
              name
            }
            indicators {
              occupancy_rate
            }
          }
        }
        data {
          typology
          total_spaces
          indicators {
            bookings {
              id
              start
              end
              type
            }
            occupancy_rate
            spaces {
              space {
                id
                name
              }
              indicators {
                occupancy_rate
              }
            }
          }
        }
      }
    }
  }
`;

export const listOrderReportings = /* GraphQL */ `
  query ListOrderReportings($filter: ListOrderReportingsFilterInput!) {
    listOrderReportings(filter: $filter) {
      items {
        date
        indicators {
          total_sales
          total_cancellations
          total_refunds
          sale_rate
          cancellation_rate
          refund_rate
        }
        data {
          typology
          indicators {
            total_sales
            total_cancellations
            total_refunds
            sale_rate
            cancellation_rate
            refund_rate
          }
        }
      }
    }
  }
`;

export const listIncidentReportings = /* GraphQL */ `
  query listIncidentReportings($filter: ListIncidentReportingsFilterInput!) {
    listIncidentReportings(filter: $filter) {
      items {
        date
        indicators {
          total_incidents
        }
        indicators_status {
          status {
            code
            label
          }
          indicators {
            total_incidents
          }
        }
        indicators_type {
          type {
            code
            label
          }
          indicators {
            total_incidents
          }
        }
      }
    }
  }
`;

export const listReportingsUser = /* GraphQL */ `
  query listReportingsUser($filter: ListReportingsUserFilterInput!) {
    listReportingsUser(filter: $filter) {
      items {
        id
        date
        type
        role
        status
        total
        variation
        createdStartDate
        createdEndDate
        evolutionRate
      }
    }
  }
`;

export const listReportingsSales = /* GraphQL */ `
  query listReportingsSales($filter: ListReportingsSalesFilterInput!) {
    listReportingsSales(filter: $filter) {
      items {
        id
        date
        amount
        variation
        type
        typology
        variation
        createdStartDate
        createdEndDate
        rate
        evolutionRate
      }
    }
  }
`;

export const listReportingsGlobal = /* GraphQL */ `
  query listReportingsGlobal($filter: ListReportingsGlobalFilterInput!) {
    listReportingsGlobal(filter: $filter) {
      items {
        id
        date
        type
        entity
        status
        total
        variation
        createdStartDate
        createdEndDate
        evolutionRate
      }
    }
  }
`;

export const listReportingsIncident = /* GraphQL */ `
  query listReportingsIncident($filter: ListReportingsIncidentFilterInput!) {
    listReportingsIncident(filter: $filter) {
      items {
        id
        date
        view
        total
        variation
        status {
          id
          code
          label
        }
        type {
          id
          code
          label
        }
        reason {
          id
          code
          label
        }
        createdStartDate
        createdEndDate
        evolutionRate
        resolutionTime
      }
    }
  }
`;

export const listReportingsIncidentStatus = /* GraphQL */ `
  query listReportingsIncidentStatus {
    listReportingsIncidentStatus {
      items {
        code
        label
      }
    }
  }
`;

export const listReportingsIncidentType = /* GraphQL */ `
  query listReportingsIncidentType {
    listReportingsIncidentType {
      items {
        id
        code
        label
      }
    }
  }
`;

export const listReportingsIncidentReason = /* GraphQL */ `
  query listReportingsIncidentReason(
    $filter: ListReportingsIncidentReasonFilterInput!
  ) {
    listReportingsIncidentReason(filter: $filter) {
      items {
        code
        label
      }
    }
  }
`;

export const listReportingsBookings = /* GraphQL */ `
  query listReportingsBookings($filter: ListReportingsBookingsFilterInput!) {
    listReportingsBookings(filter: $filter) {
      items {
        id
        date
        view
        type
        typology
        total
        variation
        evolutionRate
        createdStartDate
        createdEndDate
      }
    }
  }
`;
