import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CustomFile,
  Folder,
} from "../../../lib/ground-aws-graphql-core/models/Api/Documents";
import PageSubheader from "../../../components/PageSubheader";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { EnumPaths } from "../../../utils/navigation";
import FolderComponent from "../folder";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const DocumentsList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { match, intl } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [file, setFile] = useState<CustomFile>();

  const listFolders = GroundGraphqlContextStore.useStoreActions(
    actions => actions.documents.listFolders
  );

  const deleteFile = GroundGraphqlContextStore.useStoreActions(
    actions => actions.documents.deleteFile
  );

  const uploadFile = GroundGraphqlContextStore.useStoreActions(
    actions => actions.documents.uploadFile
  );

  const folders = GroundGraphqlContextStore.useStoreState(
    state => state.documents.folders.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.documents.folders.total
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectFile = (file: CustomFile) => {
    setFile(file);
    setShowDeleteModal(true);
  };

  const handleAddFile = (file: File, folder: Folder) => {
    setLoading(true);

    uploadFile({
      file,
      buildingId: match.params.cid,
      folder: folder.key,
    })
      .then(async () => {
        listFolders({
          folderName: EnumPaths.DOCUMENTS,
          buildingId: match.params.cid,
        }).finally(() => {
          const message = intl.formatMessage({
            id: "page.list.documents.upload.document.success",
          });
          toast(message, { type: "success" });
          setLoading(false);
        });
      })
      .catch(() => {
        const message = intl.formatMessage({
          id: "page.list.documents.upload.document.error",
        });
        toast(message, { type: "error" });
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);

    setShowDeleteModal(false);
    deleteFile({
      key: file?.key,
    })
      .then(() => {
        listFolders({
          folderName: EnumPaths.DOCUMENTS,
          buildingId: match.params.cid,
        }).finally(() => {
          const message = intl.formatMessage({
            id: "page.list.documents.delete.document.success",
          });
          toast(message, { type: "success" });
          setLoading(false);
        });
      })
      .catch(() => {
        const message = intl.formatMessage({
          id: "page.list.documents.delete.document.error",
        });
        toast(message, { type: "error" });
        setLoading(false);
      });
  };

  const fetchData = async () => {
    setLoading(true);

    listFolders({
      folderName: EnumPaths.DOCUMENTS,
      buildingId: match.params.cid,
    })
      .catch(() => {
        const message = intl.formatMessage({
          id: "page.list.documents.fetch.error",
        });
        toast(message, { type: "error" });
      })
      .finally(() => setLoading(false));
  };

  const renderFolders = () => {
    return loading ? (
      <div className="loading" />
    ) : (
      <div className="p-8">
        {folders.map((folder, index) => renderFolder(folder, index))}
      </div>
    );
  };

  const renderFolder = (folder: Folder, index: number) => {
    return (
      <FolderComponent
        index={index + 1}
        folder={folder}
        onSelectFile={handleSelectFile}
        onAddFile={handleAddFile}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.documents.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader title="general.documents" />

      {renderFolders()}

      <ConfirmModal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.documents.delete.document" />}
      />
    </>
  );
};

export default injectIntl(DocumentsList);
