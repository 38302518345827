import { formatISO, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import dayjs, { Dayjs, ManipulateType } from "dayjs";
import "dayjs/locale/fr"; // import locale fr
import "dayjs/locale/es";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import "dayjs/locale/pt";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import minMax from "dayjs/plugin/minMax";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  EnumReportingView,
  OpeningClosingHour,
  Product,
} from "../../lib/ground-aws-graphql-core/models/Product";
import { getTranslation } from "../translation";
import { ActionTypes } from "../types";
import { Authorization } from "../../lib/ground-aws-graphql-core/models/Authorization";
import { ILocale } from "../../lang";
import { EnumUnit } from "../../lib/ground-aws-graphql-core/api/graphql/types";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

// Sets the first day of the week to Monday
dayjs().isoWeekday(1);

export const DATE_PICKER_TIME_STEP = 15;
export const DATE_PICKER_TIME_INTERVALS = 30;

export const START_MORNING_HOUR = 8; //TODO:
export const START_MORNING_MINUTES = 0; //TODO:
export const END_MORNING_HOUR = 12; //TODO:
export const END_MORNING_MINUTES = 0; //TODO:
export const START_AFTERNOON_HOUR = 14; //TODO:
export const START_AFTERNOON_MINUTES = 0; //TODO:
export const END_AFTERNOON_HOUR = 19; //TODO:
export const END_AFTERNOON_MINUTES = 0; //TODO:

export const getStringHour = (h: number, m: number): string => {
  const minutes = m > 9 ? `${m}` : `0${m}`;

  return h > 9 ? `${h}:${minutes}` : `0${h}:${minutes}`;
};

export const getHour = (
  hour: string
): {
  hours: number;
  minutes: number;
  date: Date;
} | null => {
  if (!hour) {
    return null;
  }

  const result = null;

  const tabs = hour.split(":");
  if (tabs.length === 2) {
    try {
      const h = parseInt(tabs[0], 10);
      const m = parseInt(tabs[1], 10);

      const date = new Date();
      date.setHours(h, m, 0, 0);

      return {
        hours: h,
        minutes: m,
        date,
      };
    } catch (error) {
      console.error(`Unable to parse hours range: ${hour}`);
    }
  }

  return result;
};

// OPENING HOURS FOR SPACE IN BUILDING TIMEZONE
export const morning = {
  start: getStringHour(START_MORNING_HOUR, START_MORNING_MINUTES),
  end: getStringHour(END_MORNING_HOUR, END_MORNING_MINUTES),
};

export const afternoon = {
  start: getStringHour(START_AFTERNOON_HOUR, START_AFTERNOON_MINUTES),
  end: getStringHour(END_AFTERNOON_HOUR, END_AFTERNOON_MINUTES),
};

export const dayBreakHoursRange = {
  start: getStringHour(END_MORNING_HOUR, END_MORNING_MINUTES),
  end: getStringHour(START_AFTERNOON_HOUR, START_AFTERNOON_MINUTES),
};

// react date picker format
export const DATE_PICKER_FORMAT_CALENDAR = "MMMM yyyy";
export const DATE_PICKER_FORMAT_DAY = "dd/MM/yyyy";
export const DATE_PICKER_FORMAT_DAY_HOUR = "dd/MM/yyyy - HH:mm";

// dayjs format
export const DATE_FORMAT_DAY = "DD/MM/YYYY";
export const DATE_FORMAT_HOUR = "HH:mm";
export const DATE_FORMAT_DAY_HOUR = "DD/MM/YYYY - HH:mm";
export const DATE_FORMAT_DAY_YYYYMMDD = "YYYY-MM-DD";
export const DATE_FORMAT_MONTH = "YYYY-MM";
export const DATE_FORMAT_YYYYMMDDHHMMSS = "YYYY-MM-DD-HH:mm:ss.SSS";

export const getBucket = (): string => {
  return process.env.REACT_APP_S3_BUCKET || "";
};

export const getBucketRegion = (): string => {
  return process.env.REACT_APP_S3_BUCKET_REGION || "";
};

export const getImageHandlerApi = (): string => {
  return process.env.REACT_APP_IMAGE_HANDLER_API || "";
};

export const getImageManagerApi = (): string => {
  return process.env.REACT_APP_IMAGE_MANAGER_API || "";
};

export const isMaxAdministratorsPerCenterReached = (
  items: {
    authorization: Authorization;
    action: ActionTypes;
  }[]
): boolean => {
  const elements = items.filter(i => i.action !== ActionTypes.TO_DELETE);

  return elements.length === getMaxAdministratorsPerCenter();
};

export const getMaxAdministratorsPerCenter = (): number => {
  const max = process.env.REACT_APP_MAX_ADMINISTRATORS_PER_CENTER;
  if (!max) {
    return 10;
  }
  try {
    const value = parseInt(max);

    return value;
  } catch (error) {
    return 10;
  }
};

export const isMaxGroupedProductsReached = (
  items: {
    id: string;
    singleProduct: Product;
    action: ActionTypes;
  }[]
): boolean => {
  const elements = items.filter(i => i.action !== ActionTypes.TO_DELETE);

  return elements.length === getMaxGroupedProducts();
};

export const isMaxGroupedProductsCombinationsReached = (
  products: Product[],
  product: Product
): boolean => {
  const grouped = products.filter(
    p => p.products?.items && p.products?.items.length > 0
  );

  const elements = grouped?.filter(
    p =>
      p.products?.items &&
      p.products?.items.length > 0 &&
      p.products?.items.find(i => i.product.id === product.id)
  );

  return elements.length === getMaxGroupedProductsCombination();
};

export const getMaxGroupedProductsCombination = (): number => {
  const max = process.env.REACT_APP_MAX_GROUPED_PRODUCTS_COMBINAISON;
  if (!max) {
    return 3;
  }
  try {
    const value = parseInt(max);

    return value;
  } catch (error) {
    return 3;
  }
};

export const getMaxGroupedProducts = (): number => {
  const max = process.env.REACT_APP_MAX_GROUPED_PRODUCTS;
  if (!max) {
    return 4;
  }
  try {
    const value = parseInt(max);

    return value;
  } catch (error) {
    return 4;
  }
};

export const getChangeLogWidgetKey = (): string => {
  return process.env.REACT_APP_HEADWAY_WIDGET || "";
};

export const getCypressTestId = (
  item:
    | {
        id?: string | number;
        name?: any;
        title?: any;
        email?: any;
        ormOrder?: any;
        key?: any;
      }
    | null
    | undefined
): string | number | undefined => {
  if (!item) {
    return;
  }
  let result = item.id;
  if (item.email) {
    result = item.email;
  }
  if (item.ormOrder) {
    result = item.ormOrder;
  }
  if (item.key) {
    result = item.key;
  }
  try {
    if (item.title) {
      const title = getTranslation(item.title);
      result = title;
    }
    if (item.name) {
      const name = getTranslation(item.name);
      result = name;
    }
  } catch (error) {
    // console.error({'Unable to parse item name:': error})
  }

  return result;
};

export const randomColor = (
  opacity = 0.5
): {
  color: string;
  colorOpacity: string;
} => {
  const x = Math.floor(Math.random() * 256);
  const y = Math.floor(Math.random() * 256);
  const z = Math.floor(Math.random() * 256);

  const color = "rgba(" + x + "," + y + "," + z + ")";
  const colorOpacity = "rgba(" + x + "," + y + "," + z + "," + opacity + ")";

  return {
    color,
    colorOpacity,
  };
};

export const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getCurrentTimezoneOffset = (): number => {
  return new Date().getTimezoneOffset();
};

export const getDateInUtcUsingOffset = (date: Date): Date => {
  const offset = getCurrentTimezoneOffset();
  const value =
    offset < 0
      ? addDuration(date, -offset, "minute")
      : subDuration(date, offset, "minute");

  return value;
};

export const getTodayInCenterTimezone = (
  timezone: string,
  initHours = false
): Date => {
  let now = dayjs().tz(timezone);

  if (initHours) now = now.startOf("day");

  return now.toDate();
};

export const getDayInLocal = (
  date?: Date
): { startDay: Date; endDay: Date } => {
  // date in local server (UTC date)
  const value = date ?? new Date();

  const s = startOfDay(value);
  const e = endOfDay(value);

  return {
    startDay: s,
    endDay: e,
  };
};

export const getMonthInLocal = (
  date: Date
): { startMonth: Date; endMonth: Date } => {
  const s = dayjs(date).startOf("month");
  const e = dayjs(date).endOf("month");

  return { startMonth: s.toDate(), endMonth: e.toDate() };
};

export const getPreviousMonthInLocal = (month: {
  start: Date;
  end: Date;
}): { startMonth: Date; endMonth: Date } => {
  const start = subDuration(month.start, 1, "month");
  const end = subDuration(month.end, 1, "month");

  return { startMonth: start, endMonth: end };
};

export const getNextMonthInLocal = (month: {
  start: Date;
  end: Date;
}): { startMonth: Date; endMonth: Date } => {
  const start = addDuration(month.start, 1, "month");
  const end = addDuration(month.end, 1, "month");

  return { startMonth: start, endMonth: end };
};

export const getWeekInCenterTimezone = (
  date: Date,
  timezone: string
): { startWeek: Date; endWeek: Date } => {
  const s = dayjs(date).tz(timezone).startOf("isoWeek");
  const e = dayjs(date).tz(timezone).endOf("isoWeek");

  return { startWeek: s.toDate(), endWeek: e.toDate() };
};

export const getPreviousWeek = (week: {
  start: Date;
  end: Date;
}): { startWeek: Date; endWeek: Date } => {
  const start = subDuration(week.start, 1, "week");
  const end = subDuration(week.end, 1, "week");

  return { startWeek: start, endWeek: end };
};

export const getNextWeek = (week: {
  start: Date;
  end: Date;
}): { startWeek: Date; endWeek: Date } => {
  const start = addDuration(week.start, 1, "week");
  const end = addDuration(week.end, 1, "week");

  return { startWeek: start, endWeek: end };
};

export const getCurrentWeekInCenterTimezone = (
  timezone: string
): { startWeek: Date; endWeek: Date } => {
  const now = getTodayInCenterTimezone(timezone);

  return getWeekInCenterTimezone(now, timezone);
};

export const getBookingInBuildingTZ = (
  booking: { start: string; end: string },
  timezone: string
): {
  sameDay: boolean;
  startDay: string;
  endDay: string;
  startHour: string;
  endHour: string;
} => {
  const { start, end } = booking;
  const sameDay = isSameDay(start, end);

  const startDay = displayDayDDMMYYYY(start, timezone);
  const endDay = displayDayDDMMYYYY(end, timezone);

  const startHour = displayDayHH(start, timezone);
  const endHour = displayDayHH(end, timezone);

  return {
    sameDay,
    startDay,
    endDay,
    startHour,
    endHour,
  };
};

export const isAfter = (date1: Date, date2: Date): boolean =>
  dayjs(date1).isAfter(date2);

export const isBefore = (
  date1: Date | Dayjs | string,
  date2: Date | Dayjs | string
): boolean => dayjs(date1).isBefore(date2);

export const formatDate = (date: Date | string, format: string): Date => {
  const value = dayjs(date, format);

  return value?.toDate();
};

export const getDifferenceInHours = (end: Date, start: Date): number => {
  const value = dayjs(end).diff(dayjs(start), "hours", true);

  return Math.ceil(value);
};

export const getDifferenceInMinutes = (end: Date, start: Date): number => {
  const value = dayjs(end).diff(dayjs(start), "minutes", true);

  return Math.ceil(value);
};

export const getDifferenceInMilliseconds = (
  end: string | undefined,
  start: string | undefined
): number => {
  let value = 0;

  if (end && start) {
    value = dayjs(end).diff(dayjs(start), "milliseconds", true);
  }

  return value;
};

export const getMinMaxHoursRanges = (
  hoursRanges: OpeningClosingHour[]
): {
  start: Date;
  end: Date;
} | null => {
  const dayjsIntervals = hoursRanges.flatMap(hour => [
    dayjs(hour.start),
    dayjs(hour.end),
  ]);

  const min = dayjs.min(dayjsIntervals);
  const max = dayjs.max(dayjsIntervals);

  if (!min || !max) {
    return null;
  }

  return {
    start: min.toDate(),
    end: max.toDate(),
  };
};

export const getDuration = (
  date: string
): {
  minutes: number;
  seconds: number;
} => {
  const duration = dayjs.duration(dayjs(date).diff(dayjs()));
  const secondsDuration = duration.asSeconds();
  const minutes = Math.floor(secondsDuration / 60);
  const seconds = Math.floor(secondsDuration - minutes * 60);

  return {
    minutes,
    seconds,
  };
};

export const isInPast = (date: string): boolean =>
  isAfter(new Date(), new Date(date));

export const displayMonthName = (
  date: Date,
  locale: ILocale,
  timezone: string
): string => dayjs(date).locale(locale.date_code).tz(timezone).format("MMMM");

export const displayDayName = (
  date: Date,
  locale: ILocale,
  timezone: string
): string => dayjs(date).locale(locale.date_code).tz(timezone).format("dddd");

export const displayDayNumberInMonth = (date: Date, timezone: string): string =>
  dayjs(date).tz(timezone).format("DD");

export const displayDayLabel = (
  date: Date,
  locale: ILocale,
  timezone: string
): string => {
  return dayjs(date)
    .locale(locale.date_code)
    .tz(timezone)
    .format("dddd D MMMM YYYY");
};

export const displayDayDDMMYYYY = (
  date: Date | string | null | undefined,
  timezone: string
): string => {
  return date ? dayjs(new Date(date)).tz(timezone).format(DATE_FORMAT_DAY) : "";
};

export const displayDayHH = (
  date: Date | string | null | undefined,
  timezone: string
): string => {
  return date
    ? dayjs(new Date(date)).tz(timezone).format(DATE_FORMAT_HOUR)
    : "";
};

export const displayDayDDMMYYYY_HHMM = (
  date: Date | string | null | undefined,
  timezone: string
): string => {
  return date
    ? dayjs(new Date(date)).tz(timezone).format(DATE_FORMAT_DAY_HOUR)
    : "";
};

export const displayDayYYYYMMDD = (date: Date, timezone: string): string =>
  dayjs(date).tz(timezone).format(DATE_FORMAT_DAY_YYYYMMDD);

export const displayDayYYYYMMDDHHMMSS = (
  date: Date,
  timezone: string
): string => dayjs(date).tz(timezone).format(DATE_FORMAT_YYYYMMDDHHMMSS);

export const transformDateInHourForQuery = (date: Date): string => {
  return dayjs(date).format(DATE_FORMAT_HOUR);
};

export const transformDateForQuery = (
  date: Date,
  timezone: string,
  view?: EnumReportingView
): string => {
  let d = dayjs(date).tz(timezone).format(DATE_FORMAT_DAY_YYYYMMDD);
  if (view && view === EnumReportingView.MONTH) {
    d = dayjs(date).tz(timezone).format(DATE_FORMAT_MONTH);
  }

  return d;
};

export const addDuration = (
  date: Date,
  value: number,
  unit: "minute" | "day" | "week" | "month" | "year"
): Date => {
  const result = dayjs(date).add(value, unit);

  return result.toDate();
};

export const subDuration = (
  date: Date,
  value: number,
  unit: "minute" | "day" | "week" | "month" | "year"
): Date => {
  const result = dayjs(date).subtract(value, unit);

  return result.toDate();
};

export const eachUnitOfInterval = (
  start: Date | string,
  end: Date | string,
  unit: ManipulateType,
  step = 1
): Date[] => {
  const range: Date[] = [];
  let current = dayjs(start);

  while (!current.isAfter(dayjs(end))) {
    range.push(current.toDate());
    current = current.add(step, unit);
  }

  return range;
};

export const isSameDates = (date1: Date, date2: Date): boolean =>
  dayjs(date1).isSame(date2);

export const utcToZonedTimeDateFn = (date: Date, timeZone: string): Date => {
  return utcToZonedTime(date, timeZone);
};

export const formatISODateFn = (date: Date): string => {
  return formatISO(date);
};

export const parseISODateFn = (argument: string): Date => {
  return parseISO(argument);
};

export const isSameDay = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  return dayjs(date1).isSame(date2, "day");
};

export const endOfDay = (date: Date): Date => {
  return dayjs(date).endOf("day").toDate();
};

export const startOfDay = (date: Date): Date => {
  return dayjs(date).startOf("day").toDate();
};

export const isMorningOrAfternoon = (
  ranges: OpeningClosingHour[] | null | undefined
): {
  morning: boolean;
  afternoon: boolean;
} => {
  if (!ranges || ranges.length === 0) {
    return {
      morning: false,
      afternoon: false,
    };
  }

  const { morning, afternoon } = isRangeInMorningOrAfternoon(ranges[0]);

  if (ranges.length > 1) {
    const result = isRangeInMorningOrAfternoon(ranges[1]);

    return {
      morning: morning || result.morning,
      afternoon: afternoon || result.afternoon,
    };
  } else {
    return {
      morning,
      afternoon,
    };
  }
};

export const isRangeInMorningOrAfternoon = (
  range: OpeningClosingHour
): {
  morning: boolean;
  afternoon: boolean;
} => {
  const { start, end } = range;

  const morningRangeOption = getMorningRangeOption(start);
  const afternoonRangeOption = getAfternoonRangeOption(start);

  const sInMorningRange = dayjs(start).isBetween(
    morningRangeOption.start,
    morningRangeOption.end,
    "hours",
    "[)"
  );

  const eInMorningRange = dayjs(end).isBetween(
    morningRangeOption.start,
    morningRangeOption.end,
    "hours",
    "(]"
  );

  const sInAfternoonRange = dayjs(start).isBetween(
    afternoonRangeOption.start,
    afternoonRangeOption.end,
    "hours",
    "[)"
  );
  const eInAfternoonRange = dayjs(end).isBetween(
    afternoonRangeOption.start,
    afternoonRangeOption.end,
    "hours",
    "(]"
  );

  return {
    morning: sInMorningRange || eInMorningRange,
    afternoon: sInAfternoonRange || eInAfternoonRange,
  };
};

const getMorningRangeOption = (date: string) => {
  const s = startOfDay(new Date(date));

  const e = new Date(date);
  e.setHours(START_AFTERNOON_HOUR, START_AFTERNOON_MINUTES, 0, 0);

  return {
    start: s,
    end: e,
  };
};

const getAfternoonRangeOption = (date: string) => {
  const s = new Date(date);
  s.setHours(END_MORNING_HOUR, END_MORNING_MINUTES, 0, 0);

  const e = endOfDay(s);

  return {
    start: s,
    end: e,
  };
};

export const getTotalTime = (
  start: Date,
  end: Date,
  unit: EnumUnit | undefined
): number => {
  let value = 1;
  switch (unit) {
    case EnumUnit.HALF_HOUR:
      value = getDifferenceInMinutes(end, start) / 30;
      break;
    case EnumUnit.HOUR:
      value = getDifferenceInHours(end, start);
      break;
    default:
      break;
  }

  return Math.ceil(value);
};

export const forceHourToCenterTimezone = (
  date: Date | Dayjs | string | null,
  timezone: string
): Date | null =>
  date
    ? dayjs.tz(dayjs(date).format("YYYY-MM-DD HH:mm:ss"), timezone).toDate()
    : null;

export const getBookingPriceUnitsInBO = (): EnumUnit[] => {
  const values = [
    EnumUnit.HALF_HOUR,
    EnumUnit.HOUR,
    EnumUnit.HALF_DAY,
    EnumUnit.DAY,
  ];

  return values;
};

export const validateEmail = (email: string): string => {
  if (!email) return "general.required.field";
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
    return "general.field.email.invalid";

  return "";
};
