import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { FieldAttributes } from "formik";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import ImageGallery from "../../../../components/Tailwind/ImageGallery";
import {
  Image,
  ActionTypes,
  getCategoryOptions,
  isOperatorAdmin,
  EnumAction,
} from "../../../../utils/types";
import CustomDatePicker from "../../../../components/Tailwind/DatePicker";
import Footer from "../../../../components/Tailwind/Block/Footer";
import contextStore from "../../../../redux/store";
import ConfirmModal from "../../../../utils/modal/confirm";
import IntlMessages from "../../../../utils/messages";
import {
  EnumCategoryType,
  EnumPermissionEntity,
  SearchableCategorySortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import {
  getCypressTestId,
  getTodayInCenterTimezone,
  isAfter,
} from "../../../../utils/config";
import Button from "../../../../components/Tailwind/Button";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";
import ModalCreateUpdateCategory from "../../../catalog/category/create-update";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import FormQuill from "../../../../components/Form/FormQuill";
import { getLocale } from "../../../../lang";

interface Props {
  handleClose?: () => void;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const NewsForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [category, setCategory] = useState<Category | null>(null);
  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [action, setAction] = useState<EnumAction>();

  const { edition, handleClose, match, intl } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // initial date
  const date = getTodayInCenterTimezone(centerTimezone, true);

  const [startDate, setStartDate] = useState<Date | null>(
    !edition ? date : null
  );
  const [endDate, setEndDate] = useState<Date | null>(!edition ? date : null);

  const [pictures, setPictures] = useState([] as Image[]);
  const [enabled, setEnabled] = useState<boolean>(true);

  const [togglePanel, setTogglePanel] = useState(false);

  const getNews = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.getNews
  );

  const updateNews = contextStore.useStoreActions(
    actions => actions.news.updateNews
  );

  const createNews = contextStore.useStoreActions(
    actions => actions.news.createNews
  );

  const updateNewsAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.updateNews
  );

  const createNewsAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.createNews
  );

  const news = GroundGraphqlContextStore.useStoreState(
    state => state.news.news
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const categories = GroundGraphqlContextStore.useStoreState(
    state => state.category.categories.items
  );

  const searchAllCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.searchAllCategories
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const handleDelete = (id: string) => {
    setLoading(true);
    updateNewsAction({ id, markForDelete: true })
      .then(() => {
        const centerId = match.params.cid;
        history.push(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.NEWS}`
        );
        toast(
          intl.formatMessage({
            id: "general.news.delete",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "general.news.delete",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    if (edition && news && startDate && endDate) {
      updateNews({
        news,
        values: { ...values, newsCategoryId: category?.id },
        pictures,
        center: {
          id: match.params.cid,
        },
        publicationDate: startDate,
        unpublicationDate: endDate,
        enabled,
        callbacks: {
          updateNews: updateNewsAction,
          createNews: createNewsAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.NEWS}`
          );
          toast(
            intl.formatMessage({
              id: "page.news.update.news.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.news.update.news.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (startDate && endDate) {
      createNews({
        news: null,
        values: { ...values, newsCategoryId: category?.id },
        pictures,
        center: {
          id: match.params.cid,
        },
        me,
        publicationDate: startDate,
        unpublicationDate: endDate,
        enabled,
        callbacks: {
          updateNews: updateNewsAction,
          createNews: createNewsAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${centerId}/${EnumPaths.MARKETING}/${EnumPaths.NEWS}`
          );
          toast(
            intl.formatMessage({
              id: "page.news.create.news.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.news.create.news.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    const promises = [
      searchAllCategories({
        filter: {
          type: { eq: EnumCategoryType.NEWS },
        },
        sort: {
          field: SearchableCategorySortableFields.createdAt,
          direction: SearchableSortDirection.desc,
        },
        locale: currentAppLocale.backend_locale,
      }),
    ];
    if (edition) {
      promises.push(getNews({ id: match.params.id }));
    }
    Promise.all(promises).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (news) {
      setCategory(news.category || null);
      setEnabled(news.enabled ? news.enabled : false);

      if (news.publicationDate) {
        setStartDate(new Date(news.publicationDate));
      }

      if (news.unpublicationDate) {
        setEndDate(new Date(news.unpublicationDate));
      }

      // on picture for news
      if (news.picture) {
        const newsPictures: Image[] = [];
        newsPictures.push({
          picture: news.picture,
          source: false,
          action: ActionTypes.TO_KEEP,
        });
        setPictures(newsPictures);
      }
    }
  }, [news]);

  const handleAddPicture = (picture: string | ArrayBuffer) => {
    const items = [...pictures];
    items.push({
      picture,
      source: true,
      action: ActionTypes.TO_ADD,
    });
    setPictures(items);

    // show save panel
    handleShowSavePanel();
  };

  useEffect(() => {
    const currentCategory = category
      ? categories?.find(c => c.id === category.id)
      : null;
    if (currentCategory) {
      setCategory(currentCategory);
    }
  }, [categories]);

  const handleChangeCategory = (e: any) => {
    const catId = e.target.value;

    const currentCategory = catId
      ? categories?.find(c => c.id === catId)
      : null;

    if (currentCategory) setCategory(currentCategory);

    handleShowSavePanel();
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({
        ...el,
        action: ActionTypes.TO_DELETE,
      });
      setPictures(picturesToDelete);
    }

    // show save panel
    handleShowSavePanel();
  };

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const operatorAdmin = isOperatorAdmin(me);

  const cleanedCategories: [Category] | null =
    categories && categories.length > 0
      ? (categories.filter(c => c.level !== 0) as [Category])
      : null;
  const categoryItems = getCategoryOptions(cleanedCategories);

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "title",
      label: "general.title",
      placeholder: "general.title",
      initialValue: news?.title,
      required: true,
      translatable: true,
    },
    {
      name: "description",
      label: "general.description",
      placeholder: "general.description",
      initialValue: news?.description,
      required: true,
      translatable: true,
      component: (props: any) => <FormQuill {...props} />,
    },
    {
      name: "newsCategoryId",
      label: "general.category",
      placeholder: "general.category",
      initialValue: category?.id,
      as: "select",
      options: categoryItems,
      onChange: handleChangeCategory,
      value: category?.id,
      children: !operatorAdmin ? undefined : (
        <div className="mt-4">
          <button
            id="btn-modify-category"
            name="btn-modify-category"
            data-cy="btn-modify-category"
            data-testid={getCypressTestId(category)}
            type="button"
            onClick={() => {
              setAction(EnumAction.UPDATE);
              setModalCategoryOpen(true);
            }}
            className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
          >
            <span>
              <IntlMessages id="general.edit.category" />
            </span>
          </button>
        </div>
      ),
      thirdColComponent: !operatorAdmin ? undefined : (
        <div>
          <Button
            id="btn-add-category"
            name="btn-add-category"
            data-cy="btn-add-category"
            item={null}
            type="button"
            outline
            onClick={() => {
              setAction(EnumAction.CREATE);
              setModalCategoryOpen(true);
            }}
          >
            <IntlMessages id="general.add.CATEGORY" />
          </Button>
        </div>
      ),
    },
  ];

  const labels = {
    creation: "page.list.news.create.news",
    edition: "page.list.news.update.news",
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <div className="px-8">
      <Block>
        <Header
          item={news}
          title={edition ? labels.edition : labels.creation}
          entity={EnumPermissionEntity.NEWS}
          checked={enabled}
          onChange={e => {
            setEnabled(e);

            // show save panel
            handleShowSavePanel();
          }}
          className="border-b border-gray-200"
        />
        <ModalCreateUpdateCategory
          {...props}
          isOpen={modalCategoryOpen}
          parent={false}
          categories={categories}
          toggle={() => {
            setModalCategoryOpen(!modalCategoryOpen);
          }}
          action={action}
          type={EnumCategoryType.NEWS}
          category={action === EnumAction.UPDATE ? category : null}
          centerId={match.params.cid}
        />

        <DefaultForm
          fields={formFields}
          onSubmit={handleSubmit}
          showPanel={togglePanel}
        >
          <CustomDatePicker
            name="startdate"
            label="page.list.news.table.head.startdate"
            minDate={date}
            index={formFields.length}
            selected={startDate}
            onChange={selectedDate => {
              setStartDate(selectedDate);
              if (endDate) {
                const after = isAfter(selectedDate, endDate);
                if (after) {
                  setEndDate(selectedDate);
                }
              }
              // show save panel
              handleShowSavePanel();
            }}
            selector="selectsStart"
            startDate={startDate}
            endDate={endDate}
            dateFormat="P"
          />
          <CustomDatePicker
            name="enddate"
            label="page.list.news.table.head.enddate"
            minDate={startDate}
            index={formFields.length + 1}
            selected={endDate}
            onChange={d => {
              setEndDate(d);
              // show save panel
              handleShowSavePanel();
            }}
            selector="selectsEnd"
            startDate={startDate}
            endDate={endDate}
            dateFormat="P"
          />
          <ImageGallery
            entity={news}
            label="general.image.gallery.photos"
            index={formFields.length + 2}
            max={1}
            images={pictures}
            onAddImage={handleAddPicture}
            onRemoveImage={handleRemovePicture}
          />
          {news && edition && (
            <>
              <Footer
                item={news}
                index={formFields.length + 3}
                labels={["general.cancel", "general.delete"]}
                onCancel={handleClose}
                onDelete={() => setShowDeleteModal(!showDeleteModal)}
              />
              <ConfirmModal
                item={news}
                isOpen={showDeleteModal}
                onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
                handleConfirm={() => handleDelete(news.id)}
                content={<IntlMessages id="page.news.delete.news" />}
              />
            </>
          )}
          {!edition && (
            <Footer
              item={news}
              index={formFields.length + 3}
              labels={["general.cancel", "general.delete"]}
              onCancel={handleClose}
            />
          )}
        </DefaultForm>
      </Block>
    </div>
  );
};

export default NewsForm;
