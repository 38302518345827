import { OperatorConfig } from "..";
import { EnumBackOfficeUserRole } from "../../ground-aws-graphql-core/api/graphql/types";

const displayableElement = [
  //header
  "header.centers",
  "header.providers",
  "header.users",
  "header.administrators",
  "header.parameters",
  "header.indicators",
  //sidebar
  "sidebar.menu.building",
  "sidebar.menu.building.informations",
  "sidebar.menu.building.notifications",
  "sidebar.menu.building.documents",
  "sidebar.menu.management",
  "sidebar.menu.management.dashboards",
  "sidebar.menu.management.calendar",
  "sidebar.menu.management.bookings",
  "sidebar.menu.management.orders",
  "sidebar.menu.management.incidents",
  "sidebar.menu.management.visits",
  "sidebar.menu.catalog",
  "sidebar.menu.catalog.workspaces",
  "sidebar.menu.catalog.services",
  "sidebar.menu.communicate",
  "sidebar.menu.communicate.events",
  "sidebar.menu.communicate.news",

  // features
  "feature.building.rating",

  "feature.enterprise",
  "feature.user.delete",
  "feature.user.forgot_password",
  "feature.dashboards.realtime_occupancy",
  "feature.dashboards.realtime_sales",
  "feature.dashboards.realtime_incidents",
  "feature.dashboards.ga",
  "feature.dashboards.incidents",
  "feature.dashboards.sales",
  "feature.dashboards.occupancy",
] as const;

type DisplayableElement = (typeof displayableElement)[number];

export type DisplayConfiguration = Partial<
  Record<
    DisplayableElement,
    boolean | Partial<Record<EnumBackOfficeUserRole, boolean>>
  >
>;

export const isDisplayable = (
  configuration: OperatorConfig,
  path: DisplayableElement,
  role?: string
): boolean => {
  const displayed = configuration?.backoffice?.display?.[path];

  let value = false;
  switch (typeof displayed) {
    case "boolean":
      value = displayed;
      break;

    case "object":
      value = role ? displayed[role] || false : false;
      break;

    default:
      break;
  }

  return value;
};

export const defaultBackOfficeDisplay: DisplayConfiguration = {
  "header.centers": {
    OperatorAdmin: true,
    CenterAdmin: true,
  },
  "header.providers": {
    OperatorAdmin: true,
  },
  "header.users": {
    OperatorAdmin: true,
  },
  "header.administrators": {
    OperatorAdmin: true,
  },
  "header.parameters": {
    OperatorAdmin: true,
  },
  "header.indicators": {
    OperatorAdmin: true,
  },
  // building
  "sidebar.menu.building": true,
  "sidebar.menu.building.informations": true,
  "sidebar.menu.building.notifications": true,
  "sidebar.menu.building.documents": false,

  // management (dashboard, bookings, incidents, visits)
  "sidebar.menu.management": true,
  "sidebar.menu.management.dashboards": true,
  "sidebar.menu.management.calendar": true,
  "sidebar.menu.management.bookings": true,
  "sidebar.menu.management.orders": true,
  "sidebar.menu.management.incidents": true,
  "sidebar.menu.management.visits": true,

  // catalog
  "sidebar.menu.catalog": true,
  "sidebar.menu.catalog.workspaces": true,
  "sidebar.menu.catalog.services": true,

  // events & news
  "sidebar.menu.communicate": true,
  "sidebar.menu.communicate.news": true,
  "sidebar.menu.communicate.events": true,

  // features for operator
  "feature.enterprise": true,

  "feature.building.rating": false,
  "feature.user.delete": true,
  "feature.user.forgot_password": true,

  "feature.dashboards.realtime_occupancy": true,
  "feature.dashboards.realtime_sales": true,
  "feature.dashboards.realtime_incidents": true,
  "feature.dashboards.ga": true,
  "feature.dashboards.incidents": true,
  "feature.dashboards.sales": true,
  "feature.dashboards.occupancy": true,
};
