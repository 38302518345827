import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import _ from "lodash";
import { $enum } from "ts-enum-util";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import GroundFormik from "../../../components/Tailwind/Form";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import { Value, Image, ActionTypes } from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import ImageGallery from "../../../components/Tailwind/ImageGallery";
import contextStore from "../../../redux/store";
import ConfirmModal from "../../../utils/modal/confirm";
import SavePanel from "../../../components/Tailwind/SavePanel";
import {
  AttributeItem,
  ProviderAttributeKeys,
  getAttributes,
  getRequiredAttributes,
} from "../../../utils/attribute";
import CardAttributes from "../../../components/GroundBlocks/BlockCard/CardAttributes";
import StripeConnect from "../../../components/StripeConnect";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { getCountries } from "../../../lang";
import {
  EnumAttributeType,
  EnumPermissionEntity,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundStripeContextStore } from "../../../lib/ground-payment-stripe-core";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey/index";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import {
  CreateAccountLinksOpts,
  CreateConnectedAccountOpts,
} from "../../../lib/ground-payment-stripe-core/models/Payment";

interface Props {
  match: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const DEFAULT_LIMIT = 100;

const ProviderForm = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [receivingEmails, setReceivingEmails] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pictures, setPictures] = useState([] as Image[]);
  const [togglePanel, setTogglePanel] = useState(false);
  const generalFormRef = useRef<any>();
  const attributesFormRef = useRef<any>();
  const [fieldsToUpdate, setFieldsToUpdate] = useState(
    [] as { field: any; value: any }[]
  );
  const [attributes, setAttributes] = useState<AttributeItem[]>([]);
  const [attributeKeys, setAttributeKeys] = useState<AttributeKey[]>([]);

  const { match, intl, edition } = props;

  const getProvider = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.getProvider
  );

  const updateProviderAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.updateProvider
  );

  const deleteProviderAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.deleteProvider
  );

  const createProviderAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.createProvider
  );

  const createProvider = contextStore.useStoreActions(
    actions => actions.provider.createProvider
  );

  const updateProvider = contextStore.useStoreActions(
    actions => actions.provider.updateProvider
  );

  const provider = GroundGraphqlContextStore.useStoreState(
    state => state.provider.provider
  );

  const createConnectedAccount = GroundStripeContextStore.useStoreActions(
    actions => actions.payment.createConnectedAccount
  );

  const createAccountLinks = GroundStripeContextStore.useStoreActions(
    actions => actions.payment.createAccountLinks
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const searchAllAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.searchAllAttributeKeys
  );

  const url = window.location.href;
  const { origin } = new URL(url);

  const handleDelete = id => {
    setLoading(true);
    deleteProviderAction({
      id,
    })
      .then(() => {
        history.push(`/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}`);
        toast(
          intl.formatMessage({
            id: "general.provider.delete",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "general.provider.delete",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const addFieldToUpdate = e => {
    const index = _.findIndex(fieldsToUpdate, { field: e.target.name });
    const array = fieldsToUpdate;
    if (index >= 0) {
      array.splice(index, 1, {
        field: e.target.name,
        value: e.target.value,
      });
      setFieldsToUpdate(array);
    } else {
      setFieldsToUpdate([
        ...fieldsToUpdate,
        { field: e.target.name, value: e.target.value },
      ]);
    }
    handleShowSavePanel();
  };

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleHideSavePanel = () => {
    setTogglePanel(false);
  };

  const saveDatas = () => {
    setLoading(true);

    const name = _.find(fieldsToUpdate, ["field", "name"]);
    const address = _.find(fieldsToUpdate, ["field", "address"]);
    const zipCode = _.find(fieldsToUpdate, ["field", "zipCode"]);
    const city = _.find(fieldsToUpdate, ["field", "city"]);
    const country = _.find(fieldsToUpdate, ["field", "country"]);
    const description = _.find(fieldsToUpdate, ["field", "description"]);
    const email = _.find(fieldsToUpdate, ["field", "email"]);
    const phone = _.find(fieldsToUpdate, ["field", "phone"]);

    let values: any = {
      name: name ? name.value : provider?.name,
      address: address ? address.value : provider?.address,
      zipCode: zipCode ? zipCode.value : provider?.zipCode,
      city: city ? city.value : provider?.city,
      country: country ? country.value : provider?.country,
      phone: phone ? phone.value : provider?.phone,
      email: email ? email.value : provider?.email,
      receivingEmails,
    };
    const descr = description ? description.value : provider?.description;
    if (descr) {
      values = { ...values, description: descr };
    }
    const payload = {
      provider,
      values,
      enabled,
      pictures,
      attributeKeys,
      attributes,
      callbacks: {
        createProvider: createProviderAction,
        updateProvider: updateProviderAction,
      },
    };

    setLoading(true);
    if (edition) {
      updateProvider(payload)
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}`);
          toast(
            intl.formatMessage({
              id: "page.provider.update.provider.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.provider.update.provider.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    } else {
      createProvider(payload)
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}`);
          toast(
            intl.formatMessage({
              id: "page.provider.create.provider.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.provider.create.provider.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleTogglePanel = () => {
    setTogglePanel(!togglePanel);
  };

  const handleChangeAttribute = (newAttributes: AttributeItem[]) => {
    setAttributes([...newAttributes]);
    handleShowSavePanel();
  };

  const handleSavePanel = () => {
    if (generalFormRef.current) {
      generalFormRef.current.validateForm().then(errors => {
        if (Object.keys(errors).length === 0) {
          if (attributesFormRef.current) {
            attributesFormRef.current.validateForm().then(data => {
              if (Object.keys(data).length === 0) {
                saveDatas();
                setTogglePanel(!togglePanel);
              } else {
                attributesFormRef.current.showErrors(data);
              }
            });
          } else {
            saveDatas();
            setTogglePanel(!togglePanel);
          }
        } else {
          generalFormRef.current.showErrors(errors);
        }
      });
    }
    // setFieldsToUpdate([]);
  };

  useEffect(() => {
    setLoading(true);

    const promises = [
      searchAllAttributeKeys({
        filter: {
          type: { eq: EnumAttributeType.PROVIDER },
          enabled: { eq: true },
        },
        limit: DEFAULT_LIMIT,
      }),
    ];

    if (match.params.id) promises.push(getProvider({ id: match.params.id }));

    Promise.all(promises)
      .then(([attrKeys]) => setAttributeKeys(attrKeys.items))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (provider) {
      setEnabled(provider.enabled as boolean);
      setReceivingEmails(!!provider?.receivingEmails);
      const providerPictures = provider.pictures?.reduce(
        (acc: Image[], picture) => {
          if (picture) {
            acc.push({
              picture,
              source: false,
              action: ActionTypes.TO_KEEP,
            });
          }

          return acc;
        },
        []
      );

      if (providerPictures) {
        setPictures(providerPictures);
      }
    }
  }, [provider]);

  useEffect(() => {
    if (
      provider &&
      attributeKeys &&
      provider?.attributes &&
      provider?.attributes.items
    ) {
      const providerAttributes = getAttributes(
        provider,
        EnumAttributeType.PROVIDER,
        attributeKeys
      );
      setAttributes([...providerAttributes]);
    }
  }, [provider, attributeKeys]);

  const handleAddPicture = (picture: string | ArrayBuffer) => {
    const items = [...pictures];
    items.push({ picture, source: true, action: ActionTypes.TO_ADD });
    setPictures(items);
    handleShowSavePanel();
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({ ...el, action: ActionTypes.TO_DELETE });
      setPictures(picturesToDelete);
    }
    handleShowSavePanel();
  };

  const handleStripeAccountCreation = () => {
    if (provider && provider.id && meDetails?.operator.short_name) {
      const params: CreateConnectedAccountOpts = {
        id: provider.id,
        operatorName: meDetails?.operator.short_name,
      };
      setLoading(true);
      createConnectedAccount(params)
        .then(() => {
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}/${provider.id}/${EnumPaths.EDIT_MODE}`
          );
        })
        .catch(() => setLoading(false));
    }
  };

  const handleStripeAccountLink = (stripeAccountId: string) => {
    if (
      stripeAccountId &&
      provider &&
      provider.id &&
      meDetails?.operator.short_name
    ) {
      const params: CreateAccountLinksOpts = {
        operatorName: meDetails?.operator.short_name,
        id: stripeAccountId,
        refreshUrl: `${origin}/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}/${provider.id}/${EnumPaths.EDIT_MODE}`,
        returnUrl: `${origin}/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}/${provider.id}/${EnumPaths.EDIT_MODE}`,
      };
      setLoading(true);
      createAccountLinks(params)
        .then(res => {
          window.location.replace(res.data.url);
        })
        .catch(() => setLoading(false));
    }
  };

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const countries = getCountries(locale);

  const values: Value[] = [
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.provider.name",
      placeholder: "page.provider.name",
      required: true,
      value: provider?.name,
      translatable: true,
      useIntl: true,
    },
    {
      id: "address",
      name: "address",
      type: "text",
      label: "general.address",
      placeholder: "general.address",
      required: true,
      value: provider?.address ?? "",
      useIntl: true,
    },
    {
      id: "zipCode",
      name: "zipCode",
      type: "text",
      label: "page.provider.zipcode",
      placeholder: "page.provider.zipcode",
      required: true,
      value: provider?.zipCode,
      useIntl: true,
    },
    {
      id: "city",
      name: "city",
      type: "text",
      label: "general.city",
      placeholder: "general.city",
      required: true,
      value: provider?.city,
      useIntl: true,
    },
    {
      id: "country",
      name: "country",
      type: "select",
      label: "general.country",
      placeholder: "general.country",
      required: true,
      items: countries,
      values: [
        {
          id: provider?.country,
          name: provider?.country,
        },
      ],
      useIntl: true,
    },
    {
      id: "description",
      name: "description",
      type: "textarea",
      label: "general.description",
      placeholder: "general.description",
      required: false,
      value: provider?.description,
      translatable: true,
      useIntl: true,
    },
    {
      id: "phone",
      name: "phone",
      type: "text",
      label: "general.phone",
      placeholder: "general.phone",
      required: false,
      value: provider?.phone ? provider?.phone : "",
      useIntl: true,
    },
    {
      id: "email",
      name: "email",
      type: "email",
      label: "general.email",
      placeholder: "general.email",
      required: false,
      value: provider?.email ? provider?.email : "",
      useIntl: true,
      multiple: true,
    },
    {
      id: "receivingEmails",
      name: "receivingEmails",
      type: "checkbox",
      label: "general.receiving.emails",
      placeholder: "general.receiving.emails",
      required: false,
      value: provider?.receivingEmails || false,
      useIntl: true,
    },
  ];

  const labels = {
    creation: "page.list.providers.create.provider",
    edition: "page.list.providers.update.provider",
  };

  const requiredAttributes = getRequiredAttributes(attributeKeys, attributes);

  const classNameDeleteButton = classnames(
    "inline-flex items-center px-4 py-2 border a rounded-5 focus:outline-none transition ease-in-out duration-150",
    {
      "border-transparent text-white bg-red-500 hover:bg-white hover:text-red-500 hover:border-1 hover:border-red-500 focus:border-red-500 active:bg-red-500 active:text-white":
        true,
    }
  );

  const providerAttributeKeys = $enum(ProviderAttributeKeys).getKeys();
  const attrKeys = attributeKeys.filter(a =>
    providerAttributeKeys.includes(ProviderAttributeKeys[a.name])
  );

  return loading ? (
    <div className="loading" />
  ) : (
    <div className="px-8 pb-5">
      <Block>
        <Header
          item={provider}
          title={edition ? labels.edition : labels.creation}
          entity={EnumPermissionEntity.PROVIDER}
          checked={enabled}
          onChange={e => {
            setEnabled(e);
            handleShowSavePanel();
          }}
          className="border-b border-gray-200"
        />
        <GroundFormik
          ref={generalFormRef}
          onChange={addFieldToUpdate}
          countries={countries}
          item={provider}
          values={values}
          showSavePanel={togglePanel}
          onHideSavePanel={handleHideSavePanel}
          onChangeSwitch={checked => {
            setReceivingEmails(checked);
            handleShowSavePanel();
          }}
        >
          <ImageGallery
            entity={provider}
            label="general.image.gallery.photos"
            index={values.length}
            max={5}
            images={pictures}
            onAddImage={handleAddPicture}
            onRemoveImage={handleRemovePicture}
          />
        </GroundFormik>
      </Block>
      {edition && (
        <>
          <CardAttributes
            {...props}
            item={provider}
            title="general.param.attributes"
            attributes={attributes.concat(requiredAttributes)}
            attributeKeys={attributeKeys}
            onChange={handleChangeAttribute}
            onDelete={handleChangeAttribute}
            type={EnumAttributeType.PROVIDER}
            ref={attributesFormRef}
            zeroMessage="page.provider.attributes.empty"
          />
          {attrKeys && attrKeys.length > 0 && (
            <Block>
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
                <div>
                  <h3 className="text-16px leading-6 font-medium text-gray-900">
                    <IntlMessages id="page.provider.stripe.configuration" />
                  </h3>
                </div>
              </div>
              <StripeConnect
                attributes={attributes}
                onCreateAccount={handleStripeAccountCreation}
                onLinkAccount={handleStripeAccountLink}
              />
            </Block>
          )}
        </>
      )}
      {provider && edition && (
        <>
          <div className="flex justify-end">
            <button
              id="btn-delete-provider"
              name="btn-delete-provider"
              data-cy="btn-delete-provider"
              data-testid={getCypressTestId(provider)}
              type="button"
              onClick={() => setShowDeleteModal(!showDeleteModal)}
              className={classNameDeleteButton}
            >
              <span className="text-center">
                <IntlMessages id="general.delete" />
              </span>
            </button>
          </div>
          <ConfirmModal
            item={provider}
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
            handleConfirm={() => handleDelete(provider.id)}
            content={<IntlMessages id="page.provider.delete.provider" />}
          />
        </>
      )}
      <SavePanel
        label="general.panel.save"
        visible={togglePanel}
        onCancel={handleTogglePanel}
        onSave={handleSavePanel}
      />
    </div>
  );
};
export default injectIntl(ProviderForm);
