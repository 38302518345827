import { uploadPicturesPromise } from "../Image";
import { NewsOpts } from "../../models/News";
import {
  CreateNewsInput,
  EnumPermissionEntity,
  UpdateNewsInput,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";

export const createNews = async (payload: NewsOpts): Promise<any> => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.NEWS
  );
  const { urls } = response;

  const params: CreateNewsInput = {
    title: payload.values.title,
    description: payload.values.description,
    publicationDate: payload.publicationDate,
    unpublicationDate: payload.unpublicationDate,
    picture: urls?.length > 0 ? urls[0] : "",
    enabled: payload.enabled,
    centerNewsId: payload.center.id,
    newsCategoryId: payload.values.newsCategoryId,
  };

  return await payload.callbacks.createNews(params);
};

export const updateNews = async (payload: NewsOpts): Promise<any> => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.NEWS
  );

  const { urls } = response;

  const params: UpdateNewsInput = {
    id: payload.news!.id,
    title: payload.values.title,
    description: payload.values.description,
    publicationDate: payload.publicationDate,
    unpublicationDate: payload.unpublicationDate,
    picture: urls?.length > 0 ? urls[0] : "",
    enabled: payload.enabled,
    markForDelete: false,
    newsCategoryId: payload.values.newsCategoryId,
  };

  return await payload.callbacks.updateNews(params);
};
