import { createContextStore, persist } from "easy-peasy";
import { storeModel } from "../models";
import * as UserService from "../services/User";
import * as UnavailabilityService from "../services/Unavailability";
import * as CenterService from "../services/Center";
import * as CenterTypeService from "../services/CenterType";
import * as ServiceService from "../services/Service";
import * as ProviderService from "../services/Provider";
import * as ProductService from "../services/Product";
import * as PriceUnitService from "../services/PriceUnit";
import * as ParticipationService from "../services/Participation";
import * as GlobalOrderService from "../services/GlobalOrder";
import * as OrderService from "../services/Order";
import * as OrderItemService from "../services/OrderItem";
import * as NewsService from "../services/News";
import * as EventTypeService from "../services/EventType";
import * as EventService from "../services/Event";
import * as DaySlotService from "../services/DaySlot";
import * as ClosedDayService from "../services/ClosedDay";
import * as CategoryService from "../services/Category";
import * as BackOfficeUserService from "../services/BackOfficeUser";
import * as AttributeService from "../services/Attribute";
import * as AttributeKeyService from "../services/AttributeKey";
import * as OptionService from "../services/Option";
import * as SingleProductService from "../services/SingleProduct";
import * as NotificationService from "../services/Notification";
import * as AuthorizationService from "../services/Authorization";
import * as PriceUnitVariationService from "../services/PriceUnitVariation";
import * as ReportingService from "../services/Reporting";
// api
import * as CartApiService from "../services/Api/Cart";
import * as AccountApiService from "../services/Api/Account";
import * as OrderApiService from "../services/Api/Order";
import * as CancellationApiService from "../services/Api/Cancellation";
import * as IncidentService from "../services/Api/Incidents";
import * as VisitsService from "../services/Api/Visits";
import * as PaymentMethodsService from "../services/Api/PaymentMethods";
import * as RefundRequestsService from "../services/Api/RefundRequests";
import * as AttributeValueService from "../services/Api/Attribute";
import * as WebhooksService from "../services/Api/Webhooks";
import * as DocumentsService from "../services/Api/Documents";

export interface Injections {
  userService: typeof UserService;
  unavailabilityService: typeof UnavailabilityService;
  serviceService: typeof ServiceService;
  providerService: typeof ProviderService;
  productService: typeof ProductService;
  priceUnitService: typeof PriceUnitService;
  participationService: typeof ParticipationService;
  orderService: typeof OrderService;
  globalOrderService: typeof GlobalOrderService;
  orderItemService: typeof OrderItemService;
  newsService: typeof NewsService;
  eventTypeService: typeof EventTypeService;
  eventService: typeof EventService;
  daySlotService: typeof DaySlotService;
  closedDayService: typeof ClosedDayService;
  centerTypeService: typeof CenterTypeService;
  centerService: typeof CenterService;
  categoryService: typeof CategoryService;
  backOfficeUserService: typeof BackOfficeUserService;
  attributeService: typeof AttributeService;
  attributeKeyService: typeof AttributeKeyService;
  optionService: typeof OptionService;
  singleProductService: typeof SingleProductService;
  notificationService: typeof NotificationService;
  authorizationService: typeof AuthorizationService;
  priceUnitVariationService: typeof PriceUnitVariationService;
  reportingService: typeof ReportingService;

  // api
  cartApiService: typeof CartApiService;
  accountApiService: typeof AccountApiService;
  orderApiService: typeof OrderApiService;
  cancellationApiService: typeof CancellationApiService;
  incidentService: typeof IncidentService;
  visitsService: typeof VisitsService;
  paymentMethodsService: typeof PaymentMethodsService;
  refundRequestsService: typeof RefundRequestsService;
  attributeValueService: typeof AttributeValueService;
  webhooksService: typeof WebhooksService;
  documentsService: typeof DocumentsService;
}

export const contextStore = createContextStore(storeModel, {
  name: "Ground GraphQl Store",
  injections: {
    userService: UserService,
    unavailabilityService: UnavailabilityService,
    serviceService: ServiceService,
    providerService: ProviderService,
    productService: ProductService,
    priceUnitService: PriceUnitService,
    participationService: ParticipationService,
    orderService: OrderService,
    globalOrderService: GlobalOrderService,
    orderItemService: OrderItemService,
    newsService: NewsService,
    eventTypeService: EventTypeService,
    eventService: EventService,
    daySlotService: DaySlotService,
    closedDayService: ClosedDayService,
    centerTypeService: CenterTypeService,
    centerService: CenterService,
    categoryService: CategoryService,
    backOfficeUserService: BackOfficeUserService,
    attributeService: AttributeService,
    attributeKeyService: AttributeKeyService,
    optionService: OptionService,
    singleProductService: SingleProductService,
    notificationService: NotificationService,
    authorizationService: AuthorizationService,
    priceUnitVariationService: PriceUnitVariationService,
    reportingService: ReportingService,

    // api
    cartApiService: CartApiService,
    accountApiService: AccountApiService,
    orderApiService: OrderApiService,
    cancellationApiService: CancellationApiService,
    incidentService: IncidentService,
    visitsService: VisitsService,
    paymentMethodsService: PaymentMethodsService,
    refundRequestsService: RefundRequestsService,
    attributeValueService: AttributeValueService,
    webhooksService: WebhooksService,
    documentsService: DocumentsService,
  },
});

export const contextStorePersist = storage =>
  createContextStore(persist(storeModel, { storage }), {
    name: "Ground GraphQl Store Persist",
    injections: {
      userService: UserService,
      unavailabilityService: UnavailabilityService,
      serviceService: ServiceService,
      providerService: ProviderService,
      productService: ProductService,
      priceUnitService: PriceUnitService,
      participationService: ParticipationService,
      globalOrderService: GlobalOrderService,
      orderService: OrderService,
      orderItemService: OrderItemService,
      newsService: NewsService,
      eventTypeService: EventTypeService,
      eventService: EventService,
      daySlotService: DaySlotService,
      closedDayService: ClosedDayService,
      centerTypeService: CenterTypeService,
      centerService: CenterService,
      categoryService: CategoryService,
      backOfficeUserService: BackOfficeUserService,
      attributeService: AttributeService,
      attributeKeyService: AttributeKeyService,
      optionService: OptionService,
      singleProductService: SingleProductService,
      notificationService: NotificationService,
      authorizationService: AuthorizationService,
      priceUnitVariationService: PriceUnitVariationService,
      reportingService: ReportingService,

      // api
      cartApiService: CartApiService,
      accountApiService: AccountApiService,
      orderApiService: OrderApiService,
      cancellationApiService: CancellationApiService,
      incidentService: IncidentService,
      visitsService: VisitsService,
      paymentMethodsService: PaymentMethodsService,
      refundRequestsService: RefundRequestsService,
      attributeValueService: AttributeValueService,
      webhooksService: WebhooksService,
      documentsService: DocumentsService,
    },
  });
